import React from "react";
import { Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ButtonsAcoesListas from "../../../../styles/StyledComponents/ButtonsAcoesListas";
import {
  BodyTable,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import { formatToCurrency } from "../../../../utils/FormatacaoDeDados/FormatToCurrency";

const TableLiquidado = ({
  loading,
  currentItens,
  currentFilteredItens,
  searchTerm,
}) => (
  <Table
    responsive
    style={loading ? { display: "none" } : { marginTop: "-10px" }}
  >
    <thead>
      <tr>
        <TituloTable>Liquidação</TituloTable>
        <TituloTable>Fornecedor</TituloTable>
        <TituloTable>Data</TituloTable>
        <TituloTable>Valor</TituloTable>
        <TituloTableAction>Ação</TituloTableAction>
      </tr>
    </thead>
    <BodyTable>
      {searchTerm
        ? currentFilteredItens.map((item, index) => (
            <tr key={index}>
              <td
                style={{ whiteSpace: "nowrap" }}
                title={`${item.empenho.numero} - ${item.liquidacao}`}
              >
                <NavLink
                  style={{
                    width: "65px",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                  to={`/contabilidade/despesa/liquidacao-de-empenho/${item.id}`}
                >
                  {`${item.empenho.numero} - ${item.liquidacao}`}
                </NavLink>
              </td>
              <td
                style={{ whiteSpace: "nowrap" }}
                title={`${item.empenho.fornecedorInfo.dados.cpf} - ${item.empenho.fornecedorInfo.dados.nomeCompleto}`}
              >
                {item.empenho.fornecedorInfo.dados.nomeCompleto}
              </td>

              <td
                style={{ whiteSpace: "nowrap" }}
                title={new Date(item.data).toLocaleDateString("pt-BR", {
                  timeZone: "UTC",
                })}
              >
                {new Date(item.data).toLocaleDateString("pt-BR", {
                  timeZone: "UTC",
                })}
              </td>
              <td
                style={{ whiteSpace: "nowrap" }}
                title={formatToCurrency(item.valor)}
              >
                {formatToCurrency(item.valor)}
              </td>

              <td>
                <ButtonsAcoesListas
                  // FuncaoIconDelete={() => {
                  //   openModal();
                  //   setDataDelete(item.numero);
                  // }}
                  // deactivated
                  FuncaoIconPrint={() => console.log("Print")}
                  CaminhoPagReplica={"#"}
                  CaminhoPagAnulacao={"#"}
                  CaminhoPagSubstituir={"#"}
                  FuncaoIconDelete={() => console.log("Delete")}
                  CaminhoPagUpdate={`/contabilidade/despesa/liquidacao-de-empenho/${item.id}`}
                />
              </td>
            </tr>
          ))
        : currentItens.map((item, index) => (
            <tr key={index}>
              <td
                style={{ whiteSpace: "nowrap" }}
                title={`${item?.empenho?.numero} - ${item?.liquidacao}`}
              >
                <NavLink
                  style={{
                    width: "65px",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                  to={`/contabilidade/despesa/liquidacao-de-empenho/${item?.id}`}
                >
                  {`${item?.empenho?.numero} - ${item?.liquidacao}`}
                </NavLink>
              </td>
              <td
                style={{ whiteSpace: "nowrap" }}
                title={`${item?.empenho?.fornecedorInfo?.dados?.cpf} - ${item?.empenho?.fornecedorInfo?.dados?.nomeCompleto}`}
              >
                {item?.empenho?.fornecedorInfo?.dados?.nomeCompleto}
              </td>

              <td
                style={{ whiteSpace: "nowrap" }}
                title={new Date(item.data).toLocaleDateString("pt-BR", {
                  timeZone: "UTC",
                })}
              >
                {new Date(item.data).toLocaleDateString("pt-BR", {
                  timeZone: "UTC",
                })}
              </td>
              <td
                style={{ whiteSpace: "nowrap" }}
                title={formatToCurrency(item.valor)}
              >
                {formatToCurrency(item.valor)}
              </td>

              <td>
                <ButtonsAcoesListas
                  // FuncaoIconDelete={() => {
                  //   openModal();
                  //   setDataDelete(item.numero);
                  // }}
                  // deactivated
                  FuncaoIconPrint={() => console.log("Print")}
                  CaminhoPagReplica={"#"}
                  CaminhoPagAnulacao={"#"}
                  CaminhoPagSubstituir={"#"}
                  FuncaoIconDelete={() => console.log("Delete")}
                  CaminhoPagUpdate={`/contabilidade/despesa/liquidacao-de-empenho/${item.id}`}
                />
              </td>
            </tr>
          ))}
    </BodyTable>
  </Table>
);

export default TableLiquidado;
