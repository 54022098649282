import { Row, Col, Table } from "react-bootstrap";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import InputSvg from "../../../../components/Grid/InputSvg";
import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";
import ButtonsAcoesListas from "../../../../styles/StyledComponents/ButtonsAcoesListas";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import { toast, ToastContainer } from "react-toastify";
import Modal from "react-modal";
import api from "../../../../utils/api";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import ModalDeleteData from "../../../../components/ModalDeleteData/ModalDeleteData";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";

const ListaContas = () => {
  Modal.setAppElement("#root");

  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useCurrentYear();

  const [itens, setItens] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [itensFiltrados, setItensFiltrados] = useState([]);
  const [loadingLista, setLoadingLista] = useState(false);

  const [dataToDelete, setDataToDelete] = useState({ id: 0 });

  const [modalOpened, setModalOpened] = useState(false);

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = itens.slice(startIndex, endIndex);
  const currentFilteredItens = itensFiltrados.slice(startIndex, endIndex);

  const pages = Math.ceil(itens.length / itensPorPagina);
  const filteredPages = Math.ceil(itensFiltrados.length / itensPorPagina);

  const fetchContas = useCallback(async () => {
    setLoadingLista(true);
    try {
      const resp = await api.get(
        `contaBancaria/cliente/${currentClient.clienteId}/${currentYear}`
      );
      const data = resp.data;

      console.log(data);
      setItens(data);
    } catch (error) {}
    setLoadingLista(false);
  }, [currentClient, currentYear]);

  useEffect(() => {
    fetchContas();
  }, [fetchContas]);

  const handleSearch = (e) => {
    const valor = e.target.value.toLowerCase();

    setSearchTerm(valor);

    const filteredArray = itens.filter((item) => {
      const numeroConta = (item.numeroConta + item.digito).replace(
        /[^0-9]/g,
        ""
      );
      const nomeConta = item.nome;

      return (
        numeroConta.includes(valor) || nomeConta.toLowerCase().includes(valor)
      );
    });

    setItensFiltrados(filteredArray);
  };

  const deleteConta = async () => {
    try {
      await api.delete(`contaBancaria/${dataToDelete.id}`);
      setItens((prev) => prev.filter((item) => item.id !== dataToDelete.id));
      toast.success("Conta deletada com sucesso.");
    } catch (error) {
      console.log(error);
      toast.error("Algo deu errado ao deletar a conta.");
    }
  };

  const closeModal = () => {
    setModalOpened(false);
  };

  return (
    <>
      <ModalDeleteData
        setDataDelete={setDataToDelete}
        deleteDataFunction={deleteConta}
        modalOpened={modalOpened}
        closeModal={closeModal}
      />
      <ToastContainer />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loadingLista ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        <Table
          responsive
          style={loadingLista ? { display: "none" } : { marginTop: "-10px" }}
        >
          <thead>
            <tr>
              <TituloTable>Código</TituloTable>
              <TituloTable>Conta Bancária</TituloTable>
              <TituloTable>Tipo de Conta</TituloTable>
              <TituloTable>Tipo de Vínculo</TituloTable>
              <TituloTableAction>Ação</TituloTableAction>
            </tr>
          </thead>
          <BodyTable>
            {searchTerm
              ? currentFilteredItens
                  .filter((item) => item.valor !== "00")
                  .map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{ whiteSpace: "nowrap" }}
                      >{`${item.numeroConta.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        "."
                      )}-${item.digito}`}</td>
                      <td title={""} style={{ whiteSpace: "nowrap" }}>
                        {item.nome}
                      </td>

                      <td title={""} style={{ whiteSpace: "nowrap" }}>
                        {item.tipoDeContaId}
                      </td>
                      <td title={""} style={{ whiteSpace: "nowrap" }}>
                        {item.tipoDeVinculo}
                      </td>

                      <td>
                        <ButtonsAcoesListas
                          FuncaoIconDelete={() => {
                            setDataToDelete({ id: item.id });
                            setModalOpened(true);
                          }}
                          CaminhoPagUpdate={`/contabilidade/financeiro/conta-bancaria/visualizar/${item.id}`}
                        />
                      </td>
                    </tr>
                  ))
              : currentItens
                  .filter((item) => item.valor !== "00")
                  .map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{ whiteSpace: "nowrap" }}
                      >{`${item.numeroConta.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        "."
                      )}-${item.digito}`}</td>
                      <td title={""} style={{ whiteSpace: "nowrap" }}>
                        {item.nome}
                      </td>

                      <td title={""} style={{ whiteSpace: "nowrap" }}>
                        {item.tipoDeContaId}
                      </td>
                      <td title={""} style={{ whiteSpace: "nowrap" }}>
                        {item.tipoDeVinculo}
                      </td>
                      <td>
                        <ButtonsAcoesListas
                          FuncaoIconDelete={() => {
                            setDataToDelete({ id: item.id });
                            setModalOpened(true);
                          }}
                          CaminhoPagUpdate={`/contabilidade/financeiro/conta-bancaria/visualizar/${item.id}`}
                        />
                      </td>
                    </tr>
                  ))}
          </BodyTable>
        </Table>
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm ? itensFiltrados.length : itens.length} registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>

            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </>
  );
};

export default ListaContas;
