import api from "../../utils/api";

export const getByIdRetencao = async (retencaoId) => {
  try {
    const resp = await api.get(`retencao/${retencaoId}`);
    return resp.data;
  } catch (error) {
    return null;
  }
};
