import styled from "styled-components";
import SaveLogoExpanded from "../SvgComponents/SaveLogoExpanded";
import SaveLogoMinimalized from "../SvgComponents/SaveLogoMinimalized";
import { CSSTransition } from "react-transition-group";
import { useContext } from "react";
import { SidebarExpandidaContext } from "../../contexts/SidebarExpandidaContext";

const ImgLogoExpanded = styled.div`
  width: 185px;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 105px;
  
`;

const ImgLogo = styled.div`
  background-color: transparent;
  border-right: 2px solid #efefef;
  width: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 63px;
`;

const ImgLogoMinimalized = styled.div`
  width: 60px;
  display: none;
  
`;

const SideBarLogo = () => {
  const { sidebarExpandida } = useContext(SidebarExpandidaContext);

  return (
    <ImgLogo id="div_logo_header">
      <ImgLogoExpanded id="logo_expanded">
        <CSSTransition
          in={sidebarExpandida === false}
          timeout={1000}
          classNames="slide"
          unmountOnExit
        >
          <SaveLogoExpanded />
        </CSSTransition>
      </ImgLogoExpanded>
      <ImgLogoMinimalized id="logo_minimalized">
        <SaveLogoMinimalized />
      </ImgLogoMinimalized>
    </ImgLogo>
  );
};

export default SideBarLogo;
