import api from "../../utils/api";

export const getAllNaturezaReceitaVinculoQdr = async (clientId, year) => {
  try {
    const resp = await api.get(
      `naturezaDaReceita/vinculo-qdr/${clientId}/${year}`
    );
    return resp.data;
  } catch (error) {
    return null;
  }
};
