import React, { useContext, useEffect, useState } from "react";

import { Col, Container, Row, Table } from "react-bootstrap";

import { toast, ToastContainer } from "react-toastify";

import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";

import { useNavigate } from "react-router-dom";

import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import InputSvg from "../../../../components/Grid/InputSvg";

import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";

import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

import { useFetchData } from "../../../../hooks/useFetchData";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";
import { IconPrint } from "../../../../components/Icons/IconPrint";
import { IconEdit } from "../../../../components/Icons/IconEdit";
import { IconReplica } from "../../../../components/Icons/IconReplica";
import { IconCancel } from "../../../../components/Icons/IconCancel";
import { IconReplace } from "../../../../components/Icons/IconReplace";
import { IconDelete } from "../../../../components/Icons/IconDelete";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";

function ConsultaGdeDespesa() {
  const { currentYear } = useCurrentYear();
  const { currentClient } = useContext(CurrentClientContext);

  const navigate = useNavigate();

  const { data: dataGdeDespesa, loading } = useFetchData(
    `gde/cliente/${currentClient.clienteId}/${currentYear}`
  );

  const [dataGdeDespesaFormatted, setDataGdeDespesaFormatted] = useState([
    {
      id: "",
      numero: "",
      qdrde: "",
      beneficiario: "",
      data: "",
      valor: "",
    },
  ]);

  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [dataGdeDespesaFiltered, setDataGdeDespesaFiltered] = useState([
    {
      id: "",
      numero: "",
      qdrde: "",
      beneficiario: "",
      data: "",
      valor: "",
    },
  ]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filteredItems = dataGdeDespesaFormatted.filter((item) => {
      return (
        item.numero.toString().includes(value) ||
        item.qdrde.toLowerCase().includes(value.toLowerCase()) ||
        item.beneficiario.toLowerCase().includes(value.toLowerCase()) ||
        item.valor.includes(value)
      );
    });
    setDataGdeDespesaFiltered(filteredItems);
    setCurrentPage(0);
  };

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = dataGdeDespesaFormatted.slice(startIndex, endIndex);
  const currentFilteredItens = dataGdeDespesaFiltered.slice(
    startIndex,
    endIndex
  );

  const pages = Math.ceil(dataGdeDespesaFormatted.length / itensPorPagina);
  const filteredPages = Math.ceil(
    dataGdeDespesaFiltered.length / itensPorPagina
  );

  const handleGeneratePdf = (receitaId) => {
    console.log(receitaId);
    toast.info("Relatório não implementado");
  };

  const handleNavigatePageAnulacao = (gdeId, saldoGde) => {
    if (!saldoGde) {
      toast.info("Esta Guia não possui saldo");
      return;
    }
    navigate(`/contabilidade/extra/anulacao-extra/cadastro/${gdeId}?guia=gde`);
  };

  useEffect(() => {
    console.log("oi");

    if (dataGdeDespesa) {
      console.log(dataGdeDespesa);
      const formattedDataGdeDespesa = dataGdeDespesa
        .map((gde) => {
          // const numero  = gde.numero.replace(/^0+/, '');
          const date = gde.data.split("T")[0];
          const [year, month, day] = date.split("-");
          const dateFormatted = `${day}/${month}/${year}`;
          return {
            id: gde.id,
            numero: gde.numero,
            qdrde: gde.qdrdeInfo.titulo,
            beneficiario: gde.beneficiarioInfo.nomeCompleto,
            data: dateFormatted,
            valor: FormatValueToLocaleString(gde.valor),
          };
        })
        .flat();
      console.log(formattedDataGdeDespesa);
      setDataGdeDespesaFormatted(formattedDataGdeDespesa);
    }
  }, [dataGdeDespesa]);

  const renderInfosTable = (infosTable) => {
    return (
      <tr key={infosTable.id}>
        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
        >
          {infosTable.numero}
        </td>
        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
          title={infosTable.qdrde}
        >
          {infosTable.qdrde.length > 70
            ? `${infosTable.qdrde.substring(0, 70)}...`
            : `${infosTable.qdrde}`}
        </td>
        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
          title={infosTable.beneficiario}
        >
          {infosTable.beneficiario.length > 70
            ? `${infosTable.beneficiario.substring(0, 70)}...`
            : `${infosTable.beneficiario}`}
        </td>

        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
        >
          {infosTable.data}
        </td>

        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
        >
          {`R$ ${infosTable.valor}`}
        </td>
        {/* <td>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              flexShrink: "0",
              gap: "2px",
            }}
          >
            <Link
              title={"editar"}
              to={`/contabilidade/extra/gde-despesa/visualizar/${infosTable.id}`}
            >
              <Icon icon="bx:edit" color="#5971C8" height="22" />
            </Link>
            <IconDelete height={22} />
          </div>
        </td> */}
        <td>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <IconPrint
              onClick={() => {
                handleGeneratePdf(infosTable.id);
              }}
            />

            <IconEdit
              onClick={() =>
                navigate(
                  `/contabilidade/extra/gde-despesa/visualizar/${infosTable.id}`
                )
              }
            />

            <IconReplica
              onClick={() =>
                navigate(
                  `/contabilidade/extra/gde-despesa/replicar/${infosTable.id}`
                )
              }
            />

            <IconCancel
              onClick={() =>
                handleNavigatePageAnulacao(infosTable.id, infosTable.valor)
              }
            />
            <IconReplace />
            <IconDelete
              style={{ marginLeft: "-2px" }}
              handleDelete={() => console.log(infosTable.id)}
            />
          </div>
        </td>
      </tr>
    );
  };

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"GDE - Despesa"}
        PaginaSubtitulo={"Consulta"}
        BotaoSaveAtivo={false}
        RowTitle="Despesa Extraorçamentária - GDE"
        BotaoNovoFunction={() =>
          navigate("/contabilidade/extra/gde-despesa/cadastro")
        }
      />
      <ToastContainer />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loading ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        {currentItens.length > 0 && (
          <Table responsive style={{ marginTop: "-10px" }}>
            <thead>
              <tr>
                <TituloTable>Número</TituloTable>
                <TituloTable>Qdrde</TituloTable>
                <TituloTable>Beneficiário</TituloTable>
                <TituloTable>Data</TituloTable>
                <TituloTable>Valor</TituloTable>
                <TituloTableAction>Ação</TituloTableAction>
              </tr>
            </thead>

            <BodyTable>
              {searchTerm
                ? currentFilteredItens.map((item) => {
                    if (!item.numero) return null;
                    return renderInfosTable(item);
                  })
                : currentItens.map((item) => {
                    if (!item.numero) return null;
                    return renderInfosTable(item);
                  })}
            </BodyTable>
          </Table>
        )}
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm
              ? dataGdeDespesaFiltered.length
              : dataGdeDespesaFormatted
              ? dataGdeDespesaFormatted.length
              : 0}{" "}
            registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>
            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default ConsultaGdeDespesa;
