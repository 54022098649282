export const formatDateToISO = (date) => {
  const separator = date.includes("/") ? "/" : "-";

  const parts = date.split(separator);

  let year, month, day;

  if (parts[0].length === 4) {
    [year, month, day] = parts;
  } else {
    [day, month, year] = parts;
  }

  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};

export const checkIsValideData = (dateInfoRegister,date) => {
  const [yearReceita, monthReceita] = dateInfoRegister.split("-");

  // const date = currentDate();

  const separatorDateCurrentDate = date.includes("/") ? "/" : "-";
  const [currentYear, currentMonth] = date
    .split(separatorDateCurrentDate)
    .reverse();
  //TODO: se for editar um registro talvez tenha que por (monthReceita <= currentMonth || paramsId) já que p mes atual pode ser menor
  const isValidDate =
    currentYear === yearReceita && monthReceita <= currentMonth;

  return isValidDate;
};