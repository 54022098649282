import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Col, Container, Row, Table } from "react-bootstrap";

import { toast, ToastContainer } from "react-toastify";

import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";

import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import InputSvg from "../../../../components/Grid/InputSvg";
import HeaderOptions from "../../../../components/HeaderOptions/HeaderOptions";
import { IconEdit } from "../../../../components/Icons/IconEdit";
import { IconDelete } from "../../../../components/Icons/IconDelete";
import ModalDeleteData from "../../../../components/ModalDeleteData/ModalDeleteData";

import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";

import { useFetchData } from "../../../../hooks/useFetchData";

import { RetencaoService } from "../../../../services/retencao";

const nameIndicador = {
  GRO: "Orçamentário",
  GRE: "Extraorçamentário",
};

function ConsultaRetencao() {
  const { currentClient } = useContext(CurrentClientContext);

  const navigate = useNavigate();

  const { data: dataRetencao, loading } = useFetchData(
    `retencao/cliente/${currentClient.clienteId}`
  );

  const [dataRetencaoFormatted, setDataRetencaoFormatted] = useState([
    {
      id: "",
      numero: "",
      indicador: "",
      titulo: "",
      status: "",
      isDelete: "",
    },
  ]);

  const [modalOpened, setModalOpened] = useState(false);
  const [dataDelete, setDataDelete] = useState({ id: 0 });
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [dataRetencaoFiltered, setDataRetencaoFiltered] = useState([
    {
      id: "",
      numero: "",
      indicador: "",
      titulo: "",
      status: "",
      isDelete: "",
    },
  ]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filteredItems = dataRetencaoFormatted.filter((item) => {
      return (
        item.numero.toString().includes(value) ||
        item.indicador.toLowerCase().includes(value.toLowerCase()) ||
        item.titulo.toLowerCase().includes(value.toLowerCase()) ||
        item.status.toLowerCase().includes(value.toLowerCase())
      );
    });
    setDataRetencaoFiltered(filteredItems);
    setCurrentPage(0);
  };

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = dataRetencaoFormatted.slice(startIndex, endIndex);
  const currentFilteredItens = dataRetencaoFiltered.slice(startIndex, endIndex);

  const pages = Math.ceil(dataRetencaoFormatted.length / itensPorPagina);
  const filteredPages = Math.ceil(dataRetencaoFiltered.length / itensPorPagina);

  const formattedDataRetencao = (data) => {
    const dataFormatted = data.map((info) => ({
      id: info.id,
      numero: info.numero,
      indicador: nameIndicador[info.indicador.toUpperCase()],
      titulo: info.titulo,
      status: info.status ? "Ativo" : "Inativo",
      isDelete: info.delete === "on" ? true : false,
    }));
    return dataFormatted;
  };

  function openModal() {
    setModalOpened(true);
  }

  function closeModal() {
    setModalOpened(false);
  }

  const handleDeleteRetencao = (id) => {
    openModal();
    setDataDelete({ id });
  };

  const deleteGuiaRetencao = async () => {
    const isSuccessResponse = await RetencaoService.deleteRetencao(
      dataDelete.id
    );

    if (!isSuccessResponse) {
      toast.error("Algo deu errado ao deletar o registro.");
      return;
    }
    const filterDataGuiaReceita = dataRetencaoFormatted.filter(
      (guia) => guia.id !== dataDelete.id
    );
    setDataRetencaoFormatted(filterDataGuiaReceita);
    toast.success("Registro deletado com sucesso.");
  };

  useEffect(() => {
    if (dataRetencao) {
      const listDataRetencao = formattedDataRetencao(dataRetencao);
      setDataRetencaoFormatted(listDataRetencao);
    }
  }, [dataRetencao]);

  const renderInfosTable = (infosTable) => {
    return (
      <tr key={infosTable.id}>
        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
        >
          {infosTable.numero}
        </td>
        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
          title={infosTable.titulo}
        >
          {infosTable.titulo.length > 70
            ? `${infosTable.titulo.substring(0, 70)}...`
            : `${infosTable.titulo}`}
        </td>
        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
        >
          {infosTable.indicador}
        </td>
        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
        >
          {infosTable.status}
        </td>

        <td>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <IconEdit
              onClick={() =>
                navigate(`/contabilidade/visualizar/retencao/${infosTable.id}`)
              }
            />

            <IconDelete
              style={{ marginLeft: "-2px" }}
              disabled={!infosTable.isDelete}
              handleDelete={() => handleDeleteRetencao(infosTable.id)}
            />
          </div>
        </td>
      </tr>
    );
  };
  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderOptions
        withRow={true}
        TituloPagina={"Retenção"}
        SubTituloPagina={"Consulta"}
        ToPag={"/contabilidade/cadastros/retencao"}
      />
      <ModalDeleteData
        modalOpened={modalOpened}
        closeModal={closeModal}
        setDataDelete={setDataDelete}
        deleteDataFunction={deleteGuiaRetencao}
      />
      <ToastContainer />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loading ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        {currentItens.length > 0 && (
          <Table responsive style={{ marginTop: "-10px" }}>
            <thead>
              <tr>
                <TituloTable>Número</TituloTable>
                <TituloTable>Título</TituloTable>
                <TituloTable>Indicador de Lançamento</TituloTable>
                <TituloTable>Status</TituloTable>
                <TituloTableAction>Ação</TituloTableAction>
              </tr>
            </thead>

            <BodyTable>
              {searchTerm
                ? currentFilteredItens.map((item) => {
                    if (!item.numero) return null;
                    return renderInfosTable(item);
                  })
                : currentItens.map((item) => {
                    if (!item.numero) return null;
                    return renderInfosTable(item);
                  })}
            </BodyTable>
          </Table>
        )}
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm
              ? dataRetencaoFiltered.length
              : dataRetencaoFormatted
              ? dataRetencaoFormatted.length
              : 0}{" "}
            registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>
            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default ConsultaRetencao;
