import React from "react";
import { CCol, RowToModal } from "../../../../components/Grid/CCol";
// import IconModulosHeader from "../../../../components/SvgComponents/IconModulosHeader";
// import InputVoiceToText from "../../../../components/Inputs/InputVoiceToText";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";

const HistoricoAba = ({
  values,
  setValues,
  disabled,
  inputDescricaoRef,
  onKeyDown,
}) => {
  return (
    <>
      {/* <RowToModal
        className="row_form mt-3"
        style={{
          borderRadius: "5px 5px 0px 0px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <CCol
          md={1}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              margin: 0,
              padding: 0,
              fontWeight: "600",
              color: "#515c70",
            }}
          >
            Histórico
          </p>
        </CCol>
        <CCol style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "15px" }}>
            <IconModulosHeader savebot />
            <span style={{ color: "#6C7585" }}>SaveBot</span>
          </div>
          <div style={{ flexGrow: "1" }}>
            <InputVoiceToText disabled={disabled} />
          </div>
        </CCol>
      </RowToModal> */}
      <RowToModal
        className="row_form mt-3"
        style={{
          borderRadius: "5px 5px 0px 0px",
          padding: "8px 10px",
        }}
      >
        <CCol md={1}>
          <span
            style={{ fontSize: "16px", color: "#515c70", fontWeight: "700" }}
          >
            Histórico
          </span>
        </CCol>
      </RowToModal>
      <RowToModal className="row_form_2 mt-1">
        <CCol>
          <FloatingLabelInput
            disabled={disabled}
            tipo="text_area"
            voiceInput
            as="textarea"
            ref={inputDescricaoRef}
            label="Descrição"
            placeholder="Descrição"
            style={{ minHeight: "98px" }}
            value={values?.descricao}
            onChange={(e) => {
              let { value } = e.target;
              if (value.length > 500) {
                value = value.slice(0, 500);
              }
              setValues((prevValues) => ({
                ...prevValues,
                descricao: value,
              }));
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.shiftKey && onKeyDown) {
                e.preventDefault();
                onKeyDown();
              }
            }}
          />
        </CCol>
      </RowToModal>
    </>
  );
};

export default HistoricoAba;
