export const IconDelete = ({
  title = "Excluir",
  disabled,
  handleDelete,
  style,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 24 24"
      style={{
        cursor: disabled ? "auto" : "pointer",
        opacity: disabled ? "0.5" : "1",
        ...style,
      }}
      onClick={disabled ? null : handleDelete}
      {...props}
    >
      <title>{title}</title>
      <path
        fill="#E79900"
        d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM19 4h-3.5l-1-1h-5l-1 1H5v2h14z"
      ></path>
    </svg>
  );
};
