import { colors } from "../../styles/colors";

export const LineDivider = ({ style, ...props }) => {
  return (
    <div
      style={{
        height: "2px",
        backgroundColor: colors["background-page"],
        ...style,
      }}
      {...props}
    ></div>
  );
};
