import React from "react";
import { CCol, RowForm, RowToModal } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";

export const FonteDeRecursosAba = () => {
  return (
    <>
      <RowToModal
        className={"row_form mt-3"}
        style={{
          borderRadius: "5px 5px 0px 0px",
          padding: "8px 10px",
        }}
      >
        <CCol
          style={{
            fontSize: "16px",
            color: "#515c70",
            fontWeight: "700",
          }}
        >
          Fonte de Recursos / CO - Pagamento
        </CCol>
      </RowToModal>

      <RowForm $borderRadiusType={2} className="mt-1">
        <div>
          <CCol>
            <FloatingLabelInput
              disabled
              style={{ border: "none" }}
              placeholder="Fonte de Recursos - Despesa Extra"
              label="Fonte de Recursos - Despesa Extra"
            />
          </CCol>
          <CCol style={{ marginTop: "16px" }}>
            <FloatingLabelInput
              disabled
              style={{ border: "none" }}
              placeholder="Fonte de Recursos - Despesa Extra - Pagamento"
              label="Fonte de Recursos - Despesa Extra - Pagamento"
            />
          </CCol>
        </div>
      </RowForm>
    </>
  );
};
