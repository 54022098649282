import styled from "styled-components";
import { Form } from "react-bootstrap";

export const CustomCheckBox = styled(Form.Check)`
  color: #6c7585;
  font-weight: 500;
  padding: 5px 0 5px 28px;
  font-size: 16px;
  line-height: 24px;
  /* border-bottom: 2px solid #f2f3f8; */
  display: flex;
  align-items: flex-start;
  gap: 4px;
  white-space: nowrap;
  overflow: hidden;

  input[type="checkbox"] {
    background-color: #f7f7f7;
    border-radius: 2px;
    border: 2px solid #515c70;
    /* margin-left: 5px; */
    /* margin-right: 7px; */
    width: 16px;
    min-width: 16px;
    height: 16px;
  }

  input[type="checkbox"]:checked {
    background-color: green;
    border-color: green;
  }
`;