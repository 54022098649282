import { useEffect, useRef } from "react";
import { colors } from "../../styles/colors";
import { IconClose } from "../Icons/IconClose";

import * as Styled from "./style";

export const CustomToast = ({
  isOpenToast,
  setIsOpenToast,
  title = "",
  saveRegister,
}) => {
  const toastTitle = title || "Deseja salvar esse registro?";
  const confirmButtonRef = useRef(null);

  const handleConfirmToast = async () => {
    await saveRegister();
    setIsOpenToast((prevState) => !prevState);
  };
  useEffect(() => {
    if (isOpenToast) confirmButtonRef.current?.focus();
  }, [isOpenToast]);

  return (
    <Styled.ToastContainer isOpen={isOpenToast}>
      <Styled.ButtonClose onClick={() => setIsOpenToast(false)}>
        <IconClose height={18} />
      </Styled.ButtonClose>
      <Styled.ToastMessage>{toastTitle}</Styled.ToastMessage>
      <Styled.ButtonContainer>
        <Styled.ButtonAction
          ref={confirmButtonRef}
          style={{ backgroundColor: colors.confirm }}
          onClick={handleConfirmToast}
        >
          Sim
        </Styled.ButtonAction>
        <Styled.ButtonAction
          style={{ backgroundColor: colors.cancel }}
          onClick={() => setIsOpenToast(false)}
        >
          Não
        </Styled.ButtonAction>
      </Styled.ButtonContainer>
    </Styled.ToastContainer>
  );
};
