import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";

import { useCurrentYear } from "../../../../hooks/useCurrentYear";

import { QdrdeService } from "../../../../services/qdrde";
import { RetencaoService } from "../../../../services/retencao";
import { NaturezaService } from "../../../../services/naturezaDaReceita";

import { ACCOUNTS_TYPE_TRANSFER_QDRDE } from "../../Extra/QDRDE/accountsPCASP";

const optionsIndicador = [
  { label: "Orçamentário", value: "GRO", placeholder: "Natureza da Receita" },
  { label: "Extraorçamentário", value: "GRE", placeholder: "QDRDE" },
];

export const UseRetencao = () => {
  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useCurrentYear();

  const { id: paramsIdRetencao } = useParams();

  const tituloRef = useRef(null);
  const indicadorRef = useRef(null);
  const autoCompleteInputRef = useRef(null);

  const [isChecked, setIsChecked] = useState(true);
  const [isOpenToast, setIsOpenToast] = useState(false);
  const [autoCompleteInputValue, setAutoCompleteInputValue] = useState("");
  const [valuesRegister, setValuesRegister] = useState({
    num: "",
    titulo: "",
    indicador: "",
    naturezaId: "",
    qdrdeId: "",
    description: "",
    placeholderSelected: optionsIndicador[0].placeholder,
    isActive: true,
  });

  const [optionsIndicadorSelected, setOptionsIndicadorSelected] = useState({
    optionsNatureza: [
      {
        id: "",
        label: "",
        description: "",
      },
    ],
    optionsQdrde: [
      {
        id: "",
        label: "",
        description: "",
      },
    ],
  });

  const handleSelectIndicador = async (option) => {
    if (option.value === valuesRegister.indicador) {
      return;
    }

    setValuesRegister((prevState) => ({
      ...prevState,
      indicador: option.value,
      placeholderSelected: option.placeholder,
      naturezaId: "",
      qdrdeId: "",
      description: "",
    }));
    autoCompleteInputRef?.current.focus();
    if (autoCompleteInputValue) setAutoCompleteInputValue("");

    const hasOptionsQdrde = optionsIndicadorSelected.optionsQdrde.every(
      (option) => option.id
    );
    if (option.value === "GRE" && hasOptionsQdrde) {
      return;
    }
    const dataQdrde = await QdrdeService.getAllQdrde(
      currentClient.clienteId,
      currentYear
    );
    if (!dataQdrde) {
      toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
      return;
    }
    const optionsQdrde = formattedQdrde(dataQdrde);
    setOptionsIndicadorSelected((prevState) => ({
      ...prevState,
      optionsQdrde,
    }));
  };

  const handleSelectInputAutoComplete = (option) => {
    setValuesRegister((prevState) => ({
      ...prevState,
      naturezaId: valuesRegister.indicador === "GRO" ? option.id : "",
      qdrdeId: valuesRegister.indicador === "GRE" ? option.id : "",
      description: option.description,
    }));
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    setValuesRegister((prevState) => ({
      ...prevState,
      isActive: e.target.checked,
    }));
    console.log(e.target.checked);
  };

  const handleKeyDown = (e, nextRef) => {
    if (e.key === "Enter") {
      if (nextRef && nextRef.current) {
        e.preventDefault();
        nextRef.current.focus();
      }
    }
  };

  const resetAutoCompleteInput = () => {
    setValuesRegister((prevState) => ({
      ...prevState,
      naturezaId: "",
      qdrdeId: "",
      description: "",
    }));
    setAutoCompleteInputValue("");
  };

  const validateInfosRegister = () => {
    let infosRegister = {
      clienteId: currentClient.clienteId,
      indicador: valuesRegister.indicador,
      titulo: valuesRegister.titulo,
      status: valuesRegister.isActive ? 1 : 0,
    };

    if (infosRegister.indicador === optionsIndicador[0].value) {
      infosRegister = {
        ...infosRegister,
        naturezaId: valuesRegister.naturezaId,
      };
    }

    if (infosRegister.indicador === optionsIndicador[1].value) {
      infosRegister = { ...infosRegister, qdrdeId: valuesRegister.qdrdeId };
    }

    const hasInfosRegister = Object.values(infosRegister).every(
      (info) => info !== ""
    );

    if (!hasInfosRegister) return false;

    return infosRegister;
  };

  const saveRegisterToast = async () => {
    const infosRetencao = validateInfosRegister();
    if (!infosRetencao) {
      toast.info("Preencha todas as informações");
    }

    const responseSuccess = paramsIdRetencao
      ? await RetencaoService.putRetencao(paramsIdRetencao, infosRetencao)
      : await RetencaoService.postRetencao(infosRetencao);

    if (!responseSuccess) {
      toast.error(
        `Erro ao ${
          paramsIdRetencao ? "atualizar" : "realizar"
        } cadastro. Tente novamente mais tarde.`
      );
      return;
    }
    toast.success(
      `Cadastro ${paramsIdRetencao ? "atualizado" : "realizado"} com sucesso.`
    );
    if (!paramsIdRetencao) {
      resetFields();
      tituloRef?.current.focus();
    }
  };

  const handleSave = () => {
    const infosRetencao = validateInfosRegister();
    if (!infosRetencao) {
      toast.info("Preencha todas as informações");
      return;
    }

    setIsOpenToast(true);
  };

  const resetFields = () => {
    setValuesRegister((preState) => ({
      ...preState,
      titulo: "",
      naturezaId: "",
      qdrdeId: "",
      description: "",
    }));
    setAutoCompleteInputValue("");
  };

  const formattedQdrde = (dataQdrde) => {
    const infosQdrde = dataQdrde
      .map((info) => {
        const qdrdeCategoryCode = info.categoriaContaPcaspInfo[0].conta;
        if (ACCOUNTS_TYPE_TRANSFER_QDRDE.includes(qdrdeCategoryCode)) return {};
        return {
          id: info.id,
          label: info.titulo,
          description: info.itemContaPcaspInfo[0].funcao,
        };
      })
      .filter((info) => info.id);

    return infosQdrde;
  };

  const formattedNatureza = (dataNatureza) => {
    const infosNatureza = dataNatureza.map((info) => ({
      id: info.id,
      label: `${info.nr} - ${info.titulo}`,
      description: info.descricao,
    }));
    return infosNatureza;
  };

  useEffect(() => {
    const fetchData = async () => {
      const dataNatureza =
        await NaturezaService.getAllNaturezaReceitaVinculoQdr(
          currentClient.clienteId,
          currentYear
        );

      if (!dataNatureza) {
        toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
        return;
      }

      const optionsNatureza = formattedNatureza(dataNatureza);
      setOptionsIndicadorSelected((prevState) => ({
        ...prevState,
        optionsNatureza,
      }));
    };

    fetchData();
  }, [currentClient.clienteId, currentYear]);

  const formattedValuesByIdRetencao = (data) => {
    const infosIndicadorSelected = optionsIndicador.find(
      (option) => option.value === data.indicador
    );
    if (!infosIndicadorSelected) {
      toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
      return;
    }

    const dataFormatted = {
      num: data.numero,
      titulo: data.titulo,
      indicador: data.indicador,
      naturezaId: data.naturezaId || "",
      qdrdeId: data.qdrdeId || "",
      description: data.naturezaId
        ? data.natureza[0].descricao
        : data.qdrde.itemContaPcaspInfo[0].funcao,
      placeholderSelected: infosIndicadorSelected.placeholder,
      isActive: !!data.status,
    };
    console.log(dataFormatted);

    if (data.naturezaId) {
      const labelInputNatureza = `${data.natureza[0].nr} - ${data.natureza[0].especificacao}`;
      setAutoCompleteInputValue(labelInputNatureza);
    }

    if (data.qdrdeId) {
      setAutoCompleteInputValue(data.qdrde.titulo);
    }

    return dataFormatted;
  };

  useEffect(() => {
    const fetchData = async () => {
      const dataRetencao = await RetencaoService.getByIdRetencao(
        paramsIdRetencao
      );
      if (!dataRetencao) {
        toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
        return;
      }

      const infosRetencao = formattedValuesByIdRetencao(dataRetencao);
      setValuesRegister(infosRetencao);
    };

    if (paramsIdRetencao) fetchData();
  }, [paramsIdRetencao]);

  useEffect(() => {
    if (!paramsIdRetencao) tituloRef?.current.focus();
  }, [paramsIdRetencao]);

  const saveActions = {
    handleSave,
    saveRegisterToast,
  };

  const stateActions = {
    isOpenToast,
    setIsOpenToast,
    valuesRegister,
    setValuesRegister,
    autoCompleteInputValue,
    setAutoCompleteInputValue,
    isChecked,
    setIsChecked,
  };

  const handleActions = {
    handleSelectIndicador,
    handleSelectInputAutoComplete,
    handleCheckboxChange,
    handleKeyDown,
  };

  const refs = {
    tituloRef,
    indicadorRef,
    autoCompleteInputRef,
  };

  return {
    optionsIndicador,
    saveActions,
    optionsIndicadorSelected,
    stateActions,
    resetAutoCompleteInput,
    handleActions,
    refs,
  };
};
