export const IconPrint = ({ title = "Imprimir", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      fill="none"
      viewBox="0 0 23 22"
      {...props}
    >
      <title>{title}</title>
      <path
        fill="#515C70"
        d="M5.308 19.462h12.384v-3.539H5.308zm0-8.847h12.384V5.308h-2.211a1.28 1.28 0 0 1-.94-.387 1.28 1.28 0 0 1-.387-.94V1.769H5.308zm15.923.885a.85.85 0 0 0-.263-.622.85.85 0 0 0-.622-.263.85.85 0 0 0-.622.263.85.85 0 0 0-.262.622q0 .36.262.622a.85.85 0 0 0 .622.263.85.85 0 0 0 .622-.263.85.85 0 0 0 .263-.622m1.769 0v5.75q0 .18-.131.311a.43.43 0 0 1-.311.131h-3.096v2.212q0 .552-.387.94a1.28 1.28 0 0 1-.94.387H4.865a1.28 1.28 0 0 1-.94-.387 1.28 1.28 0 0 1-.387-.94v-2.212H.442a.43.43 0 0 1-.31-.131A.43.43 0 0 1 0 17.25V11.5q0-1.092.78-1.873a2.56 2.56 0 0 1 1.874-.78h.884v-7.52q0-.553.387-.94A1.28 1.28 0 0 1 4.865 0h9.289q.553 0 1.216.276.665.277 1.05.664l2.102 2.1q.387.388.663 1.051.276.664.277 1.217v3.538h.884q1.092 0 1.873.781.78.78.781 1.873"
      ></path>
    </svg>
  );
};
