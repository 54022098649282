const MAX_LENGTH_VALUES = 11;

export const completeWithZerosDocFinanceiro = (
  docFinanceiro,
  maxLength = MAX_LENGTH_VALUES
) => {
  if (!docFinanceiro) return "";
  const formattedDocFinanceiro = String(docFinanceiro).padEnd(maxLength, "0");
  return formattedDocFinanceiro;
};
