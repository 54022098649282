import React, { useEffect, useState } from "react";
import { QueryTable } from "../../../../components/QueryTable";
import { Container } from "react-bootstrap";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import api from "../../../../utils/api";
import { useCurrentClient } from "../../../../hooks/useCurrentClient";
import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import IconDelete from "../../../../components/ListaDeInputs/IconDelete";
import IconEdit from "../../../../components/ListaDeInputs/IconEdit";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import { FormatDateToBR } from "../../../../utils/FormatacaoDeDados/FormatDateToBR";
import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";
import { NewQueryTable } from "../../../../components/QueryTable/NewQueryTable";

const NotaTeste = () => {
  const [colunas, setColunas] = useState([]);
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const navigate = useNavigate();
  const { currentClient } = useCurrentClient();
  const { currentYear } = useCurrentYear();

  const fetchData = async () => {
    try {
      setLoadingData(true);
      const response = await api.get(
        `/notaEmpenho/${currentClient.clienteId}/${currentYear}`
      );
      const data = response.data;
      console.log(data);
      const cols = [
        {
          title: "Número",
          key: "numero",
          values: data.map((item) => item.numero),
        },
        {
          title: "Data de Emissão",
          key: "data",
          values: data.map((item) => FormatDateToBR(item.data.split("T")[0])),
        },
        {
          title: "Fornecedor",
          key: "fornecedor",
          values: data.map((item) => FormatCpfPaste(item.fornecedor)),
        },
        {
          title: "Valor",
          key: "valor",
          values: data.map((item) => FormatValueToLocaleString(item.valor)),
        },
        {
          title: "Ações",
          key: "dataActions",
          values: data.map((item) => ({
            dataId: item.numero,
            actions: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  flexShrink: "0",
                  gap: "2px",
                }}
              >
                <IconEdit
                  height={22}
                  onClick={() =>
                    navigate(
                      `/contabilidade/despesa/nota-de-empenho/${item.numero}`
                    )
                  }
                />
                <Icon
                  icon="gg:copy"
                  color="#3E347B"
                  height="22"
                  style={{
                    cursor: "pointer",
                  }}
                />
                <Icon
                  icon="fluent:document-dismiss-16-regular"
                  height="22"
                  style={{ color: "#f00" }}
                />
                <IconDelete height={22} />
              </div>
            ),
          })),
        },
      ];

      // Atualiza colunas e dados
      setColunas(cols);
      console.log("AQUIIII:", data);
      setData(
        data.map((item) => ({
          id: item.id,
          numero: item.numero,
          data: FormatDateToBR(item.data.split("T")[0]),
          fornecedor: FormatCpfPaste(item.fornecedor),
          valor: FormatValueToLocaleString(item.valor),
        }))
      );
    } catch (error) {
      console.error(error);
      console.log("VEio pra o erro");
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchData();

    //eslint-disable-next-line
  }, []);

  const collsForData = [
    { label: "Número", value: "numero" },
    { label: "Data de Emissão", value: "Data de Emissão" },
  ];

  return (
    <div>
      <Container
        id={"pags_responsividade_padding_geral"}
        fluid
        className={"container_conteudo conteudo_pagina"}
      >
        <HeaderCadastros
          NomePaginaTitulo={"Nota de Empenho"}
          PaginaSubtitulo={"Consulta"}
          OpcoesDeArquivo
          BotaoSaveAtivo={false}
          BotaoNovoFunction={() =>
            navigate("/contabilidade/despesa/nota-de-empenho")
          }
        />
        <RowForm $borderRadiusType={3} className="mt-1" style={{ padding: 0 }}>
          <CCol md={12} style={{ padding: 0 }}>
            <QueryTable
              colunas={colunas}
              data={data}
              loadingData={loadingData}
              multiplier={1.5}
            />
          </CCol>
        </RowForm>

        <RowForm $borderRadiusType={3} className="mt-1" style={{ padding: 0 }}>
          <CCol md={12} style={{ padding: 0 }}>
            <NewQueryTable data={data} colunas={collsForData} />
          </CCol>
        </RowForm>
        <div className="p-3"></div>
      </Container>
    </div>
  );
};

export default NotaTeste;
