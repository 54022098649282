import api from "../../utils/api";

export const getAllQdrde = async (clientId, year) => {
  try {
    const resp = await api.get(`qdrde/${clientId}/${year}`);
    return resp.data;
  } catch (error) {
    return null;
  }
};

export const getQdrdeById = async (idContaPcasp) => {
  try {
    const resp = await api.get(`/qdrde/por-id/id/${idContaPcasp}`);
    return resp.data;
  } catch (error) {
    return null;
  }
};
