export const colors = {
  confirm: "#238c59",
  cancel: "#ff0000",
  "gray-light": "#757575",
  "text-table": "#515c70",
  "icon-delete": "#E79900",
  "icon-replace": "#F24E1E",
  "icon-replica": "#3E347B",
  "icon-cancel": "#f00",
  "icon-edit": "#5971C8",
  "background-page": "#F2F3F8",
};
