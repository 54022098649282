import api from "../../utils/api";

export const putRetencao = async (idRetencao, infosRetencao) => {
  try {
    await api.put(`retencao/${idRetencao}`, infosRetencao);
    return true;
  } catch (error) {
    return null;
  }
};
