import { Container } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";

import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import { CustomToast } from "../../../../components/CustomToast";
import { CheckBox } from "../../../../components/CheckBox";

import { UseRetencao } from "./useRetencao";

const CColNumero = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

export const CadastroRetencao = () => {
  const {
    handleActions,
    optionsIndicador,
    optionsIndicadorSelected,
    resetAutoCompleteInput,
    saveActions,
    stateActions,
    refs,
  } = UseRetencao();

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"Retenção"}
        PaginaSubtitulo={"Cadastro"}
        ButtonSaveFunction={saveActions.handleSave}
        BotaoNovoAtivo={false}
        PaginaConsulta={"/contabilidade/consulta/retencao"}
      />
      <ToastContainer />
      <CustomToast
        isOpenToast={stateActions.isOpenToast}
        setIsOpenToast={stateActions.setIsOpenToast}
        saveRegister={saveActions.saveRegisterToast}
      />

      <RowForm className="mt-1">
        <CColNumero md={1}>
          <FloatingLabelInput
            label="Número"
            placeholder="Número"
            disabled
            value={stateActions.valuesRegister.num}
          />
        </CColNumero>
        <CCol>
          <FloatingLabelInput
            ref={refs.tituloRef}
            label="Título"
            placeholder="Título"
            value={stateActions.valuesRegister.titulo}
            onChange={(e) =>
              stateActions.setValuesRegister((prevState) => ({
                ...prevState,
                titulo: e.target.value,
              }))
            }
            onKeyDown={(e) => handleActions.handleKeyDown(e, refs.indicadorRef)}
          />
        </CCol>
        <CCol md={3}>
          <FloatingLabelInput
            ref={refs.indicadorRef}
            tipo="input_select"
            new
            labelInput="Indicador de Lançamento"
            placeholder="Indicador de Lançamento"
            options={optionsIndicador}
            onSelect={(option) => handleActions.handleSelectIndicador(option)}
            value={stateActions.valuesRegister.indicador}
          />
        </CCol>
      </RowForm>
      <RowForm
        style={{ marginTop: "-14px", display: "flex", alignItems: "center" }}
      >
        <CCol>
          <MyAutoComplete
            ref={refs.autoCompleteInputRef}
            labelInput={stateActions.valuesRegister.placeholderSelected}
            style={{ height: "37px" }}
            options={
              stateActions.valuesRegister.indicador === "GRO"
                ? optionsIndicadorSelected.optionsNatureza
                : optionsIndicadorSelected.optionsQdrde
            }
            labelFormat={(item) => item.label}
            inputValue={stateActions.autoCompleteInputValue}
            setInputValue={stateActions.setAutoCompleteInputValue}
            onOptionSelect={(option) => {
              handleActions.handleSelectInputAutoComplete(option);
            }}
            iconClearFunction={resetAutoCompleteInput}
          />
        </CCol>
        <CCol md={3}>
          <CheckBox
            label={
              stateActions.isChecked ? "Retenção Ativa" : "Retenção Inativa"
            }
            value={1}
            checked={stateActions.isChecked}
            onChange={handleActions.handleCheckboxChange}
          />
        </CCol>
      </RowForm>
      {stateActions.valuesRegister.description && (
        <RowInfosAcao>
          <CCol>
            <p>{stateActions.valuesRegister.description}</p>
          </CCol>
        </RowInfosAcao>
      )}
    </Container>
  );
};
