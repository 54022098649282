import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";

import { Container } from "react-bootstrap";

import { toast, ToastContainer } from "react-toastify";

import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
  RowForm,
} from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";

import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";

import { Icon } from "@iconify/react/dist/iconify.js";

import { useFetchData } from "../../../../hooks/useFetchData";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";

import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";

import { MotivoAba } from "./MotivoAba";
import { FonteDeRecursosAba } from "./FonteDeRecursosAba";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";

import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import api from "../../../../utils/api";
import { completeWithZerosDocFinanceiro } from "../../../../utils/FormatacaoDeDados/CompleteWithZerosDocFinanceiro";
import { formatDateToISO } from "../../../../utils/FormatacaoDeDados/FormatDateToRegister";
import { validateDescription } from "../../../../utils/Gerais/validateDescription";
import { formatLabelContaBancaria } from "../../../../utils/FormatacaoDeDados/FormatLabelContaBancaria";
import { CustomToast } from "../../../../components/CustomToast";

const optionsTipoAnulacao = [
  {
    label: "1 - Anulação - Remove o Registro Contábil",
    type: 1,
    value: "1 - Anulação - Remove o Registro Contábil",
  },
  {
    label: "2 - Restituição - Dedução da Receita Orçamentária",
    type: 2,
    value: "2 - Restituição - Dedução da Receita Orçamentária",
  },
];

const AnulacaoDeReceita = () => {
  // const dataAtual = ReturnDataAtual()
  //   .replace(/\//g, "-")
  //   .split("-")
  //   .reverse()
  //   .join("-");

  const currentDate = () => {
    const date = new Date();
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");

    return `${day}/${month}/${currentYear}`;
  };

  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useCurrentYear();

  const { id: paramsIdGuiaAnulacaoReceita } = useParams();
  const location = useLocation();
  const pathNameCadastro = location.pathname.includes("cadastro");

  const { data: dataGuiaReceita } = useFetchData(
    `/guiaDeReceita/${currentClient.clienteId}/${currentYear}`
  );
  const inputTipoAnulacao = useRef(null);
  const inputDate = useRef(null);
  const inputReceitaRef = useRef(null);
  const inputValorAnulacaoRef = useRef(null);
  const inputMotivoRef = useRef(null);
  const inputCaixaRef = useRef(null);
  const inputDocFinanceiroRef = useRef(null);

  const [optionsFonteRecurso, setOptionsFonteRecurso] = useState([
    {
      guiaReceitaId: "",
      numeroGuia: "",
      fonteId: "",
      codigo: "",
      titulo: "",
      porcentagem: "",
      valor: "",
    },
  ]);

  const [optionsGuiaReceita, setOptionsGuiaReceita] = useState([
    {
      guiaReceitaId: null,
      extraLabel: null,
      numeroGuia: null,
      saldoGuia: null,
      idNatureza: null,
      codigoNatureza: null,
      tituloNatureza: null,
      idContaBancaria: null,
      fontesGuiaReceita: [
        {
          guiaReceitaId: "",
          numeroGuia: "",
          fonteId: "",
          codigo: "",
          titulo: "",
          porcentagem: "",
          valor: "",
        },
      ],
      contasBancariaGuiaReceita: {
        guiaReceitaId: "",
        idContaBancaria: "",
        nameContaBancaria: "",
        agenciaContaBancaria: "",
      },
    },
  ]);
  const [isOpenToast, setIsOpenToast] = useState(false);
  const [inputGuiaReceita, setInputGuiaReceita] = useState("");
  const [inputContaBancaria, setInputContaBancaria] = useState("");

  const [indexEditGuiaReceita, setIndexEditGuiaReceita] = useState(null);
  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Motivo",
    numeroAba: 0,
  });
  const [valuesAddGuiaReceita, setValuesAddGuiaReceita] = useState({
    infosGuiaReceita: {
      guiaReceitaId: "",
      saldoGuia: "",
      idNatureza: "",
      label: "",
      valorAnulacao: "",
      numeroGuia: "",
    },
    fontesGuiaReceita: [
      {
        guiaReceitaId: "",
        numeroGuia: "",
        fonteId: "",
        codigo: "",
        titulo: "",
        porcentagem: "",
        valor: "",
      },
    ],
    contasBancariaGuiaReceita: [
      {
        guiaReceitaId: "",
        idContaBancaria: "",
        nameContaBancaria: "",
        agenciaContaBancaria: "",
      },
    ],
  });

  const [valuesNaturezaAntesDaEdicao, setValuesNaturezaAntesDaEdicao] =
    useState({
      saldoGuia: "0,00",
      valorAnulacao: "0,00",
    });

  const [valuesAnulacaoReceita, setValuesAnulacaoReceita] = useState({
    numero: "",
    guiaReceitaId: "",
    tipoAnulacao: optionsTipoAnulacao[0].value,
    data: currentDate(),
    docFinanceiro: "",
    clienteId: currentClient.clienteId,
    contaOrigemId: "",
    motivo: "",
    guiaReceitas: [
      {
        guiaReceitaId: "",
        numeroGuia: "",
        saldoGuia: "",
        idNatureza: "",
        label: "",
        valorAnulacao: "",
        idContaBancaria: "",
        nameContaBancaria: "",
        agenciaContaBancaria: "",
      },
    ],
    fontesGuiaReceita: [
      {
        guiaReceitaId: "",
        numeroGuia: "",
        fonteId: "",
        codigo: "",
        titulo: "",
        porcentagem: "",
        valor: "",
      },
    ],
    contasBancariaGuiaReceita: [
      {
        guiaReceitaId: "",
        idContaBancaria: "",
        nameContaBancaria: "",
        agenciaContaBancaria: "",
      },
    ],
  });

  const optionTipoAnulacaoIsRestituicao =
    "2 - Restituição - Dedução da Receita Orçamentária" ===
    valuesAnulacaoReceita.tipoAnulacao;

  const handleSelectAnulacaoReceita = (option) => {
    // setOptionTipoAnulacao(option.value);
    setValuesAnulacaoReceita((prevState) => ({
      ...prevState,
      tipoAnulacao: option.value,
    }));
    inputDate.current.focus();
  };

  const onChangeDate = (value) => {
    setValuesAnulacaoReceita((prev) => ({
      ...prev,
      data: value,
    }));
  };

  const handleSelectGuiaReceita = (option) => {
    setValuesAddGuiaReceita({
      infosGuiaReceita: {
        // idContaBancaria: option.idContaBancaria,
        // agenciaContaBancaria: option.agenciaContaBancaria,
        // nameContaBancaria: option.nameContaBancaria,
        idNatureza: option.idNatureza,
        label: option.label,
        guiaReceitaId: option.guiaReceitaId,
        numeroGuia: option.numeroGuia,
        saldoGuia: option.saldoGuia,
        valorAnulacao: !optionTipoAnulacaoIsRestituicao
          ? option.saldoGuia
          : "0,00",
      },
      fontesGuiaReceita: option.fontesGuiaReceita,
      contasBancariaGuiaReceita: option.contasBancariaGuiaReceita,
    });
    if (optionTipoAnulacaoIsRestituicao) inputValorAnulacaoRef.current.focus();
  };

  const onChangeValue = (value) => {
    console.log(value);

    setValuesAddGuiaReceita((prev) => ({
      ...prev,
      infosGuiaReceita: {
        ...prev.infosGuiaReceita,
        valorAnulacao: value,
      },
    }));
  };

  const handleSelectContaBancaria = (option) => {
    console.log(option);

    setValuesAnulacaoReceita((prevState) => ({
      ...prevState,
      contaOrigemId: option.idContaBancaria,
    }));
  };

  const handleAddGuiaReceita = () => {
    const hasSaldoGuia = valuesAddGuiaReceita.infosGuiaReceita.saldoGuia;
    if (!hasSaldoGuia) {
      toast.error("Esta Guia não possui saldo");
      return;
    }

    if (!validateFieldsGuiaReceita()) return;

    if (optionTipoAnulacaoIsRestituicao && !validateSaldoAnulacao()) return;

    if (!checkExistingGuiaReceitas()) {
      setInputContaBancaria(
        `${valuesAddGuiaReceita.contasBancariaGuiaReceita.agenciaContaBancaria} - ${valuesAddGuiaReceita.contasBancariaGuiaReceita.nameContaBancaria}`
      );
      setValuesAnulacaoReceita({
        ...valuesAnulacaoReceita,
        contaOrigemId:
          valuesAddGuiaReceita.contasBancariaGuiaReceita.idContaBancaria,
        guiaReceitas: [valuesAddGuiaReceita.infosGuiaReceita],
        fontesGuiaReceita: [
          ...valuesAddGuiaReceita.fontesGuiaReceita,
          ...valuesAnulacaoReceita.fontesGuiaReceita,
        ],
        contasBancariaGuiaReceita: [
          valuesAddGuiaReceita.contasBancariaGuiaReceita,
          ...valuesAnulacaoReceita.contasBancariaGuiaReceita,
        ],
      });
      setOptionsFonteRecurso([
        ...valuesAddGuiaReceita.fontesGuiaReceita,
        ...valuesAnulacaoReceita.fontesGuiaReceita,
      ]);
      resetGuiaReceitaFields();
      inputMotivoRef.current.focus();
      return;
    }

    if (guiaAlreadyExists()) {
      toast.error("Esta Guia já foi adicionada.");
      return;
    }

    if (!validateContaBancaria()) {
      toast.error("Adicione Receitas com a mesma Natureza e Conta Bancária.");
      return;
    }

    addGuiaReceita();

    inputMotivoRef.current.focus();
  };

  const validateFieldsGuiaReceita = () => {
    const hasValues = Object.values(
      valuesAddGuiaReceita.infosGuiaReceita
    ).every((item) => item !== "");

    if (!hasValues) {
      toast.error(
        "Preencha todos os campos corretamente para adicionar a Guia Orçamentária."
      );
      return false;
    }

    return true;
  };

  const validateSaldoAnulacao = () => {
    const saldoMenorValorAnulacao =
      parseFloat(valuesAddGuiaReceita.infosGuiaReceita.saldoGuia) <
      parseFloat(valuesAddGuiaReceita.infosGuiaReceita.valorAnulacao);

    if (saldoMenorValorAnulacao) {
      toast.error("O valor de anulação não pode ser maior que o saldo.");
      return false;
    }

    return true;
  };

  const checkExistingGuiaReceitas = () => {
    const filterGuiaReceita = valuesAnulacaoReceita.guiaReceitas.filter(
      (item) => item.idNatureza
    );

    if (!filterGuiaReceita.length) {
      return false;
    }

    return true;
  };

  const guiaAlreadyExists = () => {
    return valuesAnulacaoReceita.guiaReceitas.some(
      (item) =>
        item.guiaReceitaId ===
        valuesAddGuiaReceita.infosGuiaReceita.guiaReceitaId
    );
  };

  const validateContaBancaria = () => {
    return valuesAnulacaoReceita.guiaReceitas.every(
      (item) =>
        item.idContaBancaria ===
          valuesAddGuiaReceita.infosGuiaReceita.idContaBancaria &&
        item.idNatureza === valuesAddGuiaReceita.infosGuiaReceita.idNatureza
    );
  };

  const addGuiaReceita = () => {
    setValuesAnulacaoReceita({
      ...valuesAnulacaoReceita,
      contaOrigemId:
        valuesAddGuiaReceita.contasBancariaGuiaReceita.idContaBancaria,
      guiaReceitas: [
        valuesAddGuiaReceita.infosGuiaReceita,
        ...valuesAnulacaoReceita.guiaReceitas,
      ],
      fontesGuiaReceita: [
        ...valuesAddGuiaReceita.fontesGuiaReceita,
        ...valuesAnulacaoReceita.fontesGuiaReceita,
      ],
      contasBancariaGuiaReceita: [
        valuesAddGuiaReceita.contasBancariaGuiaReceita,
        ...valuesAnulacaoReceita.contasBancariaGuiaReceita,
      ],
    });
    resetGuiaReceitaFields();
  };

  const resetGuiaReceitaFields = () => {
    setValuesAddGuiaReceita({
      ...valuesAddGuiaReceita,
      infosGuiaReceita: {
        idNatureza: "",
        label: "",
        guiaReceitaId: "",
        numeroGuia: "",
        saldoGuia: "0,00",
        valorAnulacao: "0,00",
      },
    });
    setInputGuiaReceita("");
  };

  const handleEditGuiaReceita = (indexGuiaReceita) => {
    const { saldoGuia, valorAnulacao } =
      valuesAnulacaoReceita.guiaReceitas[indexGuiaReceita];
    setValuesNaturezaAntesDaEdicao({ saldoGuia, valorAnulacao });
    setIndexEditGuiaReceita(indexGuiaReceita);
  };

  const handleDeleteGuiaReceita = (indexGuiaReceita, option) => {
    const guiaReceitas = valuesAnulacaoReceita.guiaReceitas.filter(
      (_, index) => index !== indexGuiaReceita
    );
    const fontesGuiaReceita = valuesAnulacaoReceita.fontesGuiaReceita.filter(
      (item) => item.guiaReceitaId !== option.guiaReceitaId
    );

    const contasBancariaGuiaReceita =
      valuesAnulacaoReceita.contasBancariaGuiaReceita.filter(
        (item) =>
          item.guiaReceitaId !== option.guiaReceitaId && item.guiaReceitaId
      );

    setValuesAnulacaoReceita({
      ...valuesAnulacaoReceita,
      guiaReceitas,
      fontesGuiaReceita,
      contasBancariaGuiaReceita,
    });

    setOptionsFonteRecurso(fontesGuiaReceita);

    if (!contasBancariaGuiaReceita.length) {
      setInputContaBancaria("");
    }
  };

  const handleSaveEditGuiaReceita = (option) => {
    console.log(option);

    if (!optionTipoAnulacaoIsRestituicao) {
      setIndexEditGuiaReceita(null);
      return;
    }
    const saldoMenorValorAnulacao =
      parseFloat(option.saldoGuia) < parseFloat(option.valorAnulacao);

    if (saldoMenorValorAnulacao) {
      toast.error("O valor de anulação não pode ser maior que o saldo.");
      return;
    }
    setIndexEditGuiaReceita(null);
  };

  const handleCancelEditGuiaReceita = () => {
    const guiaReceitas = valuesAnulacaoReceita.guiaReceitas.map(
      (item, index) => {
        if (index === indexEditGuiaReceita) {
          return {
            ...item,
            saldoGuia: valuesNaturezaAntesDaEdicao.saldoGuia,
            valorAnulacao: valuesNaturezaAntesDaEdicao.valorAnulacao,
          };
        }
        return item;
      }
    );

    setValuesAnulacaoReceita({ ...valuesAnulacaoReceita, guiaReceitas });
    setIndexEditGuiaReceita(null);
  };

  const handleEditValorSaldoGuia = (indexGuiaReceita, saldoGuia) => {
    const guiaReceitas = valuesAnulacaoReceita.guiaReceitas.map(
      (item, index) => {
        if (index === indexGuiaReceita) {
          return {
            ...item,
            saldoGuia,
          };
        }
        return item;
      }
    );

    setValuesAnulacaoReceita({ ...valuesAnulacaoReceita, guiaReceitas });
  };

  const handleEditValorAnulacao = (indexGuiaReceita, valorAnulacao) => {
    const guiaReceitas = valuesAnulacaoReceita.guiaReceitas.map(
      (item, index) => {
        if (index === indexGuiaReceita) {
          return {
            ...item,
            valorAnulacao,
          };
        }
        return item;
      }
    );

    setValuesAnulacaoReceita({ ...valuesAnulacaoReceita, guiaReceitas });
  };

  const handleResetValuesCaixa = () => {
    console.log(optionTipoAnulacaoIsRestituicao);
    if (!optionTipoAnulacaoIsRestituicao) return;

    console.log(optionTipoAnulacaoIsRestituicao);

    setInputContaBancaria("");
  };

  const handleKeyDown = async (e, nextRef, hasInputValue = false) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      if (optionTipoAnulacaoIsRestituicao) {
        inputDocFinanceiroRef.current.focus();
        return;
      }
      inputDocFinanceiroRef.current.focus();
      return;
    }
    if (hasInputValue && e.key === "Enter") {
      e.preventDefault();
      handleAddGuiaReceita();
      return;
    }
    if (e.key === "Enter") {
      if (nextRef && nextRef.current) {
        e.preventDefault();
        nextRef.current.focus();
      }
    }
  };

  const validatedValuesRegister = () => {
    console.log(valuesAnulacaoReceita);

    const infosRegisterAnulacaoReceita = {
      clienteId: valuesAnulacaoReceita.clienteId,
      tipoAnulacao: parseInt(valuesAnulacaoReceita.tipoAnulacao[0]),
      data: formatDateToISO(valuesAnulacaoReceita.data),
      totalAnulacao: totalAnulacaoReceita,
      contaOrigemId: valuesAnulacaoReceita.contaOrigemId,
      motivo: valuesAnulacaoReceita.motivo,
      docFinanceiro: parseInt(
        completeWithZerosDocFinanceiro(valuesAnulacaoReceita.docFinanceiro)
      ),
      guiaDeReceita: formattedGuiaReceitaByRegister(
        valuesAnulacaoReceita.guiaReceitas
      ),
      fontes: formattedFonteRecursosByRegister(
        valuesAnulacaoReceita.fontesGuiaReceita
      ),
    };
    console.log(infosRegisterAnulacaoReceita);

    const hasInfosCadastro = Object.values(infosRegisterAnulacaoReceita).every(
      (item) =>
        item !== "" &&
        item !== null &&
        item !== 0 &&
        String(item)?.trim() !== "" &&
        !Number.isNaN(item)
    );

    if (!hasInfosCadastro) {
      toast.error("Informe todos os campos.");
      return;
    }

    const hasFontes = infosRegisterAnulacaoReceita.fontes.length;

    if (!hasFontes) {
      toast.error("Adicione uma Fonte de Recursos.");
      return;
    }

    const hasGuiaReceita = infosRegisterAnulacaoReceita.guiaDeReceita.length;

    if (!hasGuiaReceita) {
      toast.error("Adicione Receitas para serem anuladas.");
      return;
    }

    const isValidDescription = validateDescription(
      infosRegisterAnulacaoReceita.motivo
    );
    if (!isValidDescription) {
      return;
    }
    return infosRegisterAnulacaoReceita;
    // if (paramsIdGuiaAnulacaoReceita && !pathNameCadastro) {
    //   console.log(infosRegisterAnulacaoReceita);
    //   await putAnulacaoReceita(infosRegisterAnulacaoReceita);
    //   return;
    // }
    // await postAnulacaoReceita(infosRegisterAnulacaoReceita);
  };

  const handleSave = () => {
    const infosReceita = validatedValuesRegister();

    if (!infosReceita) return;

    setIsOpenToast(true);
  };

  const saveRegisterToast = async () => {
    const infosRegisterAnulacaoReceita = validatedValuesRegister();

    if (paramsIdGuiaAnulacaoReceita && !pathNameCadastro) {
      console.log(infosRegisterAnulacaoReceita);
      await putAnulacaoReceita(infosRegisterAnulacaoReceita);
      return;
    }
    console.log(infosRegisterAnulacaoReceita);
    await postAnulacaoReceita(infosRegisterAnulacaoReceita);
  };

  const formattedGuiaReceitaByRegister = (guias) => {
    if (!guias.length) return [];
    const formattedGuias = guias
      .map((item) => {
        return {
          guiaReceitaId: item.guiaReceitaId,
          saldoGuia: parseFloat(item.saldoGuia),
          valorAnulacao: parseFloat(item.valorAnulacao),
        };
      })
      .filter((guia) => guia.guiaReceitaId);
    return formattedGuias;
  };

  const formattedFonteRecursosByRegister = (fontes) => {
    if (!fontes.length) return [];
    const formattedFontes = fontes
      .map((item) => {
        return {
          guiaId: item.guiaReceitaId,
          fonteId: item.fonteId,
          percentual: parseFloat(item.porcentagem.replace(/,/g, ".")),
          valor: parseFloat(item.valor.replace(/,/g, ".")),
        };
      })
      .filter((fonte) => fonte.fonteId);
    return formattedFontes;
  };

  const postAnulacaoReceita = async (infosAnulacaoReceita) => {
    try {
      await api.post("anulacaoReceita", infosAnulacaoReceita);
      toast.success("Cadastro realizado com sucesso.");
      resetFieldsAnulacaoReceita();
      setInputContaBancaria("");
    } catch (error) {
      if (error.response.status === 500) {
        toast.error("Erro ao criar o cadastro. Esta Guia já foi anulada.");
        return;
      }
      console.log(error);
      toast.error("Erro ao criar o cadastro. Tente novamente mais tarde.");
    }
  };

  const putAnulacaoReceita = async (infosAnulacaoReceita) => {
    try {
      await api.put(
        `anulacaoReceita/update/${paramsIdGuiaAnulacaoReceita}`,
        infosAnulacaoReceita
      );

      toast.success("Registro atualizado com sucesso.");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao atualizar o registro. Tente novamente mais tarde.");
    }
  };

  const resetFieldsAnulacaoReceita = () => {
    setValuesAnulacaoReceita({
      ...valuesAnulacaoReceita,
      docFinanceiro: "",
      contaOrigemId: "",
      motivo: "",
      guiaReceitas: [
        {
          guiaReceitaId: "",
          saldoGuia: "",
          idNatureza: "",
          label: "",
          valorAnulacao: "",
          idContaBancaria: "",
          nameContaBancaria: "",
          agenciaContaBancaria: "",
        },
      ],
      fontesGuiaReceita: [
        {
          guiaReceitaId: "",
          fonteId: "",
          codigo: "",
          titulo: "",
          porcentagem: "",
          valor: "",
        },
      ],
      contasBancariaGuiaReceita: [
        {
          guiaReceitaId: "",
          idContaBancaria: "",
          nameContaBancaria: "",
          agenciaContaBancaria: "",
        },
      ],
    });
  };

  const onChangeTextDocFinanceiro = (value) => {
    console.log(value);

    const inputValue = value.replace(/\D/g, "");
    const maskedValue = inputValue.slice(0, 11);
    setValuesAnulacaoReceita((prevState) => ({
      ...prevState,
      docFinanceiro: maskedValue,
    }));
  };

  const handleFocus = () => {
    inputMotivoRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const formattedValuesAnulacaoReceitaById = (dataAnulacaoReceita) => {
    const typeAnulacaoReceita = optionsTipoAnulacao.find(
      (item) => item.type === dataAnulacaoReceita.tipoAnulacao
    );

    if (!typeAnulacaoReceita) {
      toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
      return;
    }

    const date = dataAnulacaoReceita.data.split("T")[0];
    const [year, month, day] = date.split("-");
    const dateFormatted = `${day}/${month}/${year}`;

    console.log(dataAnulacaoReceita);

    const guiaReceitas = dataAnulacaoReceita.guiasDeReceita.map((item) => {
      return {
        guiaReceitaId: item.guiaReceitaId,
        numeroGuia: item.info.numero,
        saldoGuia: item.saldoGuia,
        idNatureza: item.info.naturezaId,
        label: `${item.info.naturezaInfo.nr} - ${item.info.naturezaInfo.especificacao}`,
        valorAnulacao: item.valorAnulacao,
        idContaBancaria: dataAnulacaoReceita.contaOrigemInfo.id,
        nameContaBancaria: dataAnulacaoReceita.contaOrigemInfo.nome,
        agenciaContaBancaria: formatLabelContaBancaria(
          dataAnulacaoReceita.contaOrigemInfo.numeroConta,
          dataAnulacaoReceita.contaOrigemInfo.digito
        ),
      };
    });

    const fontesGuiaReceita = dataAnulacaoReceita.fontes
      .map((item) => {
        return item.fonteInfo.map((fonte) => {
          return {
            guiaReceitaId: item.guiaId,
            fonteId: item.fonteId,
            codigo: fonte.conta,
            titulo: fonte.titulo,
            porcentagem: item.percentual,
            valor: item.valor,
          };
        });
      })
      .flat();

    const guiaReceitaId = dataAnulacaoReceita.guiasDeReceita[0].guiaReceitaId;

    const contaBancariaGuiaReceita = {
      guiaReceitaId,
      idContaBancaria: dataAnulacaoReceita.contaOrigemInfo.id,
      nameContaBancaria: dataAnulacaoReceita.contaOrigemInfo.nome,
      agenciaContaBancaria: formatLabelContaBancaria(
        dataAnulacaoReceita.contaOrigemInfo.numeroConta,
        dataAnulacaoReceita.contaOrigemInfo.digito
      ),
    };

    const infosAnulacao = {
      numero: dataAnulacaoReceita.id,
      tipoAnulacao: typeAnulacaoReceita.label,
      data: dateFormatted,
      docFinanceiro: dataAnulacaoReceita.docFinanceiro,
      clienteId: dataAnulacaoReceita.clienteId,
      contaOrigemId: dataAnulacaoReceita.contaOrigemId,
      motivo: dataAnulacaoReceita.motivo,
      guiaReceitas,
      fontesGuiaReceita,
      contasBancariaGuiaReceita: [contaBancariaGuiaReceita],
    };
    setInputContaBancaria(
      `${contaBancariaGuiaReceita.agenciaContaBancaria} - ${contaBancariaGuiaReceita.nameContaBancaria}`
    );
    setOptionsFonteRecurso(fontesGuiaReceita);

    return infosAnulacao;
  };

  const addGuiaReceitaId = useCallback(() => {
    const guiaReceita = optionsGuiaReceita.find(
      (item) => item.guiaReceitaId === Number(paramsIdGuiaAnulacaoReceita)
    );

    if (!guiaReceita) {
      toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
      return;
    }
    console.log(guiaReceita);

    const infosGuiaReceita = {
      guiaReceitaId: guiaReceita.guiaReceitaId,
      numeroGuia: guiaReceita.numeroGuia,
      saldoGuia: guiaReceita.saldoGuia,
      idNatureza: guiaReceita.idNatureza,
      label: `${guiaReceita.codigoNatureza} - ${guiaReceita.tituloNatureza}`,
      valorAnulacao: guiaReceita.saldoGuia,
      idContaBancaria: guiaReceita.idContaBancaria,
      nameContaBancaria:
        guiaReceita.contasBancariaGuiaReceita.nameContaBancaria,
      agenciaContaBancaria:
        guiaReceita.contasBancariaGuiaReceita.agenciaContaBancaria,
    };

    setValuesAnulacaoReceita((prevState) => ({
      ...prevState,
      contaOrigemId: guiaReceita.idContaBancaria,
      guiaReceitas: [infosGuiaReceita],
      fontesGuiaReceita: guiaReceita.fontesGuiaReceita,
      contasBancariaGuiaReceita: [guiaReceita.contasBancariaGuiaReceita],
    }));
    setInputContaBancaria(
      `${guiaReceita.contasBancariaGuiaReceita.agenciaContaBancaria} - ${guiaReceita.contasBancariaGuiaReceita.nameContaBancaria}`
    );
    setOptionsFonteRecurso(guiaReceita.fontesGuiaReceita);
  }, [optionsGuiaReceita, paramsIdGuiaAnulacaoReceita]);

  const fetchDataAnulacaoReceitaById = useCallback(async () => {
    try {
      const resp = await api.get(
        `/anulacaoReceita/${paramsIdGuiaAnulacaoReceita}/${currentYear}`
      );
      const data = resp.data;
      const infosAnulacaoReceita = formattedValuesAnulacaoReceitaById(data);
      setValuesAnulacaoReceita(infosAnulacaoReceita);
    } catch (error) {
      toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
    }
  }, [currentYear, paramsIdGuiaAnulacaoReceita]);

  useEffect(() => {
    if (dataGuiaReceita) {
      console.log(dataGuiaReceita);

      const formattedOptions = dataGuiaReceita.map((receita) => {
        const fontesGuiaReceita = receita.fontes.map((fonte) => {
          return {
            guiaReceitaId: receita.id,
            numeroGuia: receita.numero,
            fonteId: fonte.fonteId,
            codigo: fonte.info.conta,
            titulo: fonte.info.titulo,
            porcentagem: "0,00",
            valor: fonte.valor,
          };
        });
        const contasBancariaGuiaReceita = {
          idContaBancaria: receita.contaBancariaInfo.id,
          nameContaBancaria: receita.contaBancariaInfo.nome,
          agenciaContaBancaria: formatLabelContaBancaria(
            receita.contaBancariaInfo.numeroConta,
            receita.contaBancariaInfo.digito
          ),
          guiaReceitaId: receita.id,
        };
        return {
          guiaReceitaId: receita.id,
          extraLabel: receita.numero,
          numeroGuia: receita.numero,
          saldoGuia: receita.saldo,
          idNatureza: receita.naturezaId,
          codigoNatureza: receita.naturezaInfo.nr,
          tituloNatureza: receita.naturezaInfo.especificacao,
          idContaBancaria: receita.contaBancariaId,
          nameContaBancaria: receita.contaBancariaInfo.nome,
          agenciaContaBancaria: formatLabelContaBancaria(
            receita.contaBancariaInfo.numeroConta,
            receita.contaBancariaInfo.digito
          ),
          fontesGuiaReceita,
          contasBancariaGuiaReceita,
        };
      });
      setOptionsGuiaReceita(formattedOptions);
    }
  }, [dataGuiaReceita]);

  useEffect(() => {
    if (paramsIdGuiaAnulacaoReceita && !pathNameCadastro) {
      fetchDataAnulacaoReceitaById();

      return;
    }
    const hasOptionsGuiaReceita = optionsGuiaReceita.every(
      (item) => item.guiaReceitaId !== null
    );
    if (
      paramsIdGuiaAnulacaoReceita &&
      pathNameCadastro &&
      hasOptionsGuiaReceita
    ) {
      addGuiaReceitaId();
      inputMotivoRef.current.focus();
    }
  }, [
    addGuiaReceitaId,
    fetchDataAnulacaoReceitaById,
    optionsGuiaReceita,
    paramsIdGuiaAnulacaoReceita,
    pathNameCadastro,
  ]);

  useEffect(() => {
    if (paramsIdGuiaAnulacaoReceita) return;

    inputTipoAnulacao.current.focus();
  }, [paramsIdGuiaAnulacaoReceita]);

  const totalAnulacaoReceita = useMemo(() => {
    const total = valuesAnulacaoReceita.guiaReceitas.reduce((acc, item) => {
      // console.log(parseFloat(item.valorAnulacao.replace(",",".")));
      const valorAnulacao =
        typeof item.valorAnulacao === "string"
          ? item.valorAnulacao.replace(",", ".")
          : String(item.valorAnulacao || "0");
      acc += parseFloat(valorAnulacao) || 0;
      return acc;
    }, 0);

    return total;
  }, [valuesAnulacaoReceita.guiaReceitas]);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70", marginBottom: "10px" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        PaginaSubtitulo={"Cadastro"}
        NomePaginaTitulo={"Anulação de Receita"}
        ButtonSaveFunction={handleSave}
        PaginaConsulta={"/contabilidade/receita/anulacao-de-receita/consulta"}
        BotaoNovoAtivo={false}
      />
      <ToastContainer />
      <CustomToast
        isOpenToast={isOpenToast}
        setIsOpenToast={setIsOpenToast}
        saveRegister={saveRegisterToast}
      />
      <RowForm className="mt-1">
        <CCol md={2}>
          <FloatingLabelInput
            disabled
            label="Número"
            placeholder="Número"
            maxLength="7"
            value={valuesAnulacaoReceita.numero}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            ref={inputTipoAnulacao}
            tipo="input_select"
            new
            disabled={paramsIdGuiaAnulacaoReceita && pathNameCadastro}
            placeholder="Tipo de Anulação"
            label="Tipo de Anulação"
            value={valuesAnulacaoReceita.tipoAnulacao}
            options={[
              { label: "Tipo de Anulação", value: null },
              ...optionsTipoAnulacao,
            ]}
            onSelect={(option) => handleSelectAnulacaoReceita(option)}
          />
        </CCol>
        <CCol md={2}>
          <InputData
            ref={inputDate}
            label="Data"
            disabled={paramsIdGuiaAnulacaoReceita && !pathNameCadastro}
            onChange={(e) => onChangeDate(e.target.value)}
            externalValue={valuesAnulacaoReceita.data}
            onKeyDown={(e) => handleKeyDown(e, inputReceitaRef)}
          />
        </CCol>
      </RowForm>

      <RowForm className="mt-1">
        <div style={{ width: "125px" }}>
          <PTitulosInputs>Guia</PTitulosInputs>
        </div>
        <CCol>
          <PTitulosInputs>Receita Realizada</PTitulosInputs>
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>Saldo da Guia</PTitulosInputs>
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>Valor Anulação</PTitulosInputs>
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
        </CColButtonsAcoes2Icons>

        <RowAninhada>
          <div style={{ width: "125px" }}>
            <FormControlListaDeInputs
              disabled
              value={valuesAddGuiaReceita.infosGuiaReceita.numeroGuia}
            />
          </div>
          <CCol>
            <MyAutoComplete
              ref={inputReceitaRef}
              inputValue={inputGuiaReceita}
              setInputValue={setInputGuiaReceita}
              options={optionsGuiaReceita}
              labelFormat={(option) =>
                `${option.codigoNatureza} - ${option.tituloNatureza}`
              }
              onOptionSelect={(option) => {
                handleSelectGuiaReceita(option);
              }}
              iconClearFunction={resetGuiaReceitaFields}
            />
          </CCol>
          <CCol md={2}>
            <InputFormatRealBrasileiro
              listaDeInputs
              disabled
              externalValue={valuesAddGuiaReceita.infosGuiaReceita.saldoGuia}
            />
          </CCol>
          <CCol md={2}>
            <InputFormatRealBrasileiro
              ref={inputValorAnulacaoRef}
              listaDeInputs
              disabled={!optionTipoAnulacaoIsRestituicao}
              externalValue={
                valuesAddGuiaReceita.infosGuiaReceita.valorAnulacao
              }
              onChange={(_, value) => onChangeValue(value)}
              onKeyDown={(e) => handleKeyDown(e, inputMotivoRef, true)}
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <IconAdicionar onClick={handleAddGuiaReceita} />
          </CColButtonsAcoes2Icons>
        </RowAninhada>

        {valuesAnulacaoReceita.guiaReceitas.map((item, index, array) => {
          if (!item.idNatureza) return null;
          return (
            <RowAninhada key={index}>
              <div style={{ width: "125px" }}>
                <FormControlListaDeInputs
                  disabled
                  value={item.numeroGuia}
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                />
              </div>
              <CCol>
                <FormControlListaDeInputs
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                  value={item.label}
                  disabled
                />
              </CCol>
              <CCol md={2}>
                <InputFormatRealBrasileiro
                  listaDeInputs
                  externalValue={item.saldoGuia}
                  // disabled={index !== indexEditGuiaReceita}
                  disabled
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                  onChange={(_, valor) =>
                    handleEditValorSaldoGuia(index, valor)
                  }
                />
              </CCol>
              <CCol md={2}>
                <InputFormatRealBrasileiro
                  listaDeInputs
                  externalValue={item.valorAnulacao}
                  disabled={
                    !optionTipoAnulacaoIsRestituicao ||
                    index !== indexEditGuiaReceita
                  }
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                  onChange={(_, valor) => handleEditValorAnulacao(index, valor)}
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                {indexEditGuiaReceita !== index && (
                  <>
                    <Icon
                      onClick={() => handleEditGuiaReceita(index)}
                      icon="bx:edit"
                      color="#5971C8"
                      height="26"
                      style={{
                        cursor: "pointer",
                        marginTop: "3px",
                      }}
                    />
                    <Icon
                      onClick={() => handleDeleteGuiaReceita(index, item)}
                      icon="ic:baseline-delete"
                      color="#E79900"
                      height="26"
                      style={{
                        cursor: "pointer",
                        marginLeft: "3px",
                        marginTop: "2px",
                      }}
                    />
                  </>
                )}
                {indexEditGuiaReceita !== null &&
                  indexEditGuiaReceita === index && (
                    <>
                      <Icon
                        onClick={() => handleSaveEditGuiaReceita(item)}
                        icon="fluent:save-24-filled"
                        color="#008CFF"
                        height="26"
                        style={{
                          cursor: "pointer",
                          marginTop: "3px",
                        }}
                      />
                      <Icon
                        icon="ic:baseline-cancel"
                        onClick={() => handleCancelEditGuiaReceita(index)}
                        height="26"
                        color="#F24E1E"
                        style={{
                          display:
                            indexEditGuiaReceita !== index
                              ? "none"
                              : "inline-block",
                          pointerEvents:
                            indexEditGuiaReceita !== index ? "none" : "auto",
                          cursor:
                            indexEditGuiaReceita !== index
                              ? "not-allowed"
                              : "pointer",
                          marginLeft: "2px",
                          marginRight: "-5px",
                          marginTop: "3px",
                        }}
                      />
                    </>
                  )}
              </CColButtonsAcoes2Icons>
            </RowAninhada>
          );
        })}

        <RowAninhada style={{ marginTop: "16px" }}>
          <CCol md={1}></CCol>
          <CCol></CCol>
          <CCol
            md={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ margin: 0, padding: 0, fontWeight: 600 }}>
              Total Anulação
            </p>
          </CCol>
          <CCol md={2}>
            <FormControlListaDeInputs
              disabled
              style={{ border: "none", borderRadius: 20, textAlign: "right" }}
              value={`R$ ${FormatValueToLocaleString(totalAnulacaoReceita)}`}
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}></CColButtonsAcoes2Icons>
        </RowAninhada>
      </RowForm>

      <RowSelecaoAbasInferior
        abas={["Motivo", "Fonte de Recursos"]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      />
      {abaSelecionada.nomeAba === "Motivo" && (
        <MotivoAba
          ref={inputMotivoRef}
          onFocus={handleFocus}
          handleKeyDown={handleKeyDown}
          infosAnulacaoReceita={valuesAnulacaoReceita}
          setInfosAnulacaoReceita={setValuesAnulacaoReceita}
        />
      )}
      {abaSelecionada.nomeAba === "Fonte de Recursos" && (
        <FonteDeRecursosAba
          infosAnulacaoReceita={valuesAnulacaoReceita}
          setInfosAnulacaoReceita={setValuesAnulacaoReceita}
          optionsFonteRecurso={optionsFonteRecurso}
          optionTipoAnulacaoIsRestituicao={optionTipoAnulacaoIsRestituicao}
        />
      )}

      <RowTituloAba>
        <div>
          <span>Origem Financeira</span>
        </div>
      </RowTituloAba>

      <RowForm style={{ marginTop: "5px" }} $borderRadiusType={3}>
        <CCol>
          <MyAutoComplete
            ref={inputCaixaRef}
            style={{ height: "37px" }}
            placeholder="Caixa e Equivalente Caixa"
            labelInput="Caixa e Equivalente Caixa"
            disabled={!optionTipoAnulacaoIsRestituicao}
            options={valuesAnulacaoReceita.contasBancariaGuiaReceita}
            labelFormat={(item) =>
              `${item.agenciaContaBancaria} - ${item.nameContaBancaria}`
            }
            inputValue={inputContaBancaria}
            setInputValue={setInputContaBancaria}
            onOptionSelect={(option) => handleSelectContaBancaria(option)}
            iconClearFunction={handleResetValuesCaixa}
          />
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            ref={inputDocFinanceiroRef}
            type="text"
            label="Doc Financeiro"
            placeholder="Doc Financeiro"
            value={valuesAnulacaoReceita.docFinanceiro}
            onChange={(e) => onChangeTextDocFinanceiro(e.target.value)}
          />
        </CCol>
      </RowForm>
      <div className="p-3" style={{ minHeight: "80px" }}></div>
    </Container>
  );
};

export default AnulacaoDeReceita;
