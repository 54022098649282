import React, { useEffect, useState, useRef } from "react";
import { Col, Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowAninhada, RowForm } from "../../../../components/Grid/CCol";
import InputData from "../../../../components/Grid/InputData";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import styled from "styled-components";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import HistoricoAba from "./HistoricoAba";
import ProdutoEAquisicaoAba from "./ProdutoEAquisicaoAba";
import ComplementoAba from "./ComplementoAba";
import SaldosAba from "./SaldosAba";
import { useCurrentClient } from "../../../../hooks/useCurrentClient";
import { useOpenedModals } from "../../../../hooks/useOpenedModals";
import ModalAutoComplete from "../../../../components/Modais/ModalAutoComplete";
import ComponentCadastroCpfCnpj from "../../../../components/ComponentCadastroCpfCnpj";
import { ToastContainer, toast } from "react-toastify";
import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";
import AutoCompleteFichas from "../../../../components/Inputs/AutoCompleteFichas";
import { gerenciaOpcoesOrdemCronologica } from "./gerenciaOpcoesOrdemCronologica";
import { getAllNaturezasDaDespesa } from "../../../../services/gets/getAllNaturezasDaDespesa";
import { gerenciaOpcoesSubelemento } from "./gerenciaOpcoesSubelemento";
import {
  getConveniosPbPorAno,
  getObrasTcePorCliente,
} from "../../../../services/tce/gets";
import { TestButton } from "../../../../utils/TestButton";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useLocation, useParams } from "react-router-dom";
import { formatToCurrency } from "../../../../utils/FormatacaoDeDados/FormatToCurrency";
import api from "../../../../utils/api";
import ProcedimentosContabeisAba from "./ProcedimentosContabeisAba";
import { CustomToast } from "../../../../components/CustomToast";
import { CollapseRow } from "../../../../components/CollapseRow/CollapseRow";
import LiquidacaoAba from "./LiquidacaoAba";
import RetencaoAba from "./RetencaoAba";

const CColNumeroAndEVB = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const RowInfosAcao = styled(RowAninhada)`
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 5px 0px;

  p {
    margin: 3px;
    color: #888a8e;
    font-weight: 500;
    font-size: 14px;
  }

  p > span {
    color: #515c70;
  }
`;

const NotaDeEmpenho = () => {
  //contexts
  // const navigate = useNavigate();
  const location = useLocation();
  const isReplica = location.pathname.includes("replica");
  const { numero } = useParams();
  const { currentYear } = useCurrentYear();
  const { currentClient } = useCurrentClient();

  const [allNaturezasDaDespesa, setAllNaturezasDaDespesa] = useState([]);
  const [optionsCpf, setOptionsCpf] = useState([]);
  const [optionsFichas, setOptionsFichas] = useState([]);
  const [saldos, setSaldos] = useState({
    empenhado: "",
    liquidado: "",
  });
  const [optionsFontesDeRecursos, setOptionsFontesDeRecursos] = useState([]);
  const [optionsSubelementos, setOptionsSubelementos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [abas, setAbas] = useState([
    "Histórico",
    "Produto e Aquisição",
    "Complemento",
    "Saldos",
    "Procedimentos Contábeis",
  ]);
  const [optionsOrdemCronologica, setOptionsOrdemCronologica] = useState([
    {
      label: "0 - Outros",
      value: 0,
      disabled: true,
    },
    {
      label: "1 - Fornecimento de Bens",
      value: 1,
      disabled: true,
    },
    {
      label: "2 - Locações",
      value: 2,
      disabled: true,
    },
    {
      label: "3 - Prestação de Serviços",
      value: 3,
      disabled: true,
    },
    {
      label: "4 - Realização de Obras",
      value: 4,
      disabled: true,
    },
  ]);
  const [filteredOptionsFontesDeRecursos, setFilteredOptionsFontesDeRecursos] =
    useState([]);
  const [inputFicha, setInputFicha] = useState("");
  const [licitacoes, setLicitacoes] = useState([]);
  // const [licitacoesFiltradas, setLicitacoesFiltradas] = useState([]);
  // const [licitacoesOptions, setLicitacoesOptions] = useState([]);
  const { openedModals, setOpenedModals } = useOpenedModals();

  const [abaSelecionada, setAbaSelecionada] = useState({});
  const dataStr = ReturnDataAtual();
  const [dia, mes] = dataStr.split("/");
  const dataTemp = `${currentYear}-${mes}-${dia}`;
  const dataAtual = dataTemp;

  const [produtoAquisicao, setProdutoAquisicao] = useState([]);
  const [valuesNotaEmpenho, setValuesNotaEmpenho] = useState({
    numero: "",
    fornecedor: "",
    fornecedorData: "",
    fornecedorAtt: false,
    licitacao: "",
    data: dataAtual,
    dataTemp: dataAtual,
    dataTempAtt: false,
    tipoDeEmpenho: null,
    ordemCronologica: null,
    fichaId: 0,
    fonteDeRecursoId: null,
    subelemento: null,
    valor: "0,00",
    descricao: "",
  });
  const [infosFicha, setInfosFicha] = useState({});

  //input states
  const [inputFornecedor, setInputFornecedor] = useState("");
  const [optionsObras, setOptionsObras] = useState([]);
  const [inputValueObras, setInputValueObras] = useState("");
  const [optionsConvenios, setOptionsConvenios] = useState([]);
  const [inputValueConvenios, setInputValueConvenios] = useState("");
  const [optionsLicitacoes, setOptionsLicitacoes] = useState([]);
  const [isOpenToast, setIsOpenToast] = useState(false);
  const [collapseLiquidacao, setCollapseLiquidacao] = useState(false);
  const [collapseRetencao, setCollapseRetencao] = useState(false);
  const [liquidacaoData, setLiquidacaoData] = useState({
    valor: "0,00",
    fornecedor: null,
    fornecedorData: null,
    fornecedorAtt: false,
  });

  const inputFornecedorRef = useRef(null);
  const inputLicitacaoRef = useRef(null);
  const inputDataRef = useRef(null);
  const inputAcaoRef = useRef(null);
  const inputExercicioRef = useRef(null);
  const inputSubelementoRef = useRef(null);
  const inputOrdemCronologicaRef = useRef(null);
  const inputTipoEmpenhoRef = useRef(null);
  const inputValorRef = useRef(null);
  const inputDescricaoRef = useRef(null);
  const rowSelecaoRef = useRef();
  // const toastShown = useRef(false);

  useEffect(() => {
    const ficha = optionsFichas
      .find((item) => item.ficha.id === valuesNotaEmpenho?.fichaId)
      ?.elementoDaDespesa?.codigo.slice(0, -3);
    const valor = parseFloat(
      valuesNotaEmpenho.valor.replace(/\./g, "").replace(",", ".")
    );
    console.log(ficha);
    switch (ficha) {
      case "4.4.90.51":
        if (valor > 80000) {
          toast.warn(
            "Você atingiu o valor máximo que pode ser empenhado sem uma licitação"
          );
          setValuesNotaEmpenho((prevValues) => ({
            ...prevValues,
            maxValueReached: true,
          }));
        } else {
          setValuesNotaEmpenho((prevValues) => ({
            ...prevValues,
            maxValueReached: false,
          }));
        }
        break;
      case "3.3.90.30":
      case "3.3.90.39":
      case "4.4.90.52":
        if (valor > 50000 && !valuesNotaEmpenho.maxValueReached) {
          toast.warn(
            "Você atingiu o valor máximo que pode ser empenhado sem uma licitação"
          );
          setValuesNotaEmpenho((prevValues) => ({
            ...prevValues,
            maxValueReached: true,
          }));
        } else {
          setValuesNotaEmpenho((prevValues) => ({
            ...prevValues,
            maxValueReached: false,
          }));
        }
        break;
      case "3.3.90.34":
      case "3.3.90.36":
        if (valor > 10000 && !valuesNotaEmpenho.maxValueReached) {
          toast.warn(
            "Você atingiu o valor máximo que pode ser empenhado sem uma licitação"
          );
          setValuesNotaEmpenho((prevValues) => ({
            ...prevValues,
            maxValueReached: true,
          }));
        } else {
          setValuesNotaEmpenho((prevValues) => ({
            ...prevValues,
            maxValueReached: false,
          }));
        }
        break;
      default:
        setValuesNotaEmpenho((prevValues) => ({
          ...prevValues,
          maxValueReached: false,
        }));
    }
    //eslint-disable-next-line
  }, [
    valuesNotaEmpenho.valor,
    valuesNotaEmpenho.licitacao,
    valuesNotaEmpenho.fornecedor,
  ]);

  // 3.3.90.30 50.000,00
  // 3.3.90.34 10.000,00
  // 3.3.90.36 10.000,00
  // 3.3.90.39 50.000,00
  // 4.4.90.51 80.000,00
  // 4.4.90.52 50.000,00

  const clearValues = () => {
    setValuesNotaEmpenho((prevValues) => ({
      ...prevValues,
      numero: null,
      fornecedor: "",
      fornecedorData: "",
      fornecedorAtt: false,
      licitacao: null,
      tipoDeEmpenho: null,
      ordemCronologica: 0,
      fichaId: 0,
      fonteDeRecursoId: null,
      subelemento: null,
      valor: "0,00",
      descricao: "",
    }));
    setInputFicha("");
    setInputFornecedor("");
    setLiquidacaoData({
      valor: "0,00",
      fornecedor: null,
      fornecedorData: null,
    });
  };

  const handleGoRef = (nextRef) => {
    nextRef?.current?.focus();

    if (nextRef?.current) {
      nextRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  const fetchObras = async () => {
    const obras = await getObrasTcePorCliente(
      currentClient.clienteId,
      currentClient.codigoTCE
    );

    setOptionsObras(obras);
  };

  const fetchConvenios = async () => {
    const convenios = await getConveniosPbPorAno(currentYear);

    setOptionsConvenios(convenios);
  };

  const fetchFontesDeRecursos = async () => {
    try {
      const resp = await api.get(`/fonteDeRecurso/por-ano/${currentYear}`);
      const data = resp.data;
      setOptionsFontesDeRecursos(data);
    } catch (error) {
      console.log(error);
    }
  };

  const formatCpfOrCnpj = (value) => {
    if (!value) return "";

    const cleanedValue = value.replace(/\D/g, "");

    if (cleanedValue.length === 11) {
      // Formato CPF: 000.000.000-00
      return `${cleanedValue.slice(0, 3)}.${cleanedValue.slice(
        3,
        6
      )}.${cleanedValue.slice(6, 9)}-${cleanedValue.slice(9)}`;
    }

    if (cleanedValue.length === 14) {
      // Formato CNPJ: 00.000.000/0000-00
      return `${cleanedValue.slice(0, 2)}.${cleanedValue.slice(
        2,
        5
      )}.${cleanedValue.slice(5, 8)}/${cleanedValue.slice(
        8,
        12
      )}-${cleanedValue.slice(12)}`;
    }

    return value; // Retorna sem formatação se não for CPF nem CNPJ
  };

  const fetchCpfs = async (reqDeModal) => {
    try {
      const response = await api.get(
        `/pessoas/clientes/${currentClient.clienteId}`
      );

      const data = response.data.map((item) => ({
        id: item.dados.id,
        nome: item.dados.nomeCompleto || item.dados.nomeFantasia,
        documento: formatCpfOrCnpj(item.dados.cpf || item.dados.cnpj),
      }));

      if (data) {
        setOptionsCpf(
          data.map((item) => ({
            id: item.id,
            nome: item.nome,
            cpf: item.documento || "",
          }))
        );

        if (reqDeModal) {
          const dado = [...data].reverse()[0];

          setInputFornecedor(`${dado.nome} - ${dado.documento}`);
          setValuesNotaEmpenho((prev) => ({
            ...prev,
            fornecedorId: dado.id,
          }));

          setOpenedModals((prev) =>
            prev.filter((item) => item.tituloModal !== "CPF/CNPJ")
          );
        }
      }
    } catch (error) {
      console.error("Erro ao buscar dados do cliente:", error);
    }
  };

  const fetchAllNaturezasDaDespesa = async () => {
    const dados = await getAllNaturezasDaDespesa(
      currentClient.clienteId,
      currentYear
    );

    setAllNaturezasDaDespesa(dados);
  };

  const fetchQddFichas = async () => {
    try {
      const resp = await api.get(
        `/qdd/${currentClient.clienteId}/${currentYear}?filtrarUG=1`
      );
      const dados = resp.data;

      const arrayDeFichas = dados.map((item) => {
        const despesaFixadaQdd = item.despesaFixadaQDD;
        const fichaReferente = despesaFixadaQdd.fichasQDD;
        const fonteDeRecursosReferente = despesaFixadaQdd.fonteDeRecurso;
        const acaoGovernamentalInfo = item.acaoGovernamentalInfo;
        const exercicio = optionsFontesDeRecursos.find(
          (item) => item.e === fonteDeRecursosReferente.conta.split(".")[0]
        );

        return {
          pill: `Ficha ${fichaReferente.numeroFicha}`,
          acaoGovernamental: {
            id: acaoGovernamentalInfo.id,
            codigo: acaoGovernamentalInfo.nAcao,
            titulo: acaoGovernamentalInfo.titulo,
          },
          unidadeOrcamentaria: `${acaoGovernamentalInfo.unidadeOrcamentariaId.codigoUnidadeOrcamentaria} - ${acaoGovernamentalInfo.unidadeOrcamentariaId.tituloUnidadeOrcamentaria}`,
          funcao: `${acaoGovernamentalInfo.funcao.valor} - ${acaoGovernamentalInfo.funcao.nome}`,
          tipoDeVinculo: {
            ...acaoGovernamentalInfo.funcao,
          },
          subfuncao: `${acaoGovernamentalInfo.subFuncao.valor} - ${acaoGovernamentalInfo.subFuncao.nome}`,
          elementoDaDespesa: {
            codigo: fichaReferente.naturezaDaDespesa.codigo,
            titulo: fichaReferente.naturezaDaDespesa.nome,
            funcao: fichaReferente.naturezaDaDespesa.funcao,
          },
          fonteDeRecursos: `${fonteDeRecursosReferente.conta.split(".")[1]} - ${
            fonteDeRecursosReferente.titulo
          }`,
          fonteDeRecursosInfos: {
            conta: fonteDeRecursosReferente.conta,
          },
          programa: `${acaoGovernamentalInfo.programaPlanejamentoId.numeroPrograma} - ${acaoGovernamentalInfo.programaPlanejamentoId.tituloPrograma}`,
          ficha: {
            id: fichaReferente.id,
            ficha: fichaReferente.numeroFicha,
          },
          exercicio: `${exercicio.conta} - ${exercicio.titulo}`,
        };
      });

      setOptionsFichas(arrayDeFichas);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLicitacao = async () => {
    try {
      const response = await api.get(
        `/licitacao/${currentClient.codigoTCE}/${currentYear}?groupByFornecedor=1`
      );

      setLicitacoes(response.data);

      // const licitacoesOptionsLocais = licitacoesFiltradasPorDataEFornecedor?.map(
      //   (item) => ({
      //     label: `${item.numeroLicitacao}`,
      //     value: `${item.codigoUnidadeGestora}${item.numeroLicitacao}${item.dataLicitacao}${item.mesAno}`,
      //     title: item?.descricao,
      //   })
      // );

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleAddNewCpf = () => {
    setOpenedModals((prev) => [
      ...prev,
      { tituloModal: "CPF/CNPJ", requisicaoDoModalConcluida: false },
    ]);
  };

  const transformarData = (dataISO) => {
    if (!dataISO) return null; // Verifica se a data é válida
    const dataObj = new Date(dataISO);
    const ano = dataObj.getUTCFullYear(); // Ano em UTC
    const mes = String(dataObj.getUTCMonth() + 1).padStart(2, "0"); // Mês em UTC (adiciona +1)
    const dia = String(dataObj.getUTCDate()).padStart(2, "0"); // Dia em UTC
    return `${ano}-${mes}-${dia}`;
  };

  const fetchAllData = async () => {
    const fornecedorData = optionsCpf;

    let newObject = {};
    if (numero) {
      try {
        const empenhoAtual = await api.get(`/notaEmpenho/get/por-id/${numero}`);
        newObject = {
          ...newObject,
          data: transformarData(empenhoAtual?.data?.data),
          numero: empenhoAtual?.data?.numero,
          fornecedor: empenhoAtual?.data?.fornecedor,
          licitacao: empenhoAtual?.data?.licitacao,
          fichaId: empenhoAtual?.data?.fichaId,
          valor: empenhoAtual?.data?.valor,
          ordemCronologica: empenhoAtual?.data?.ordemCronologica,
          tipoDeEmpenho: empenhoAtual?.data?.tipoDeEmpenho,
          descricao: empenhoAtual?.data?.descricao,
        };
        setSaldos((prevValues) => ({
          ...prevValues,
          empenhado: empenhoAtual?.data?.saldoEmpenhado || "00.00",
          liquidado: empenhoAtual?.data?.saldoLiquidado || "00.00",
        }));

        let fornecedorEncontrado = fornecedorData.find(
          (item) =>
            item?.id &&
            newObject.fornecedor &&
            item?.id === parseInt(newObject.fornecedor)
        );

        const produtos = empenhoAtual?.data?.produtos.map((item) => ({
          ...item,
          produtoAquisicao: {
            id: item?.produtoAquisicaoId,
            value: item?.numero,
            label: `${item?.numero} - ${item?.produtoAquisicao}`,
          },
          unidadeMedida: item?.unidadeDeMedida,
        }));

        if (!isReplica) setProdutoAquisicao(produtos || []);

        if (fornecedorEncontrado) {
          const { nome, cpf, cnpj } = fornecedorEncontrado;
          setInputFornecedor(`${nome} - ${cpf || cnpj}`);
        }

        // AQUI: Definindo a ficha primeiro
        const ficha = optionsFichas.find(
          (item) => item.ficha.id === newObject.fichaId
        );

        // AQUI: Usando a ficha depois de ser definida
        if (ficha) {
          setInputFicha(
            `${ficha.acaoGovernamental.codigo} - ${ficha.acaoGovernamental.titulo}`
          );

          const { tipoDeVinculo, ...rest } = ficha;

          // Filtrando as fontes que podem ser vinculadas
          const filteredFontes = optionsFontesDeRecursos
            .filter(
              (item) =>
                (item.conta.includes(rest.fonteDeRecursosInfos.conta) &&
                  item.funcaoId === tipoDeVinculo.id) ||
                item.conta === rest.fonteDeRecursosInfos.conta
            )
            .map((item) => ({
              label: `${item.conta} - ${item.titulo}`,
              value: item.id,
            }));

          if (filteredFontes.length > 0) {
            setValuesNotaEmpenho((prev) => ({
              ...prev,
              fonteDeRecursoId: filteredFontes[filteredFontes.length - 1].value,
            }));
          }

          setFilteredOptionsFontesDeRecursos(filteredFontes);

          // Setando opcoes de subelemento
          const filteredOptionsSubelementos = gerenciaOpcoesSubelemento(
            rest.elementoDaDespesa.codigo.substring(0, 9),
            allNaturezasDaDespesa
          );
          setOptionsSubelementos(filteredOptionsSubelementos);

          // Setando opcoes de Ordem Cronologica
          const { newOptions, opcaoPadrao } = gerenciaOpcoesOrdemCronologica(
            rest.elementoDaDespesa.codigo,
            optionsOrdemCronologica
          );
          setOptionsOrdemCronologica(newOptions);

          setValuesNotaEmpenho((prev) => ({
            ...prev,
            ordemCronologica: opcaoPadrao,
          }));

          // Atualizando os valores de ficha e subelemento
          setValuesNotaEmpenho((prev) => ({
            ...prev,
            fichaId: ficha.id,
            subelemento: filteredOptionsSubelementos[0]?.value,
          }));

          setInfosFicha({
            ...rest,
          });
        }
      } catch (error) {
        console.error("Erro ao buscar dados do empenho:", error);
      }

      const cpfData = optionsCpf.find(
        (item) => item.id === parseInt(newObject.fornecedor)
      );

      setValuesNotaEmpenho((prevValues) => ({
        ...prevValues,
        data: !isReplica ? newObject.data : prevValues.data,
        dataTemp: !isReplica ? newObject.data : prevValues.data,
        numero: isReplica ? prevValues.numero : newObject?.numero,
        fornecedor: newObject.fornecedor,
        licitacao: newObject.licitacao,
        fichaId: newObject.fichaId,
        ordemCronologica: newObject.ordemCronologica,
        tipoDeEmpenho: newObject.tipoDeEmpenho,
        valor: !isReplica ? formatToCurrency(newObject.valor) : "0,00",
        descricao: newObject.descricao,
      }));
      handleSelectFornecedor(cpfData);
      if (isReplica) {
        handleGoRef(inputValorRef);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchCpfs();
    fetchFontesDeRecursos();
    fetchAllNaturezasDaDespesa();
    fetchObras();
    fetchConvenios();
    fetchLicitacao();

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchAllData();
    if (!numero) {
      handleGoRef(inputFornecedorRef);
    }
    if (isReplica) {
      handleGoRef(inputValorRef);
    }
    // eslint-disable-next-line
  }, [
    numero,
    optionsCpf,
    optionsFichas,
    optionsFontesDeRecursos,
    allNaturezasDaDespesa,
  ]);

  useEffect(() => {
    if (optionsFontesDeRecursos.length > 0) {
      fetchQddFichas();
    }

    //eslint-disable-next-line
  }, [optionsFontesDeRecursos]);

  useEffect(() => {
    const modalCpfCnpj = openedModals.find(
      (item) => item.tituloModal === "CPF/CNPJ"
    );

    if (modalCpfCnpj && modalCpfCnpj.requisicaoDoModalConcluida) {
      fetchCpfs(true);
    }

    //eslint-disable-next-line
  }, [openedModals]);

  const handleSelectFicha = (option) => {
    const { ficha, tipoDeVinculo, ...rest } = option;

    //filtrando as fontes que podem ser vinculadas
    const filteredFontes = optionsFontesDeRecursos
      .filter(
        (item) =>
          (item.conta.includes(rest.fonteDeRecursosInfos.conta) &&
            item.funcaoId === tipoDeVinculo.id) ||
          item.conta === rest.fonteDeRecursosInfos.conta
      )
      .map((item) => ({
        label: `${item.conta} - ${item.titulo}`,
        value: item.id,
      }));

    if (filteredFontes.length > 0) {
      setValuesNotaEmpenho((prev) => ({
        ...prev,
        fonteDeRecursoId: filteredFontes[filteredFontes.length - 1].value,
      }));
    }

    setFilteredOptionsFontesDeRecursos(filteredFontes);

    //setando opcoes de subelemento
    const filteredOptionsSubelementos = gerenciaOpcoesSubelemento(
      rest.elementoDaDespesa.codigo.substring(0, 9),
      allNaturezasDaDespesa
    );
    setOptionsSubelementos(filteredOptionsSubelementos);

    //setando opcoes de Ordem Cronologica

    const { newOptions, opcaoPadrao } = gerenciaOpcoesOrdemCronologica(
      rest.elementoDaDespesa.codigo,
      optionsOrdemCronologica
    );
    setOptionsOrdemCronologica(newOptions);

    setValuesNotaEmpenho((prev) => ({
      ...prev,
      ordemCronologica: opcaoPadrao,
    }));

    // ----------------------------------
    setValuesNotaEmpenho((prev) => ({
      ...prev,
      fichaId: ficha.id,
      subelemento: filteredOptionsSubelementos[0].value,
    }));

    setInfosFicha({
      ...rest,
    });
    handleGoRef(inputExercicioRef);
  };

  useEffect(() => {
    if (inputFicha.length === 0) {
      setInfosFicha({});
      setValuesNotaEmpenho((prev) => ({
        ...prev,
        fichaId: 0,
      }));
    }
  }, [inputFicha]);

  // useEffect(() => {
  //   const licitacoesCabiveis = licitacoes?.licitacao?.map((licitacao) => {
  //     // Filtra e mapeia os contratos relacionados à licitação
  //     const contratosRelacionados = licitacoes?.contratos
  //       .filter(
  //         (contrato) => contrato.numeroLicitacao === licitacao.numeroLicitacao
  //       )
  //       .map((contrato) => {
  //         // Filtra os aditivos relacionados ao contrato
  //         const aditivosRelacionados = licitacoes?.aditivos.filter(
  //           (aditivo) => aditivo.numeroContrato === contrato.numeroContrato
  //         );
  //         // Retorna apenas os contratos com seus aditivos, sem as propriedades indesejadas
  //         return { ...contrato, aditivos: aditivosRelacionados };
  //       });

  //     // Retorna as licitações com contratos e aditivos relacionados, sem as outras propriedades
  //     return { ...licitacao, contratos: contratosRelacionados };
  //   });

  //   const licitacoesComVigencia = licitacoesCabiveis?.map((licitacao) => {
  //     // Filtra os contratos relacionados à licitação atual
  //     const contratosRelacionados = licitacoes?.contratos.filter(
  //       (contrato) => contrato.numeroLicitacao === licitacao.numeroLicitacao
  //     );

  //     // Filtra os aditivos relacionados à licitação atual
  //     const aditivosRelacionados = licitacoes?.aditivos.filter(
  //       (aditivo) =>
  //         aditivo.numeroContrato === contratosRelacionados.numeroContrato
  //     );

  //     // Encontrar a maior dataVigencia nos aditivos relacionados
  //     const maiorDataAditivos = aditivosRelacionados?.reduce(
  //       (maior, aditivo) =>
  //         aditivo.dataVigencia > maior ? aditivo.dataVigencia : maior,
  //       "00000000" // Valor inicial para comparação (formato "YYYYMMDD")
  //     );

  //     // Encontrar a maior dataFinal nos contratos relacionados
  //     const maiorDataContratos = contratosRelacionados?.reduce(
  //       (maior, contrato) =>
  //         contrato.dataFinal > maior ? contrato.dataFinal : maior,
  //       "00000000" // Valor inicial para comparação (formato "YYYYMMDD")
  //     );

  //     // Comparar as duas datas e pegar a maior
  //     const maiorDataGeral =
  //       maiorDataAditivos > maiorDataContratos
  //         ? maiorDataAditivos
  //         : maiorDataContratos;

  //     // Adicionar a chave "vigencia" à licitação com a maior data encontrada
  //     return {
  //       ...licitacao,
  //       vigencia: maiorDataGeral,
  //     };
  //   });

  //   setLicitacoesFiltradas(licitacoesComVigencia);
  // }, [valuesNotaEmpenho.fornecedor, licitacoes]);

  // useEffect(() => {
  //   console.log("LICITAÇÕES FILTRADAS AQUI: ", licitacoesFiltradas);
  //   const licitacoesFiltradasPorDataEFornecedor = licitacoesFiltradas?.filter(
  //     (licitacao) => {
  //       // Filtra as licitações por vigência
  //       const isVigenciaValid =
  //         licitacao.vigencia >
  //         valuesNotaEmpenho.data.split("-").reverse().join("");

  //       // Verifica se existe algum contrato com o fornecedor desejado
  //       const temContratoComFornecedor = licitacao.contratos?.some(
  //         (contrato) => {
  //           const fornecedorEncontrado = optionsCpf.find(
  //             (option) => option.id === parseInt(valuesNotaEmpenho.fornecedor)
  //           );
  //           return (
  //             contrato.fornecedor ===
  //             fornecedorEncontrado?.cpf?.replace(/\D/g, "")
  //           );
  //         }
  //       );

  //       console.log("tem contrato com fornecedor: ", temContratoComFornecedor);

  //       // A licitação será incluída se passar nas duas verificações
  //       return isVigenciaValid && temContratoComFornecedor;
  //     }
  //   );

  //   const licitacoesOptionsLocais = licitacoesFiltradasPorDataEFornecedor?.map(
  //     (item) => ({
  //       label: `${item.numeroLicitacao}`,
  //       value: `${item.codigoUnidadeGestora}${item.numeroLicitacao}${item.dataLicitacao}${item.mesAno}`,
  //       title: item?.descricao,
  //     })
  //   );
  //   console.log(
  //     "Licitações Options aqui: ",
  //     licitacoesFiltradasPorDataEFornecedor
  //   );
  //   setLicitacoesOptions(licitacoesOptionsLocais);
  //
  // }, [valuesNotaEmpenho.fornecedor, valuesNotaEmpenho.data, optionsCpf]);

  useEffect(() => {
    if (valuesNotaEmpenho.fornecedor) {
      const fornecedoresLocais = licitacoes?.participantes?.filter(
        (item) =>
          String(item.licitante) === String(valuesNotaEmpenho.fornecedorData)
      );
      const licitacoesParticipadasLocais = fornecedoresLocais
        ?.map((fornecedor) => {
          return licitacoes?.licitacoes?.filter(
            (licitacao) =>
              licitacao.numeroLicitacao === fornecedor.numeroLicitacao &&
              licitacao.modalidadeLicitacao ===
                fornecedor.modalidadeLicitacao &&
              licitacao.codigoUnidadeGestora === fornecedor.codigoUnidadeGestora
          );
        })
        .flat();

      const valoresEmVigencia =
        valuesNotaEmpenho?.data &&
        /^\d{4}-\d{2}-\d{2}$/.test(valuesNotaEmpenho.data)
          ? licitacoesParticipadasLocais?.filter((licitacao) => {
              const formattedDate =
                valuesNotaEmpenho.data.slice(5, 7) +
                valuesNotaEmpenho.data.slice(0, 4);
              const dataVigencia = licitacao.dataVigencia;

              // Converte para objetos Date usando dia fixo "01"
              const dateVigencia = new Date(
                dataVigencia.slice(2) + "-" + dataVigencia.slice(0, 2) + "-01"
              );
              const dateFormatted = new Date(
                formattedDate.slice(2) + "-" + formattedDate.slice(0, 2) + "-01"
              );

              return dateVigencia > dateFormatted;
            })
          : [];

      const optionsLicitacoesLocais = valoresEmVigencia?.map((item) => ({
        label: item.numeroLicitacao,
        value: `${item.codigoUnidadeGestora}${item.numeroLicitacao}${item.modalidadeLicitacao}`,
        title: item.descricao,
      }));

      const optionsLicitacoesGerais = licitacoes?.licitacoes?.map((item) => ({
        label: item.numeroLicitacao,
        value: `${item.codigoUnidadeGestora}${item.numeroLicitacao}${item.modalidadeLicitacao}`,
        title: item.descricao,
      }));

      if (
        (!numero &&
          licitacoesParticipadasLocais.length === 0 &&
          inputDataRef.current === document.activeElement) ||
        !valuesNotaEmpenho.fornecedor
      ) {
        toast.warn("Nenhuma Licitação existente para esse fornecedor");
        setValuesNotaEmpenho((prevValues) => ({
          ...prevValues,
          licitacao: null,
        }));
        setOptionsLicitacoes([]);
      } else {
        if (
          optionsLicitacoesLocais?.length === 0 &&
          /^\d{4}-\d{2}-\d{2}$/.test(valuesNotaEmpenho.data) &&
          !numero &&
          inputDataRef.current === document.activeElement
        ) {
          toast.warn(
            "Nenhuma Licitação existente para esse fornecedor com essa data"
          );
        }
        if (valuesNotaEmpenho.licitacao) {
          const isInOptions = optionsLicitacoesLocais.some(
            (option) => option.value === valuesNotaEmpenho.licitacao
          );
          if (!isInOptions && !numero) {
            setValuesNotaEmpenho((prevValues) => ({
              ...prevValues,
              licitacao: null,
            }));
            // toast.warn("Selecione uma licitação em vigência");
            // handleGoRef(inputLicitacaoRef);
          }
        }
        if (!numero || isReplica) {
          setOptionsLicitacoes(optionsLicitacoesLocais);
          if (optionsLicitacoesLocais.length === 1) {
            setValuesNotaEmpenho((prevValues) => ({
              ...prevValues,
              licitacao: optionsLicitacoesLocais[0].value,
            }));
            if (!valuesNotaEmpenho.licitacao) {
              handleGoRef(inputDataRef);
            }
          }
        } else {
          setOptionsLicitacoes(optionsLicitacoesGerais);
        }
      }
    }
    // eslint-disable-next-line
  }, [
    valuesNotaEmpenho.fornecedorAtt,
    licitacoes,
    valuesNotaEmpenho.fichaId,
    valuesNotaEmpenho.data,
  ]);

  useEffect(() => {
    if (optionsLicitacoes.length === 1 || optionsLicitacoes.length === 0) {
      handleGoRef(inputAcaoRef);
    } else {
      if (optionsLicitacoes.length > 1) {
        handleGoRef(inputLicitacaoRef);
      }
    }
    //eslint-disable-next-line
  }, [valuesNotaEmpenho.dataTempAtt]);

  const autocompleteFilterFuncion = (options) => {
    const onlyNumbers = inputFicha.replace(/[^0-9]/g, "");

    const fichaCorrespondente = options.find(
      (item) => item.ficha.ficha.toString() === onlyNumbers
    );

    const filtragemGeral = options
      .filter(
        (item) =>
          item.label.toLowerCase().includes(inputFicha.toLowerCase()) ||
          item.label
            .toLowerCase()
            .replace(/\./g, "")
            .includes(inputFicha.toLowerCase())
      )
      .filter((item) => item.ficha.ficha.toString() !== onlyNumbers)
      .sort((a, b) => {
        if (a.ficha.ficha < b.ficha.ficha) {
          return -1;
        } else {
          return 1;
        }
      })
      .sort((a, b) => {
        const cleanedAcao = a.acaoGovernamental.codigo.replace(/[^0-9]/g, "");
        const cleanedAcaoB = b.acaoGovernamental.codigo.replace(/[^0-9]/g, "");

        if (
          cleanedAcao.startsWith(onlyNumbers) &&
          !cleanedAcaoB.startsWith(onlyNumbers)
        ) {
          return -1;
        } else if (
          !cleanedAcao.startsWith(onlyNumbers) &&
          cleanedAcaoB.startsWith(onlyNumbers)
        ) {
          return 1;
        }

        return 0;
      });

    if (fichaCorrespondente) {
      return [fichaCorrespondente, ...filtragemGeral];
    } else {
      return [...filtragemGeral];
    }
  };

  const handleSelectFornecedor = (option) => {
    setValuesNotaEmpenho((prevValues) => ({
      ...prevValues,
      fornecedor: option?.id,
      fornecedorData: option?.cpf.replace(/\D/g, ""),
      fornecedorAtt: !prevValues.fornecedorAtt,
    }));
    if (optionsLicitacoes.length > 1) {
      handleGoRef(inputLicitacaoRef);
    } else {
      handleGoRef(inputDataRef);
    }
  };

  const handleAddNotaEmpenho = async () => {
    if ((numero && !isReplica) || isOpenToast === true) {
      postLiquidacao(await postEmpenho());
    } else {
      updateEmpenho();
    }
  };

  const elementos = [
    `30`,
    `31`,
    `32`,
    `33`,
    `34`,
    `35`,
    `36`,
    `37`,
    `38`,
    `39`,
    `51`,
    `52`,
  ];

  const updateEmpenho = async () => {
    try {
      const valuesToUpdate = {
        numero: valuesNotaEmpenho.numero,
        fornecedor: valuesNotaEmpenho.fornecedor,
        licitacao: valuesNotaEmpenho.licitacao,
        data: valuesNotaEmpenho.data,
        tipoDeEmpenho: valuesNotaEmpenho.tipoDeEmpenho,
        ordemCronologica: valuesNotaEmpenho.ordemCronologica,
        fichaId: valuesNotaEmpenho.fichaId,
        fonteDeRecursoId: valuesNotaEmpenho.fonteDeRecursoId,
        subelemento: valuesNotaEmpenho.subelemento,
        descricao: valuesNotaEmpenho.descricao,
        valor: valuesNotaEmpenho.valor.replace(/\./g, "").replace(",", "."),
        produtos: produtoAquisicao.map((produto) => ({
          produtoAquisicaoId: produto?.produtoAquisicao?.id,
          quantidade: produto?.quantidade,
          valor: produto?.valor.replace(/\./g, ""),
        })),
      };

      if (parseFloat(valuesToUpdate.valor) <= 0) {
        toast.warn("O campo 'valor' deve ser maior que 0");
        return;
      }
      const response = await api.put(`notaEmpenho/${numero}`, valuesToUpdate);
      toast.success(response.data.message || "Empenho atualizada com sucesso!");
    } catch (error) {
      toast.error(
        error.response.data.message ||
          "Houve um Problema ao Atualizar um Empenho!"
      );
      console.log(error);
    }
  };

  const postEmpenho = async () => {
    try {
      const valuesToPost = {
        ...valuesNotaEmpenho,
        valor: valuesNotaEmpenho.valor.replace(/\./g, "").replace(",", "."),
        produtos:
          produtoAquisicao.length > 0
            ? produtoAquisicao.map((produto) => ({
                produtoAquisicaoId: produto?.produtoAquisicao?.id,
                quantidade: produto?.quantidade,
                valor: produto?.valor.replace(/\./g, "").replace(",", "."),
              }))
            : null,
      };
      if (parseFloat(valuesToPost.valor) <= 0) {
        toast.warn("O campo 'valor' deve ser maior que 0");
        return;
      }
      if (valuesToPost?.descricao?.length < 10) {
        toast.warn("Por favor, digite uma descrição maior que 10 caracteres");
        return;
      }
      if (valuesToPost?.maxValueReached) {
        toast.warn(
          "Digite um valor menor para empenhar ou adicione uma Licitação"
        );
        return;
      }
      const valorNumerico = parseFloat(
        liquidacaoData.valor.replace(/\./g, "").replace(",", ".")
      );
      const valorComparacao = parseFloat(
        valuesNotaEmpenho.valor.replace(/\./g, "").replace(",", ".")
      );
      if (
        valorNumerico === valorComparacao &&
        valorNumerico > 0 &&
        valuesNotaEmpenho.tipoDeEmpenho !== "1 - Ordinário"
      ) {
        toast.warn(
          "O valor da liquidação Global ou Estimativo não pode ser igual ao valor do empenho!"
        );
        return;
      }
      console.log("Estamos enviando: ", valuesToPost);
      const response = await api.post(
        `notaEmpenho/${currentClient.clienteId}`,
        valuesToPost
      );
      console.log(response.data);
      toast.success(response.data.message || "Empenho cadastrado com sucesso!");
      return response.data.id;
    } catch (error) {
      toast.error(
        error.response.data.message ||
          "Houve um Problema ao Cadastrar um Empenho!"
      );
      console.log(error);
      return null;
    }
  };

  const postLiquidacao = async (empenhoId) => {
    const valuesToPost = {
      clienteId: currentClient.clienteId,
      empenhoId: empenhoId,
      acaoId: valuesNotaEmpenho.fichaId,
      liquidacao: "1",
      descricao: valuesNotaEmpenho.descricao,
      data: valuesNotaEmpenho.data,
      responsavelId: liquidacaoData.fornecedor,
      valor: liquidacaoData.valor.replace(/\./g, "").replace(",", "."),
      produtos:
        produtoAquisicao.length > 0
          ? produtoAquisicao.map((produto) => ({
              produtoAquisicaoId: produto?.produtoAquisicao?.id,
              quantidade: produto?.quantidade,
              valor: produto?.valor.replace(/\./g, "").replace(",", "."),
            }))
          : null,
    };

    if (
      liquidacaoData.fornecedor &&
      liquidacaoData.valor.replace(/\./g, "").replace(",", ".") > 0 &&
      empenhoId
    ) {
      try {
        const response = await api.post("/liquidacaoDeEmpenho", valuesToPost);
        console.log(response.data);
        toast.success("Liquidação de Empenho Criada com Sucesso!");
      } catch (error) {
        toast.error(
          error.response.data.message || "Erro ao tentar liquidar este empenho!"
        );
      }
    }
    clearValues();
  };

  useEffect(() => {
    const codigo = infosFicha?.elementoDaDespesa?.codigo?.split(".")[3];
    if (codigo && elementos.includes(codigo)) {
      setAbas([
        "Histórico",
        "Produto e Aquisição",
        "Complemento",
        "Saldos",
        "Procedimentos Contábeis",
      ]);
    } else {
      setAbas([
        "Histórico",
        "Complemento",
        "Saldos",
        "Procedimentos Contábeis",
      ]);
    }
    // eslint-disable-next-line
  }, [infosFicha?.elementoDaDespesa?.codigo]);

  // useEffect(() => {
  //   if (licitacoesOptions && licitacoesOptions.length === 0) {
  //     setValuesNotaEmpenho((prevValues) => ({
  //       ...prevValues,
  //       licitacao: null,
  //     }));
  //     if (inputFornecedor.length > 0 && !toastShown.current) {
  //       toast.warn("Nenhuma Licitação existente para esse fornecedor");
  //       handleGoRef(inputFornecedorRef);
  //       toastShown.current = true;

  //       setTimeout(() => {
  //         toastShown.current = false;
  //       }, 3000);
  //     }
  //   }
  //
  // }, [licitacoesOptions]);

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <ModalAutoComplete
        tituloModal={"CPF/CNPJ"}
        Component={<ComponentCadastroCpfCnpj isAModalPage={true} />}
      />

      <ToastContainer />
      <CustomToast
        isOpenToast={isOpenToast}
        setIsOpenToast={setIsOpenToast}
        saveRegister={handleAddNotaEmpenho}
      />
      <HeaderCadastros
        NomePaginaTitulo={"Nota de Empenho"}
        PaginaSubtitulo={"Cadastro"}
        ButtonSaveFunction={() => setIsOpenToast(true)}
        PaginaConsulta={"/contabilidade/despesa/consulta/nota-de-empenho"}
        BotaoNovoFunction={() => handleGoRef(inputFornecedorRef)}
      />
      {loading ? (
        <Col
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
        </Col>
      ) : (
        <>
          <RowForm className="mt-1">
            <TestButton onClick={() => console.log(valuesNotaEmpenho)}>
              values
            </TestButton>
            <TestButton onClick={() => console.log(optionsLicitacoes)}>
              Testando aqui
            </TestButton>
            <CColNumeroAndEVB md={1}>
              <FloatingLabelInput
                disabled
                label="Número"
                placeholder="Número"
                maxLength={7}
                value={valuesNotaEmpenho.numero}
                onChange={(e) =>
                  setValuesNotaEmpenho((prevValues) => ({
                    ...prevValues,
                    numero: e.target.value,
                  }))
                }
              />
            </CColNumeroAndEVB>
            <CCol>
              <MyAutoComplete
                ref={inputFornecedorRef}
                iconsOff={numero && !isReplica}
                labelInput="Fornecedor"
                options={optionsCpf}
                inputValue={inputFornecedor}
                setInputValue={setInputFornecedor}
                addNewDataFunction={handleAddNewCpf} // aquiiiii
                labelFormat={(item) => `${item.nome} - ${item.cpf}`}
                style={{ height: "37px" }}
                onOptionSelect={(option) => handleSelectFornecedor(option)}
                iconClearFunction={clearValues}
              />
            </CCol>
            <CCol md={2}>
              <FloatingLabelInput
                ref={inputLicitacaoRef}
                disabled={numero && !isReplica}
                tipo="input_select"
                new
                label="Licitação"
                placeholder="Licitação"
                options={
                  optionsLicitacoes
                    ? [
                        { label: "Licitação", value: null },
                        { label: "Sem Licitação", value: undefined },
                        ...optionsLicitacoes,
                      ]
                    : [{ label: "Licitação", value: null }]
                }
                mask={
                  valuesNotaEmpenho.licitacao &&
                  `${valuesNotaEmpenho?.licitacao?.slice(
                    6,
                    11
                  )}/${valuesNotaEmpenho?.licitacao?.slice(11, 15)}`
                }
                value={valuesNotaEmpenho.licitacao}
                onSelect={(option) => {
                  if (optionsLicitacoes.length > 0) {
                    setValuesNotaEmpenho((prevValues) => ({
                      ...prevValues,
                      licitacao: option.value,
                    }));
                  }
                  handleGoRef(inputDataRef);
                }}
              />
            </CCol>
            <CCol md={2}>
              <InputData
                ref={inputDataRef}
                disabled={numero && !isReplica}
                value={valuesNotaEmpenho.dataTemp}
                onChange={(e) =>
                  setValuesNotaEmpenho((prev) => ({
                    ...prev,
                    dataTemp: e.target.value,
                  }))
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setValuesNotaEmpenho((prev) => ({
                      ...prev,
                      data: valuesNotaEmpenho.dataTemp,
                      dataTempAtt: !prev.dataTempAtt,
                    }));
                    // if (
                    //   valuesNotaEmpenho.licitacao ||
                    //   optionsLicitacoes.length === 0
                    // ) {
                    //   handleGoRef(inputAcaoRef);
                    // } else {
                    //   handleGoRef(inputLicitacaoRef);
                    // }
                  }
                }}
              />
            </CCol>
          </RowForm>

          <RowForm style={{ marginTop: "-14px" }}>
            <CCol>
              <AutoCompleteFichas
                ref={inputAcaoRef}
                onOptionSelect={handleSelectFicha}
                filterFunction={autocompleteFilterFuncion}
                labelInput="Ação"
                options={optionsFichas}
                inputValue={inputFicha}
                setInputValue={setInputFicha}
                rightLabelContent={(item) => item.elementoDaDespesa.codigo}
                labelFormat={(item) =>
                  `${item.acaoGovernamental.codigo} - ${
                    item.acaoGovernamental.titulo
                  } - ${item.fonteDeRecursos.split(" ")[0]}`
                }
                style={{ height: "37px" }}
              />
            </CCol>
          </RowForm>
          {Object.keys(infosFicha).length > 0 && (
            <RowForm
              style={{ marginTop: "-14px", paddingRight: 17, paddingLeft: 17 }}
            >
              <RowInfosAcao>
                <CCol md={7}>
                  <p>
                    Unidade Orçamentária:{" "}
                    <span>{infosFicha.unidadeOrcamentaria}</span>
                  </p>
                  <p>
                    Programa: <span>{infosFicha.programa}</span>
                  </p>
                  <p>
                    Elemento de Despesa:{" "}
                    <span>
                      {infosFicha.elementoDaDespesa.codigo.substring(0, 9)} -{" "}
                      {infosFicha.elementoDaDespesa.titulo}
                    </span>
                  </p>
                  <p>
                    Fonte de Recursos: <span>{infosFicha.fonteDeRecursos}</span>
                  </p>
                </CCol>
                <CCol>
                  <p>
                    Função: <span>{infosFicha.funcao}</span>
                  </p>
                  <p>
                    Subfunção: <span>{infosFicha.subfuncao}</span>
                  </p>
                  <p>
                    Exercício - CNP: <span>{infosFicha.exercicio}</span>
                  </p>
                </CCol>
              </RowInfosAcao>
            </RowForm>
          )}

          <RowForm style={{ marginTop: "-14px" }}>
            <CCol md={8}>
              <FloatingLabelInput
                tipo="input_select"
                ref={inputExercicioRef}
                new
                label="Exercício / Fonte de Recursos - FR / Complementação Orçamentária -CO"
                value={valuesNotaEmpenho.fonteDeRecursoId}
                onSelect={(option) => {
                  setValuesNotaEmpenho((prev) => ({
                    ...prev,
                    fonteDeRecursoId: option?.value,
                  }));
                  handleGoRef(inputSubelementoRef);
                }}
                placeholder="Exercício / Fonte de Recursos - FR / Complementação Orçamentária -CO"
                options={[
                  {
                    label:
                      "Exercício / Fonte de Recursos - FR / Complementação Orçamentária -CO",
                    value: null,
                  },
                  ...filteredOptionsFontesDeRecursos,
                ]}
              />
            </CCol>
            <CCol md={4}>
              <FloatingLabelInput
                tipo="input_select"
                new
                label="Subelemento de Despesa"
                ref={inputSubelementoRef}
                value={valuesNotaEmpenho.subelemento}
                placeholder="Subelemento de Despesa"
                onSelect={(option) => {
                  setValuesNotaEmpenho((prevValues) => ({
                    ...prevValues,
                    subelemento: option.value,
                  }));
                  handleGoRef(inputOrdemCronologicaRef);
                }}
                options={[
                  { label: "Subelemento de Despesa", value: null },
                  ...optionsSubelementos,
                ]}
              />
            </CCol>
          </RowForm>

          {Object.keys(infosFicha).length > 0 && (
            <RowForm style={{ marginTop: "-14px" }}>
              <CCol>
                <FloatingLabelInput
                  as="textarea"
                  tipo="text_area"
                  readOnly
                  disabled
                  style={{ height: "38px", minHeight: "38px" }}
                  label="Função"
                  value={infosFicha.elementoDaDespesa.funcao}
                />
              </CCol>
            </RowForm>
          )}

          <RowForm style={{ marginTop: "-14px" }}>
            <CCol md={4}>
              <FloatingLabelInput
                tipo="input_select"
                new
                label="Natureza da Contratação"
                ref={inputOrdemCronologicaRef}
                value={valuesNotaEmpenho.ordemCronologica}
                onSelect={(option) => {
                  setValuesNotaEmpenho((prev) => ({
                    ...prev,
                    ordemCronologica: parseInt(option.value),
                  }));
                  handleGoRef(inputTipoEmpenhoRef);
                }}
                placeholder="Natureza da Contratação"
                options={[
                  { label: "Natureza da Contratação", value: null },
                  ...optionsOrdemCronologica,
                ]}
              />
            </CCol>
            <CCol md={4}>
              <FloatingLabelInput
                tipo="input_select"
                new
                label="Tipo de Empenho"
                ref={inputTipoEmpenhoRef}
                value={valuesNotaEmpenho.tipoDeEmpenho}
                onSelect={(option) => {
                  setValuesNotaEmpenho((prevValues) => ({
                    ...prevValues,
                    tipoDeEmpenho: option.value,
                  }));
                  handleGoRef(inputValorRef);
                }}
                placeholder="Tipo de Empenho"
                options={[
                  { label: "Tipo de Empenho", value: null },
                  ...[
                    { label: "1 - Ordinário", value: "1 - Ordinário" },
                    { label: "2 - Estimativo", value: "2 - Estimativo" },
                    { label: "3 - Global", value: "3 - Global" },
                  ],
                ]}
              />
            </CCol>
            <CCol>
              <InputFormatRealBrasileiro
                iconReal
                ref={inputValorRef}
                disabled={produtoAquisicao.length > 0}
                value={valuesNotaEmpenho.valor}
                onChange={(_, valor) =>
                  setValuesNotaEmpenho((prevValues) => ({
                    ...prevValues,
                    valor: valor,
                  }))
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleGoRef(inputDescricaoRef);
                  }
                }}
                putDot
              />
            </CCol>
          </RowForm>

          <RowSelecaoAbasInferior
            ref={rowSelecaoRef}
            abas={abas}
            abaSelecionada={abaSelecionada}
            setAbaSelecionada={setAbaSelecionada}
            alwaysSelectFirst
          />

          {abaSelecionada.nomeAba === "Histórico" && (
            <HistoricoAba
              values={valuesNotaEmpenho}
              setValues={setValuesNotaEmpenho}
              inputDescricaoRef={inputDescricaoRef}
              onKeyDown={() => setIsOpenToast(true)}
            />
          )}
          {abaSelecionada.nomeAba === "Produto e Aquisição" && (
            <ProdutoEAquisicaoAba
              produtoAquisicao={produtoAquisicao}
              setProdutoAquisicao={setProdutoAquisicao}
              valorTotal={valuesNotaEmpenho.valor
                .replace(/\./g, "")
                .replace(",", ".")}
            />
          )}
          {abaSelecionada.nomeAba === "Complemento" && (
            <ComplementoAba
              optionsObras={optionsObras}
              inputValueObras={inputValueObras}
              setInputValueObras={setInputValueObras}
              optionsConvenios={optionsConvenios}
              inputValueConvenios={inputValueConvenios}
              setInputValueConvenios={setInputValueConvenios}
            />
          )}
          {abaSelecionada.nomeAba === "Saldos" && <SaldosAba saldos={saldos} />}
          {abaSelecionada.nomeAba === "Procedimentos Contábeis" && (
            <ProcedimentosContabeisAba />
          )}
        </>
      )}

      <CollapseRow
        onClick={() => setCollapseLiquidacao((prev) => !prev)}
        style={{ marginTop: "15px" }}
        collapse={collapseLiquidacao}
        title={"Liquidação"}
      />

      {collapseLiquidacao && (
        <LiquidacaoAba
          elementoDespesaCodigo={
            infosFicha?.elementoDaDespesa?.codigo?.substring(0, 9)?.slice(-2) ||
            "0"
          }
          valor={valuesNotaEmpenho.valor}
          data={valuesNotaEmpenho.data}
          optionsCpf={optionsCpf}
          handleAddNewCpf={handleAddNewCpf}
          tipoEmpenho={valuesNotaEmpenho.tipoDeEmpenho}
          liquidacaoData={liquidacaoData}
          setLiquidacaoData={setLiquidacaoData}
        />
      )}
      {collapseLiquidacao && (
        <>
          <CollapseRow
            onClick={() => setCollapseRetencao((prev) => !prev)}
            style={{ marginTop: "-15px" }}
            collapse={collapseRetencao}
            title={"Retenção"}
          />
          {collapseRetencao && <RetencaoAba />}
        </>
      )}

      <div className="p-3" style={{ minHeight: "80px" }}></div>
    </Container>
  );
};

export default NotaDeEmpenho;
