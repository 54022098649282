import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Col, Container, Row, Table } from "react-bootstrap";

import { toast, ToastContainer } from "react-toastify";

import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";

import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import InputSvg from "../../../../components/Grid/InputSvg";
import { IconEdit } from "../../../../components/Icons/IconEdit";
import { IconDelete } from "../../../../components/Icons/IconDelete";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { IconPrint } from "../../../../components/Icons/IconPrint";
import { IconReplica } from "../../../../components/Icons/IconReplica";
import { IconCancel } from "../../../../components/Icons/IconCancel";
import { IconReplace } from "../../../../components/Icons/IconReplace";

import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";

import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import { formatLabelContaBancaria } from "../../../../utils/FormatacaoDeDados/FormatLabelContaBancaria";

import { useFetchData } from "../../../../hooks/useFetchData";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";

function ConsultaGreReceita() {
  const { currentYear } = useCurrentYear();
  const { currentClient } = useContext(CurrentClientContext);

  const navigate = useNavigate();

  const { data: dataGreReceita, loading } = useFetchData(
    `gre/cliente/${currentClient.clienteId}/${currentYear}`
  );

  const [dataGreReceitaFormatted, setDataGreReceitaFormatted] = useState([
    {
      id: "",
      numero: "",
      qdrde: "",
      contribuinte: "",
      conta: "",
      nameConta: "",
      data: "",
      valor: "",
    },
  ]);

  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [dataGreReceitaFiltered, setDataGreReceitaFiltered] = useState([
    {
      id: "",
      numero: "",
      qdrde: "",
      contribuinte: "",
      conta: "",
      nameConta: "",
      data: "",
      valor: "",
    },
  ]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filteredItems = dataGreReceitaFormatted.filter((item) => {
      return (
        item.numero.toString().includes(value) ||
        item.qdrde.toLowerCase().includes(value.toLowerCase()) ||
        item.contribuinte.toLowerCase().includes(value.toLowerCase()) ||
        item.conta.includes(value) ||
        item.nameConta.toLowerCase().includes(value.toLowerCase()) ||
        item.valor.includes(value)
      );
    });
    setDataGreReceitaFiltered(filteredItems);
    setCurrentPage(0);
  };

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = dataGreReceitaFormatted.slice(startIndex, endIndex);
  const currentFilteredItens = dataGreReceitaFiltered.slice(
    startIndex,
    endIndex
  );

  const pages = Math.ceil(dataGreReceitaFormatted.length / itensPorPagina);
  const filteredPages = Math.ceil(
    dataGreReceitaFiltered.length / itensPorPagina
  );

  const handleGeneratePdf = (receitaId) => {
    console.log(receitaId);
    toast.info("Relatório não implementado");
  };

  const handleNavigatePageAnulacao = (greId, saldoGre) => {
    if (!saldoGre) {
      toast.info("Esta Guia não possui saldo");
      return;
    }
    navigate(`/contabilidade/extra/anulacao-extra/cadastro/${greId}?guia=gre`);
  };

  useEffect(() => {
    if (dataGreReceita) {
      const formattedDataGreReceita = dataGreReceita
        .map((item) => {
          const infos = item.gre.qdredeInfo.map((info) => {
            // const numero  = item.gre.numero.replace(/^0+/, '');
            const date = item.gre.data.split("T")[0];
            const [year, month, day] = date.split("-");
            const dateFormatted = `${day}/${month}/${year}`;
            return {
              id: item.gre.id,
              numero: item.gre.numero,
              qdrde: info.titulo,
              contribuinte: item.gre.contribuinteInfo.nomeCompleto,
              conta: formatLabelContaBancaria(
                item.gre.caixaInfo.numeroConta,
                item.gre.caixaInfo.digito
              ),
              nameConta: item.gre.caixaInfo.nome,
              data: dateFormatted,
              valor: FormatValueToLocaleString(item.gre.valor),
            };
          });
          return infos;
        })
        .flat();
      setDataGreReceitaFormatted(formattedDataGreReceita);
    }
  }, [dataGreReceita]);

  const renderInfosTable = (infosTable) => {
    return (
      <tr key={infosTable.id}>
        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
        >
          {infosTable.numero}
        </td>
        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
          title={infosTable.qdrde}
        >
          {infosTable.qdrde.length > 70
            ? `${infosTable.qdrde.substring(0, 70)}...`
            : `${infosTable.qdrde}`}
        </td>
        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
          title={infosTable.contribuinte}
        >
          {infosTable.contribuinte.length > 70
            ? `${infosTable.contribuinte.substring(0, 70)}...`
            : `${infosTable.contribuinte}`}
        </td>

        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
          title={infosTable.nameConta}
        >
          {infosTable.conta}
        </td>

        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
        >
          {infosTable.data}
        </td>

        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
        >
          {`R$ ${infosTable.valor}`}
        </td>

        <td>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <IconPrint
              onClick={() => {
                handleGeneratePdf(infosTable.id);
              }}
            />

            <IconEdit
              onClick={() =>
                navigate(
                  `/contabilidade/extra/gre-receita/visualizar/${infosTable.id}`
                )
              }
            />

            <IconReplica
              onClick={() =>
                navigate(
                  `/contabilidade/extra/gre-receita/replicar/${infosTable.id}`
                )
              }
            />

            <IconCancel
              onClick={() =>
                handleNavigatePageAnulacao(infosTable.id, infosTable.valor)
              }
            />
            <IconReplace />
            <IconDelete
              style={{ marginLeft: "-2px" }}
              handleDelete={() => console.log(infosTable.id)}
            />
          </div>
        </td>
      </tr>
    );
  };
  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >

      <HeaderCadastros
        NomePaginaTitulo={"GRE - Receita"}
        PaginaSubtitulo={"Consulta"}
        BotaoSaveAtivo={false}
        RowTitle="Receita Extraorçamentária - GRE"
        BotaoNovoFunction={() =>
          navigate("/contabilidade/extra/gre-receita/cadastro")
        }
      />
      <ToastContainer />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loading ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        {currentItens.length > 0 && (
          <Table responsive style={{ marginTop: "-10px" }}>
            <thead>
              <tr>
                <TituloTable>Número</TituloTable>
                <TituloTable>Qdrde</TituloTable>
                <TituloTable>Contribuinte</TituloTable>
                <TituloTable>Conta</TituloTable>
                <TituloTable>Data</TituloTable>
                <TituloTable>Valor</TituloTable>
                <TituloTableAction>Ação</TituloTableAction>
              </tr>
            </thead>

            <BodyTable>
              {searchTerm
                ? currentFilteredItens.map((item) => {
                    if (!item.numero) return null;
                    return renderInfosTable(item);
                  })
                : currentItens.map((item) => {
                    if (!item.numero) return null;
                    return renderInfosTable(item);
                  })}
            </BodyTable>
          </Table>
        )}
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm
              ? dataGreReceitaFiltered.length
              : dataGreReceitaFormatted
              ? dataGreReceitaFormatted.length
              : 0}{" "}
            registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>
            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default ConsultaGreReceita;
