import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import "../../styles/Css/PagesAcessoInternoStyles/cadastros_cpf_cnpj.css";
import axios from "axios";
import HeaderCadastros from "../../components/HeaderCadastros/HeaderCadastros";
import {
  CCol,
  CColCpfCnpj,
  ContainerToModal,
  RowToModal,
} from "../../components/Grid/CCol";
import FloatingLabelInput from "../../components/Grid/FloatingLabelInput";
// import FormCpf from "./FormCpf";
// import FormCnpj from "./FormCnpj";
// import Endereco from "./Endereco";
// import EnderecoCpf from "./EnderecoCpf";
import { cpf } from "cpf-cnpj-validator";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";
import { Icon } from "@iconify/react";
import {
  ModalStyled,
  ButtonsDiv,
  DivIconModal,
} from "../../styles/StyledComponents/ModalStyled";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { FormatCpfPaste } from "../../utils/FormatacaoDeDados/FormtCpfPaste";
import { FormatCnpjPaste } from "../../utils/FormatacaoDeDados/FormatCnpjPaste";
import { ReturnDataAtual } from "../../utils/Gerais/ReturnDataAtual";
import { CurrentClientContext } from "../../contexts/CurrentClientContext";
import api from "../../utils/api";
import ButtonSaveModalAutoComplete from "../../components/Modais/ButtonSaveModalAutoComplete";
import EnderecoCnpj from "./EnderecoCnpj";
import EnderecoCpf from "./EnderecoCpf";
import FormCnpj from "./FormCnpj";
import FormCpf from "./FormCpf";
import { DefinirRequisicaoDoModalComoConluida } from "../../utils/Gerais/DefinirRequisicaoDoModalComoConcluida";
import { useOpenedModals } from "../../hooks/useOpenedModals";

export const LabelForm = styled.label`
  background-color: #e9ecef;
  padding: 7px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ced4da;
  cursor: pointer;

  @media (min-width: 768px) {
    & {
      width: 104.3%;
    }
  }
`;

export const FormControlDate = styled(FloatingLabelInput)`
  ::-webkit-calendar-picker-indicator {
    background: url("https://api.iconify.design/ic/outline-calendar-today.svg?color=%23515c70")
      no-repeat;
    background-position: center;
    background-size: 20px;
    color: blue;
    cursor: pointer;
    float: right;
  }
`;

const ComponentCadastroCpfCnpj = ({ isAModalPage, pagConsulta }) => {
  Modal.setAppElement("#root");

  const { openedModals, setOpenedModals } = useOpenedModals();
  const { currentClient } = useContext(CurrentClientContext);
  const { valor: paramsValor } = useParams();
  const dataAtual = ReturnDataAtual();

  const [inputCpfCnpj, setInputCpfCnpj] = useState("");

  const [cpfCnpjInclusoNoClienteAtual, setCpfCnpjInclusoNoClienteAtual] =
    useState(true);
  const [cepJaCadastrado, setCepJaCadastrado] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [cpfCnpjExiste, setCpfCnpjExiste] = useState(false);
  const [codigoCadastro, setCodigoCadastro] = useState(null);
  const [condicaoValidaCpfCnpj, setCondicaoValidaCpfCnpj] = useState(false);
  const [values, setValues] = useState({
    clienteId: currentClient.clienteId,
    cnpj: "",
    dataCriacao: dataAtual,
    razaoSocial: "",
    nomeFantasia: "",
    naturezaJuridica: "",
    cnae: "",
    Endereco: {
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      municipio: "",
      estado: "",
    },
    Contato: {
      telefone: "",
      celular: "",
      email: "",
    },
  });
  const [cpfValues, setCpfValues] = useState({
    clienteId: currentClient.clienteId,
    cpf: "",
    dataCriacao: "",
    nomeCompleto: "",
    Endereco: {
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      municipio: "",
      estado: "",
    },
    Contato: {
      telefone: "",
      celular: "",
      email: "",
    },
  });

  const [cpfValid, setCpfValid] = useState(0);
  const [cpfCnpj, setCpfCnpj] = useState({
    cpfCnpj: "",
  });
  const [teclaPressionada, setTeclaPressionada] = useState(0);

  const validaCpf = useCallback(() => {
    // Elimina CPFs inválidos conhecidos
    if (
      cpfValues.cpf.length !== 11 ||
      cpfValues.cpf === "00000000000" ||
      cpfValues.cpf === "11111111111" ||
      cpfValues.cpf === "22222222222" ||
      cpfValues.cpf === "33333333333" ||
      cpfValues.cpf === "44444444444" ||
      cpfValues.cpf === "55555555555" ||
      cpfValues.cpf === "66666666666" ||
      cpfValues.cpf === "77777777777" ||
      cpfValues.cpf === "88888888888" ||
      cpfValues.cpf === "99999999999"
    ) {
      setCpfValid(false);
      return;
    }

    if (!cpf.isValid(cpfValues.cpf)) {
      setCpfValid(false);
      return;
    }

    setCpfValid(true);
  }, [cpfValues.cpf]);

  useEffect(() => {
    if (!paramsValor && dataAtual) {
      setValues((prev) => ({
        ...prev,
        dataCriacao: dataAtual,
      }));

      setCpfValues((prev) => ({
        ...prev,
        dataCriacao: dataAtual,
      }));
    }
  }, [paramsValor, dataAtual]);

  const validaCnpj = useCallback(async () => {
    console.log("fora do useCallBack");
    if (condicaoValidaCpfCnpj) {
      console.log("Dentro do useCallBack");
      try {
        const res = await axios.get(
          `https://brasilapi.com.br/api/cnpj/v1/${cpfCnpj.cpfCnpj}`
        );
        console.log(res.data);
        setValues((prevValues) => ({
          ...prevValues,
          cnpj: res.data.cnpj,
          razaoSocial: res.data.razao_social,
          nomeFantasia: res.data.nome_fantasia,
          naturezaJuridica: `${res.data.codigo_natureza_juridica} - ${res.data.natureza_juridica}`,
          cnae: `${res.data.cnae_fiscal} - ${res.data.cnae_fiscal_descricao}`,
          Endereco: {
            ...prevValues.Endereco,
            municipio: res.data.municipio,
            cep: res.data.cep,
            logradouro: res.data.logradouro,
            bairro: res.data.bairro,
            numero: res.data.numero,
            estado: res.data.uf,
          },
        }));
      } catch (err) {}
    }
  }, [cpfCnpj.cpfCnpj, condicaoValidaCpfCnpj]);

  const verificaOndeEsseRegistroExiste = () => {
    api.get(`cpf/por-cliente/${currentClient.clienteId}`).then((response) => {
      const cpfInclusoNoClienteAtual = response.data.some(
        (pessoa) => pessoa.pessoaId === codigoCadastro
      );
      console.log("cpf incluso no cliente atual?");
      console.log(cpfInclusoNoClienteAtual);
      setCpfCnpjInclusoNoClienteAtual(cpfInclusoNoClienteAtual);
    });
  };

  useEffect(() => {
    console.log("useEffect do cep");
    if (values.Endereco.cep !== "" && values.Endereco.cep.length === 8) {
      setCepJaCadastrado(true);
    } else {
      setCepJaCadastrado(false);
    }
  }, [values.Endereco.cep]);

  useEffect(() => {
    if (cpfCnpjExiste) {
      verificaOndeEsseRegistroExiste();
    }

    //eslint-disable-next-line
  }, [cpfCnpjExiste]);

  //test

  axios.defaults.headers.common["Authorization"] = null;

  const limparValoresCpf = useCallback(() => {
    setCpfValues((prevValues) => ({
      ...prevValues,
      nomeCompleto: "",
      Endereco: {
        ...prevValues.Endereco,
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        municipio: "",
        estado: "",
      },
      Contato: {
        ...prevValues.Contato,
        telefone: "",
        celular: "",
        email: "",
      },
    }));
  }, [setCpfValues]);

  const limparValoresCnpj = useCallback(() => {
    setValues((prevValues) => ({
      ...prevValues,
      cnpj: "",
      razaoSocial: "",
      nomeFantasia: "",
      naturezaJuridica: "",
      cnae: "",
      Endereco: {
        ...prevValues.Endereco,
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        municipio: "",
        estado: "",
      },
      Contato: {
        ...prevValues.Contato,
        telefone: "",
        celular: "",
        email: "",
      },
    }));
  }, [setValues]);

  const handleUpdateCadastro = async () => {
    if (cpfCnpjInclusoNoClienteAtual) {
      console.log(cpfCnpj.cpfCnpj.length);

      if (cpfCnpj.cpfCnpj.length === 11) {
        const updateValues = {
          nomeCompleto: cpfValues.nomeCompleto,
          ...cpfValues.Endereco,
          ...cpfValues.Contato,
        };
        console.log("Dados do updateValues CPF");
        console.log(updateValues);

        await api
          .put(`cpf/gerenciador/${codigoCadastro}`, updateValues)
          .then((response) => {
            toast.success(response.data.message);
            if (isAModalPage) {
              DefinirRequisicaoDoModalComoConluida(
                openedModals,
                setOpenedModals,
                "CPF/CNPJ"
              );
            }
          })
          .catch((error) => {
            if (error.response.data.status === 400) {
              toast.error(
                "Campo inválido para atualização ou nenhum campo fornecido."
              );
            } else {
              toast.error("Erro ao atualizar dados de CPF.");
            }
          });
      }

      if (cpfCnpj.cpfCnpj.length === 14) {
        const updateValues = {
          nomeFantasia: values.nomeFantasia,
          ...values.Endereco,
          ...values.Contato,
        };
        console.log("Dados do updateValues CNPJ");
        console.log(updateValues);
        await api
          .put(`cnpj/${codigoCadastro}`, updateValues)
          .then((response) => {
            if (isAModalPage) {
              toast.success(response.data.message);
            }
            DefinirRequisicaoDoModalComoConluida(
              openedModals,
              setOpenedModals,
              "CPF/CNPJ"
            );
          })
          .catch((error) => {
            if (error.response.data.status === 400) {
              toast.error(
                "Campo inválido para atualização ou nenhum campo fornecido."
              );
            } else {
              toast.error("Erro ao atualizar dados de CNPJ.");
            }
          });
      }
    } else {
      handleCadastro();
    }
  };

  const handleCadastro = async () => {
    console.log(cpfValues.cpf.length);
    try {
      if (
        cpfValues.cpf.length === 11 &&
        (!cpfCnpjExiste || !cpfCnpjInclusoNoClienteAtual)
      ) {
        console.log(cpfValues, "CPF");
        const response = await api.post(`cpf`, cpfValues);
        const codigo = response.data.pessoaId;
        console.log(response.data);
        setInputCpfCnpj("");
        limparValoresCpf();
        DefinirRequisicaoDoModalComoConluida(
          openedModals,
          setOpenedModals,
          "CPF/CNPJ"
        );
        toast.success(
          `Cadastro realizado com sucesso! Código de registro: ${codigo}`
        );
      }

      if (values.cnpj.length === 14 && !cpfCnpjExiste) {
        console.log(values, "CNPJ");
        const response = await api.post("cnpj", values);
        const codigo = response.data.pessoaId;
        console.log(response.data);

        DefinirRequisicaoDoModalComoConluida(
          openedModals,
          setOpenedModals,
          "CPF/CNPJ"
        );
        toast.success(
          `Cadastro realizado com sucesso! Código de registro: ${codigo}`
        );
        setInputCpfCnpj("");
        limparValoresCnpj();
      }
    } catch (error) {
      console.error(error);

      if (error.response.data.message === "CPF já cadastrado") {
        const mensagemErro = "CPF já cadastrado";

        toast.error(`Erro no cadastro: ${mensagemErro}`);
      }

      if (error.response && error.response.status === 500) {
        const mensagemErro =
          "Problema ao cadastrar os dados. Por favor tente mais tarde";

        toast.error(mensagemErro);
      }

      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      }
    }
  };

  const formatCpfCnpj = (condition, valor) => {
    console.log(valor);
    const inputFormatado = valor.replace(/[^0-9]/g, "");
    const cleanedValue = inputFormatado.slice(0, 14); // Limit input to 14 digits

    let formattedValue = "";

    if (condition) {
      formattedValue = cleanedValue.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        (match, p1, p2, p3, p4) => {
          formattedValue = p1 + "." + p2 + "." + p3 + "-" + p4;
          valor = formattedValue;
        }
      );
    }

    if (cleanedValue.length <= 11) {
      formattedValue = cleanedValue.replace(
        /(\d{3})(\d{1,3})?(\d{1,3})?(\d{1,2})?/,
        (match, p1, p2, p3, p4) => {
          let formatted = p1;
          if (p2) {
            formatted += `.${p2}`;
          }
          if (p3) {
            formatted += `.${p3}`;
          }
          if (p4) {
            formatted += `-${p4}`;
          }
          return formatted;
        }
      );
    } else {
      formattedValue = cleanedValue.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})?/,
        (match, p1, p2, p3, p4, p5) => {
          let formatted = p1;
          if (p2) {
            formatted += `.${p2}`;
          }
          if (p3) {
            formatted += `.${p3}`;
          }
          if (p4) {
            formatted += `/${p4}-`;
          }
          if (p5) {
            formatted += `${p5}`;
          }
          return formatted;
        }
      );
    }

    console.log(formattedValue);
    setInputCpfCnpj(formattedValue);
  };

  useEffect(() => {
    if (cpfCnpj.cpfCnpj.length !== 11) {
      limparValoresCpf();
      console.log("entrou no de cpf");
      setCpfValid(false);
    }
  }, [cpfCnpj.cpfCnpj, limparValoresCpf]);

  useEffect(() => {
    if (cpfCnpj.cpfCnpj.length !== 14) {
      limparValoresCnpj();
      console.log("entrou no de cnpj");
    }
  }, [cpfCnpj.cpfCnpj, limparValoresCnpj]);

  useEffect(() => {
    if (cpfCnpj.cpfCnpj.length === 14) {
      const cleanedCnpj = cpfCnpj.cpfCnpj.replace(/[^0-9]/g, "");
      setValues((prevValues) => ({
        ...prevValues,
        cnpj: cleanedCnpj,
      }));
      validaCnpj();
    }
  }, [cpfCnpj.cpfCnpj, validaCnpj, setValues]);

  useEffect(() => {
    if (cpfValues.cpf.length >= 10 && cpfValues.cpf.length <= 12) {
      validaCpf();
    }
  }, [cpfValues.cpf, validaCpf]);

  //Funcao para saber se o CPF ou CNPJ que o usuario esta tentando cadastrar ja existe
  const fetchCpfCnpj = async (valor) => {
    if (valor.length === 11) {
      try {
        const response = await api.get(
          `/cpf/busca/${currentClient.clienteId}/${valor}`
        );
        const dataCpf = response.data;
        setCpfCnpjExiste(true);
        if (paramsValor === undefined) {
          openModal();
        }
        console.log(dataCpf);

        setCodigoCadastro(dataCpf.pessoaId);
        setCpfValues((prevValues) => ({
          ...prevValues,
          nomeCompleto: dataCpf.nomeCompleto,
          dataCriacao: dataCpf.dataCriacao.substring(0, 10),
          Endereco: {
            ...prevValues.Endereco,
            cep: dataCpf.Endereco.cep,
            logradouro: dataCpf.Endereco.logradouro,
            numero: dataCpf.Endereco.numero,
            complemento: dataCpf.Endereco.complemento,
            bairro: dataCpf.Endereco.bairro,
            municipio: dataCpf.Endereco.municipio,
            estado: dataCpf.Endereco.estado,
          },
          Contato: {
            ...prevValues.Contato,
            telefone: dataCpf.Contato.telefone,
            celular: dataCpf.Contato.celular,
            email: dataCpf.Contato.email,
          },
        }));
      } catch (error) {
        console.log(error);
        setCpfCnpjExiste(false);
      }
    } else {
      try {
        const response = await api.get(`cnpj/filter?filtro=${valor}`);
        const dataCnpj = response.data[0];
        if (response.data.length >= 1) {
          setCpfCnpjExiste(true);
          if (paramsValor === undefined) {
            openModal();
          }
          setCodigoCadastro(dataCnpj.pessoaId);
          setValues((prevValues) => ({
            ...prevValues,
            nomeFantasia: dataCnpj.nomeFantasia,
            razaoSocial: dataCnpj.razaoSocial,
            naturezaJuridica: dataCnpj.naturezaJuridica,
            dataCriacao: dataCnpj.dataCriacao.substring(0, 10),
            cnae: dataCnpj.cnae,
            Endereco: {
              ...prevValues.Endereco,
              cep: dataCnpj.Endereco.cep,
              numero: dataCnpj.Endereco.numero,
              complemento: dataCnpj.Endereco.complemento,
              logradouro: dataCnpj.Endereco.logradouro,
              bairro: dataCnpj.Endereco.bairro,
              municipio: dataCnpj.Endereco.municipio,
              estado: dataCnpj.Endereco.estado,
            },
            Contato: {
              ...prevValues.Contato,
              telefone: dataCnpj.Contato.telefone,
              celular: dataCnpj.Contato.celular,
              email: dataCnpj.Contato.email,
            },
          }));
          console.log("esse cnpj existe");
        } else {
          setCpfCnpjExiste(false);
          console.log("esse cnpj nn existe");
        }
      } catch (error) {
        console.log(error);
        setCpfCnpjExiste(false);
      }
    }
  };

  useEffect(() => {
    console.log("teste do useEffect");
    if (paramsValor && !isAModalPage) {
      setCpfCnpj((prevValues) => ({ ...prevValues, cpfCnpj: paramsValor }));
      setCpfValues((prevValues) => ({ ...prevValues, cpf: paramsValor }));
      fetchCpfCnpj(paramsValor);
    }
    // eslint-disable-next-line
  }, [paramsValor]);

  /* -------------- MODAL FUNCTIONS ---------------------- */
  function limpaValoresGerais() {
    setCpfCnpj({ ...cpfCnpj, cpfCnpj: "" });
    setCpfValues({ ...cpfValues, cpf: "" });
    setCodigoCadastro("");
    setInputCpfCnpj("");
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  /* ----------------------------------------------------- */

  const checkParamsValor = () => {
    if (paramsValor.length === 11) {
      return FormatCpfPaste(paramsValor);
    } else {
      return FormatCnpjPaste(paramsValor);
    }
  };

  return (
    <ContainerToModal
      isAModalPage={isAModalPage}
      id={isAModalPage ? undefined : "pags_responsividade_padding_geral"}
      fluid
      className={
        isAModalPage ? undefined : "container_conteudo conteudo_pagina"
      }
    >
      <div>
        <ModalStyled
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          overlayClassName="modal-overlay"
          contentLabel="Example Modal"
        >
          <DivIconModal>
            <Icon
              width={100}
              icon="fluent:error-circle-20-regular"
              className="icon_alert_modal"
            />
          </DivIconModal>

          <h6>CPF/CNPJ informado já está cadastrado</h6>
          <p>continuar e atualizar os dados do cadastro?</p>
          <ButtonsDiv>
            <button className="sim_button" onClick={closeModal}>
              Sim
            </button>
            <button
              className="nao_button"
              onClick={() => {
                limpaValoresGerais();
                setCpfCnpjExiste(false);
              }}
            >
              Não
            </button>
          </ButtonsDiv>
        </ModalStyled>
      </div>

      {!isAModalPage && (
        <>
          <ToastContainer />

          <HeaderCadastros
            PaginaConsulta={pagConsulta}
            NomePaginaTitulo={"CPF/CNPJ"}
            PaginaSubtitulo={paramsValor ? "Atualizar" : "Cadastro"}
            ButtonSaveFunction={
              cpfCnpjExiste ? handleUpdateCadastro : handleCadastro
            }
            PaginaRelatorio={
              "/planejamento/cadastro/cadastros-cpf-cnpj/relatorio"
            }
          />
        </>
      )}

      {isAModalPage && (
        <ButtonSaveModalAutoComplete
          SaveFunction={cpfCnpjExiste ? handleUpdateCadastro : handleCadastro}
          pageTitle={"CPF/CNPJ"}
        />
      )}

      <RowToModal isAModalPage={isAModalPage} className="row_form_2 mt-1">
        <CCol md={2}>
          <FloatingLabelInput
            style={{ color: "#515c70" }}
            type="text"
            value={codigoCadastro}
            placeholder="Código"
            label="Código"
            disabled
          />
        </CCol>
        <CColCpfCnpj md={2}>
          <FloatingLabelInput
            tipo="icon-x"
            icon="octicon:x-12"
            width="13"
            colorIcon="#140077"
            id="cpf_cnpj_cadastro"
            type="text"
            name="cnpj"
            maxLength="18"
            value={
              paramsValor && !isAModalPage ? checkParamsValor() : inputCpfCnpj
            }
            disabled={
              (paramsValor && !isAModalPage) || cpfCnpjExiste ? true : false
            }
            required
            onKeyDown={(e) => setTeclaPressionada(e.keyCode)}
            placeholder="CPF ou CNPJ"
            label="CPF ou CNPJ"
            cpfCnpj={cpfCnpj}
            onClickIcon={() => {
              limpaValoresGerais();
              setCpfCnpjExiste(false);
            }}
            onChange={(e) => {
              setInputCpfCnpj(e.target.value);
              const cleanedValue = e.target.value.replace(/[^\d]/g, "");
              if (cleanedValue.length <= 14 || !cpfCnpjExiste) {
                setCondicaoValidaCpfCnpj(true);
              }

              setCpfCnpj({ ...cpfCnpj, cpfCnpj: cleanedValue });
              setCpfValues({ ...cpfValues, cpf: cleanedValue });
              if (teclaPressionada !== 8) {
                formatCpfCnpj(false, e.target.value);
              }
              if (teclaPressionada === 8 && cleanedValue.length === 11) {
                formatCpfCnpj(true);
              }

              if (teclaPressionada === 8 && cpfCnpjExiste) {
                setCpfCnpjExiste(false);
              }

              if (cleanedValue.length === 11 || cleanedValue.length === 14) {
                fetchCpfCnpj(cleanedValue);
              }
            }}
            style={
              cpfValid === 0
                ? {}
                : cpfValid === false && cpfValues.cpf.length === 11
                ? { borderColor: "red", color: "red", animation: "shake .5s" }
                : {}
            }
          />
        </CColCpfCnpj>

        {cpfValues.cpf.length <= 11 && (
          <FormCpf
            cpfValid={cpfValid}
            values={cpfValues}
            setValues={setCpfValues}
            paramsValor={paramsValor && !isAModalPage ? paramsValor : undefined}
            isAModalPage={isAModalPage}
          />
        )}

        {cpfValues.cpf.length > 11 && (
          <FormCnpj
            values={values}
            setValues={setValues}
            paramsValor={paramsValor && !isAModalPage ? paramsValor : undefined}
            isAModalPage={isAModalPage}
          />
        )}
      </RowToModal>
      {/* Endereco */}
      {cpfValid === true && (
        <EnderecoCpf
          values={cpfValues}
          setValues={setCpfValues}
          isAModalPage={isAModalPage}
        />
      )}

      {cpfValues.cpf.length === 14 && (
        <EnderecoCnpj
          isAModalPage={isAModalPage}
          values={values}
          setValues={setValues}
          cepJaCadastrado={cepJaCadastrado}
          setCepJaCadastrado={setCepJaCadastrado}
        />
      )}
    </ContainerToModal>
  );
};

export default ComponentCadastroCpfCnpj;
