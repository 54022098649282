import api from "../../utils/api";

export const getAllByClientIdContaBancaria = async (clientId, year) => {
  try {
    const resp = await api.get(`contaBancaria/cliente/${clientId}/${year}`);
    return resp.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
