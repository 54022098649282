import api from "../../utils/api";

export const getByIdGre = async (idGre) => {
  try {
    const resp = await api.get(`gre/${idGre}`);
    return resp.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
