import api from "../../utils/api";

export const postQdrde = async (infosQdrde) => {
  try {
    await api.post(`qdrde`,infosQdrde);
    return true;
  } catch (error) {
    return null;
  }
};
