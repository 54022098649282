import { colors } from "../../../styles/colors";

export const IconCancel = ({ title = "Anular", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 16 16"
      {...props}
    >
      <title>{title}</title>
      <path
        fill={colors["icon-cancel"]}
        d="M4 3a2 2 0 0 1 2-2h3.586a1.5 1.5 0 0 1 1.06.44l2.915 2.914A1.5 1.5 0 0 1 14 5.414V13a2 2 0 0 1-2 2H8.663a5.5 5.5 0 0 0 1.08-1H12a1 1 0 0 0 1-1V6h-2.5A1.5 1.5 0 0 1 9 4.5V2H6a1 1 0 0 0-1 1v2.022a5.5 5.5 0 0 0-1 .185zm6.5 2h2.293L10 2.207V4.5a.5.5 0 0 0 .5.5M2.318 7.318a4.5 4.5 0 1 0 6.364 6.364 4.5 4.5 0 0 0-6.364-6.364m4.95 4.95a.5.5 0 0 1-.707 0L5.5 11.208l-1.06 1.06a.5.5 0 0 1-.708-.707l1.06-1.061-1.06-1.06a.5.5 0 0 1 .707-.708L5.5 9.792l1.06-1.06a.5.5 0 0 1 .708.707L6.208 10.5l1.06 1.06a.5.5 0 0 1 0 .708"
      ></path>
    </svg>
  );
};
