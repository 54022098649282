import api from "../../utils/api";

export const putGre = async (idGre, infosGre) => {
  try {
    await api.put(`gre/${idGre}`, infosGre);
    return true;
  } catch (error) {
    return null;
  }
};
