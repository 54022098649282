import { Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import { useContext, useEffect, useState } from "react";
import { TestButton } from "../../../../utils/TestButton";
import api from "../../../../utils/api";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { CurrentYearContext } from "../../../../contexts/YearContext";
import ProdutoEAquisicaoAba from "../NotaDeEmpenho/ProdutoEAquisicaoAba";
import { toast, ToastContainer } from "react-toastify";
import HistoricoAba from "../NotaDeEmpenho/HistoricoAba";
import { useNavigate, useParams } from "react-router-dom";
import SaldosAba from "../NotaDeEmpenho/SaldosAba";

const AnulacaoDaDespesa = () => {
  const navigate = useNavigate();
  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useContext(CurrentYearContext);
  const { idAnulacao, idEmpenho } = useParams();
  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Motivo",
    numeroAba: 0,
  });
  const [empenhosOptions, setEmpenhosOptions] = useState([]);
  const [liquidacoesOptions, setLiquidacoesOptions] = useState([]);
  const [inputAutoComplete, setInputAutoComplete] = useState("");
  const [produtoAquisicao, setProdutoAquisicao] = useState([]);
  const currentDate = new Date();
  currentDate.setFullYear(currentYear);
  const [values, setValues] = useState({
    empenho: {},
    tipoAnulacao: idEmpenho && "Empenho",
    tipoAnulacaoLabel: idEmpenho && "Empenho",
    data: currentDate.toISOString().split("T")[0],
    liquidacao: {},
    anulacao: "",
    valor: "",
    unidadeOrcamentaria: "",
    programa: "",
    elementoDaDespesa: "",
    fonteDeRecursos: "",
    funcao: "",
    subfuncao: "",
    exercicio: "",
    descricao: "",
  });
  const [valorMaximo, setValorMaximo] = useState(null);
  const [saldos, setSaldos] = useState({
    empenhado: "",
    liquidado: "",
  });

  const clearFunction = () => {
    setValues((prevValues) => ({
      ...prevValues,
      empenho: {},
      liquidacao: {},
      anulacao: "",
      valor: "",
      unidadeOrcamentaria: "",
      programa: "",
      elementoDaDespesa: "",
      fonteDeRecursos: "",
      funcao: "",
      subfuncao: "",
      exercicio: "",
      descricao: "",
    }));
    setSaldos({
      empenhado: "",
      liquidado: "",
    });
    setValorMaximo("");
    setInputAutoComplete("");
  };

  useEffect(() => {
    clearFunction();
  }, [values.tipoAnulacao]);

  const fetchEmpenho = async () => {
    try {
      const response = await api.get(
        `/notaEmpenho/${currentClient.clienteId}/${currentYear}`
      );
      setEmpenhosOptions(response.data);
      console.log("RESPONSE: ", response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEmpenhoDireto = () => {
    if (idEmpenho) {
      const empenho = empenhosOptions.find(
        (item) => parseInt(item?.id) === parseInt(idEmpenho)
      );

      handleSelectAutoComplete(empenho);
      const nome =
        empenho?.fornecedorInfo?.dados?.nomeCompleto ||
        empenho?.fornecedorInfo?.dados?.nomeFantasia;
      setInputAutoComplete(`${empenho?.numero} - ${nome}`);
    }
  };

  const fetchLiquidacao = async () => {
    try {
      const response = await api.get(
        `/liquidacaoDeEmpenho/cliente/${currentClient.clienteId}`
      );
      setLiquidacoesOptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectAutoComplete = (option) => {
    console.log(option);
    if (values.tipoAnulacao === "Empenho") {
      const valorMaximo =
        parseFloat(option?.saldoEmpenhado || "00.00") -
        parseFloat(option?.saldoLiquidado || "00.00");
      const elementoDaDespesa =
        option?.fichaInfo[0]?.despesaFixadaQDD?.fichasQDD?.naturezaDaDespesa;
      setValues((prevValues) => ({
        ...prevValues,
        empenho: option,
        liquidacao: {},
        anulacao: `${option?.numero} 1`,
        elementoDaDespesa: `${
          elementoDaDespesa?.codigo?.length === 12
            ? elementoDaDespesa?.codigo?.slice(0, -3)
            : elementoDaDespesa
        } - ${elementoDaDespesa?.nome}`,
        funcao: `${option?.fichaInfo[0]?.acaoGovernamentalInfo?.funcao?.valor} - ${option?.fichaInfo[0]?.acaoGovernamentalInfo?.funcao?.nome}`,
        subfuncao: `${option?.fichaInfo[0]?.acaoGovernamentalInfo?.subFuncao?.valor} - ${option?.fichaInfo[0]?.acaoGovernamentalInfo?.subFuncao?.nome}`,
        unidadeOrcamentaria: `${option?.fichaInfo[0]?.acaoGovernamentalInfo?.unidadeOrcamentariaId?.codigoUnidadeOrcamentaria} - ${option?.fichaInfo[0]?.acaoGovernamentalInfo?.unidadeOrcamentariaId?.tituloUnidadeOrcamentaria}`,
        programa: `${option?.fichaInfo[0]?.acaoGovernamentalInfo?.programaPlanejamentoId?.numeroPrograma} - ${option?.fichaInfo[0]?.acaoGovernamentalInfo?.programaPlanejamentoId?.tituloPrograma}`,
        fonteDeRecursos: `${option?.fichaInfo[0]?.despesaFixadaQDD?.fonteDeRecurso?.conta} - ${option?.fichaInfo[0]?.despesaFixadaQDD?.fonteDeRecurso?.titulo}`,
        exercicio: `${option?.fichaInfo[0]?.acaoGovernamentalInfo?.tipoAcao}`,
        valor: valorMaximo,
      }));
      setProdutoAquisicao(
        option?.produtos?.map((item) => ({
          ...item,
          produtoAquisicao: {
            id: item?.produtoAquisicaoId,
            value: item?.numero,
            label: `${item?.numero} - ${item?.produtoAquisicao}`,
          },
          unidadeMedida: item.unidadeDeMedida,
        }))
      );
      setValorMaximo(
        parseFloat(option?.saldoEmpenhado || "00.00") -
          parseFloat(option?.saldoLiquidado || "00.00")
      );
      setSaldos({
        empenhado: option?.saldoEmpenhado || "00.00",
        liquidado: option?.saldoLiquidado || "00.00",
      });
    } else {
      if (values.tipoAnulacao === "Liquidação") {
        const valorMaximo = option.valor;
        console.log("VALOR MAXIMO: ", valorMaximo);
        const elementoDaDespesa =
          option?.empenho?.fichaInfo[0]?.despesaFixadaQDD?.fichasQDD
            ?.naturezaDaDespesa;
        setValues((prevValues) => ({
          ...prevValues,
          empenho: {},
          liquidacao: option,
          anulacao: `${option?.empenho?.numero} ${option?.liquidacao} ${1}`,
          elementoDaDespesa: `${
            elementoDaDespesa?.codigo?.length === 12
              ? elementoDaDespesa?.codigo?.slice(0, -3)
              : elementoDaDespesa
          } - ${elementoDaDespesa?.nome}`,
          funcao: `${option?.empenho?.fichaInfo[0]?.acaoGovernamentalInfo?.funcao?.valor} - ${option?.empenho?.fichaInfo[0]?.acaoGovernamentalInfo?.funcao?.nome}`,
          subfuncao: `${option?.empenho?.fichaInfo[0]?.acaoGovernamentalInfo?.subFuncao?.valor} - ${option?.empenho?.fichaInfo[0]?.acaoGovernamentalInfo?.subFuncao?.nome}`,
          unidadeOrcamentaria: `${option?.empenho?.fichaInfo[0]?.acaoGovernamentalInfo?.unidadeOrcamentariaId?.codigoUnidadeOrcamentaria} - ${option?.empenho?.fichaInfo[0]?.acaoGovernamentalInfo?.unidadeOrcamentariaId?.tituloUnidadeOrcamentaria}`,
          programa: `${option?.empenho?.fichaInfo[0]?.acaoGovernamentalInfo?.programaPlanejamentoId?.numeroPrograma} - ${option?.empenho?.fichaInfo[0]?.acaoGovernamentalInfo?.programaPlanejamentoId?.tituloPrograma}`,
          fonteDeRecursos: `${option?.empenho?.fichaInfo[0]?.despesaFixadaQDD?.fonteDeRecurso?.conta} - ${option?.empenho?.fichaInfo[0]?.despesaFixadaQDD?.fonteDeRecurso?.titulo}`,
          exercicio: `${option?.empenho?.fichaInfo[0]?.acaoGovernamentalInfo?.tipoAcao}`,
          valor: valorMaximo,
        }));
        setProdutoAquisicao(
          option?.empenho?.produtos.map((item) => ({
            ...item,
            produtoAquisicao: {
              id: item?.produtoAquisicaoId,
              value: item?.numero,
              label: `${item?.numero} - ${item?.produtoAquisicao}`,
            },
            unidadeMedida: item.unidadeDeMedida,
          }))
        );
        console.log("OPTIOONN: ", option);
        setValorMaximo(valorMaximo);
        setSaldos({
          empenhado: option?.saldoEmpenhado || "00.00",
          liquidado: option?.saldoLiquidado || "00.00",
        });
      }
    }
  };

  useEffect(() => {
    fetchEmpenho();
    fetchLiquidacao();
    fetchAnulacaoAtual();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchEmpenhoDireto();
    // eslint-disable-next-line
  }, [idAnulacao, empenhosOptions]);

  const handleSave = async () => {
    if (!idAnulacao) {
      const valuesToPost = {
        clienteId: currentClient.clienteId,
        tpAnulacao: values.tipoAnulacaoLabel,
        data: values.data,
        valor: values.valor.includes(",")
          ? values.valor.replace(/\./g, "").replace(",", ".")
          : values.valor,
        empenhoId: values.empenho.id || null,
        liquidacaoId: values.liquidacao.id || null,
        motivo: values.descricao,
        anulacao: values.anulacao,
      };
      console.log(valuesToPost);
      try {
        const response = await api.post("/anulacaoDespesa", valuesToPost);
        console.log(response.data);
        toast.success("Anulação Realizada Com Sucesso!");
        navigate("/contabilidade/despesa/consulta/anulacao-da-despesa");
      } catch (error) {
        toast.error(
          error?.data?.response?.data || "Não Foi Possível Anular Essa  Receita"
        );
        console.log(error.response, valuesToPost);
      }
    } else {
      console.log("Consulta");
    }
  };

  const fetchAnulacaoAtual = async () => {
    if (idAnulacao) {
      try {
        const response = await api.get(
          `/anulacaoDespesa/${idAnulacao}/${currentYear}`
        );
        console.log("AQUIIII: ", response.data);
        const data = response.data;
        const empenho = data?.empenhoId
          ? data?.emepenhoInfo
          : data?.liquidacaoInfo?.empenho;
        const elementoDaDespesa =
          empenho?.fichaInfo[0]?.despesaFixadaQDD?.fichasQDD?.naturezaDaDespesa;
        setValues((prevValues) => ({
          ...prevValues,
          tipoAnulacao: data?.tpAnulacao,
          empenho: data?.empenhoId ? data?.emepenhoInfo : null,
          liquidacao: data?.liquidacaoId ? data?.liquidacaoInfo : null,
          descricao: data?.motivo,
          anulacao: data?.anulacao,
          valor: data?.valor,
          elementoDaDespesa: `${
            elementoDaDespesa?.codigo?.length === 12
              ? elementoDaDespesa?.codigo?.slice(0, -3)
              : elementoDaDespesa
          } - ${elementoDaDespesa?.nome}`,
          funcao: `${empenho?.fichaInfo[0]?.acaoGovernamentalInfo?.funcao?.valor} - ${empenho?.fichaInfo[0]?.acaoGovernamentalInfo?.funcao?.nome}`,
          subfuncao: `${empenho?.fichaInfo[0]?.acaoGovernamentalInfo?.subFuncao?.valor} - ${empenho?.fichaInfo[0]?.acaoGovernamentalInfo?.subFuncao?.nome}`,
          unidadeOrcamentaria: `${empenho?.fichaInfo[0]?.acaoGovernamentalInfo?.unidadeOrcamentariaId?.codigoUnidadeOrcamentaria} - ${empenho?.fichaInfo[0]?.acaoGovernamentalInfo?.unidadeOrcamentariaId?.tituloUnidadeOrcamentaria}`,
          programa: `${empenho?.fichaInfo[0]?.acaoGovernamentalInfo?.programaPlanejamentoId?.numeroPrograma} - ${empenho?.fichaInfo[0]?.acaoGovernamentalInfo?.programaPlanejamentoId?.tituloPrograma}`,
          fonteDeRecursos: `${empenho?.fichaInfo[0]?.despesaFixadaQDD?.fonteDeRecurso?.conta} - ${empenho?.fichaInfo[0]?.despesaFixadaQDD?.fonteDeRecurso?.titulo}`,
          exercicio: `${empenho?.fichaInfo[0]?.acaoGovernamentalInfo?.tipoAcao}`,
        }));
        setInputAutoComplete(
          data?.empenhoId
            ? data?.emepenhoInfo?.numero
            : `${data?.liquidacaoInfo?.empenho?.numero} ${data?.liquidacaoInfo?.liquidacao}`
        );
        setProdutoAquisicao(
          empenho.produtos.map((item) => ({
            ...item,
            produtoAquisicao: {
              id: item?.produtoAquisicaoId,
              value: item?.numero,
              label: `${item?.numero} - ${item?.produtoAquisicao}`,
            },
            unidadeMedida: item.unidadeDeMedida,
          }))
        );
        setSaldos({
          empenhado: data?.empenhoId
            ? data?.emepenhoInfo?.saldoEmpenhado
            : data?.liquidacaoInfo?.saldoEmpenhado,
          liquidado: data?.empenhoId
            ? data?.emepenhoInfo?.saldoLiquidado
            : data?.liquidacaoInfo?.saldoLiquidado,
        });
      } catch (error) {
        toast.error(
          error.response.data.error || "Não foi Possível Buscar Essa Anulação"
        );
        console.log(error.response);
      }
    }
  };

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <ToastContainer />
      <TestButton onClick={() => console.log(liquidacoesOptions)}>
        Liquidações e Empenhos
      </TestButton>
      <TestButton
        onClick={() => {
          console.log(values);
        }}
      >
        Values
      </TestButton>
      <HeaderCadastros
        NomePaginaTitulo={"Anulação da Despesa"}
        PaginaSubtitulo={"Cadastro"}
        ButtonSaveFunction={handleSave}
        PaginaConsulta={"/contabilidade/despesa/consulta/anulacao-da-despesa"}
      />
      <RowForm className="mt-1">
        <CCol>
          <FloatingLabelInput
            disabled={idAnulacao}
            tipo="input_select"
            new
            options={[
              { label: "Tipo de Anulação", value: null },
              { label: "Empenho", value: "Empenho" },
              { label: "Liquidação", value: "Liquidação" },
            ]}
            placeholder="Tipo de Anulação - Liquidação - Anulação"
            value={values.tipoAnulacao}
            onSelect={(option) => {
              setValues((prevValues) => ({
                ...prevValues,
                tipoAnulacao: option.value,
                tipoAnulacaoLabel: option.label,
              }));
            }}
          />
        </CCol>
        <CCol md={2}>
          <InputData
            disabled={idAnulacao}
            value={values.data}
            onInput={(e) => {
              console.log(e.target.value);
              setValues({
                ...values,
                data: e.target.value,
              });
            }}
          />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <MyAutoComplete
            iconClearFunction={() => clearFunction()}
            inputValue={inputAutoComplete}
            setInputValue={setInputAutoComplete}
            disabled={!values?.tipoAnulacaoLabel || idAnulacao}
            iconsOff={!values?.tipoAnulacaoLabel || idAnulacao}
            options={
              values.tipoAnulacao === "Empenho"
                ? empenhosOptions
                : liquidacoesOptions
            }
            labelFormat={(item) =>
              values.tipoAnulacao === "Empenho"
                ? `${item?.numero} - ${
                    item?.fornecedorInfo?.dados?.nomeCompleto ||
                    item?.fornecedorInfo?.dados?.nomeFantasia
                  }`
                : `${item?.empenho?.numero} ${item?.liquidacao} - ${
                    item?.empenho?.fornecedorInfo?.dados?.nomeCompleto ||
                    item?.empenho?.fornecedorInfo?.dados?.nomeFantasia
                  }`
            }
            labelInput={values?.tipoAnulacaoLabel}
            style={{ height: "37px" }}
            onOptionSelect={(option) => handleSelectAutoComplete(option)}
          />
        </CCol>

        <CCol md={2}>
          <FloatingLabelInput
            disabled
            label="Anulação"
            placeholder="Anulação"
            value={values.anulacao}
          />
        </CCol>

        <CCol md={2}>
          <InputFormatRealBrasileiro
            putDot
            disabled={idAnulacao}
            placeholder={"valor"}
            value={values.valor}
            onChange={(_, valor) => {
              // Converte o valor digitado para número
              let valorNumerico = parseFloat(
                valor.replace(/\./g, "").replace(",", ".")
              );

              // Se o valor for maior que valorMaximo, ajusta para valorMaximo
              if (valorNumerico > valorMaximo) {
                valorNumerico = parseFloat(valorMaximo);
                console.log(valorMaximo);
              }

              // Converte o valor novamente para o formato de string (com a vírgula)
              const valorCorrigido =
                valorNumerico === parseFloat(valorMaximo)
                  ? valorNumerico.toFixed(2).replace(".", ",")
                  : valor;

              // Atualiza o estado com o valor corrigido
              setValues((prevValues) => ({
                ...prevValues,
                valor: valorCorrigido,
              }));
            }}
          />
        </CCol>
      </RowForm>
      <RowInfosAcao>
        <CCol md={7}>
          <p>
            Unidade Orçamentária: <span>{values.unidadeOrcamentaria}</span>
          </p>
          <p>
            Programa: <span>{values.programa}</span>
          </p>
          <p>
            Elemento de Despesa: <span>{values.elementoDaDespesa}</span>
          </p>
          <p>
            Fonte de Recursos: <span>{values.fonteDeRecursos}</span>
          </p>
        </CCol>
        <CCol>
          <p>
            Função: <span>{values.funcao}</span>
          </p>
          <p>
            Subfunção: <span>{values.subfuncao}</span>
          </p>
          <p>
            Exercício - CNP: <span>{values.exercicio}</span>
          </p>
        </CCol>
      </RowInfosAcao>
      <RowSelecaoAbasInferior
        abas={["Motivo", "Produto e Aquisição", "Saldos"]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      />
      {abaSelecionada.nomeAba === "Motivo" && (
        <HistoricoAba
          setValues={setValues}
          values={values}
          disabled={idAnulacao}
        />
      )}
      {abaSelecionada.nomeAba === "Produto e Aquisição" && (
        <ProdutoEAquisicaoAba
          produtoAquisicao={produtoAquisicao}
          setProdutoAquisicao={setProdutoAquisicao}
          // valorTotal={values.empenho.valor}
          liquidacaoAtualId={true}
        />
      )}
      {abaSelecionada.nomeAba === "Saldos" && <SaldosAba saldos={saldos} />}
    </Container>
  );
};

export default AnulacaoDaDespesa;
