import { useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Header/Header";
import { InvisibleCheckbox } from "../Header/StylesHeader";
// import SideBarGeral from "../SideBar/SideBarGeral";
// import SideBarGeralPlanejamento from "../SideBar/SideBar_Planejamento/SideBarGeral_Planejamento";
import { SidebarExpandidaContext } from "../../contexts/SidebarExpandidaContext";
import { DivHideOnPrint } from "../../styles/StyledComponents/StylesRelatorios";
import SideBarComponent from "../SideBarComponent/SideBarComponent";
import ContabilidadeNav from "../SideBarComponent/ContabilidadeNav";
import GerenciadorNav from "../SideBarComponent/GerenciadorNav";
import PlanejamentoNav from "../SideBarComponent/PlanejamentoNav";

function Estrutura() {
  const location = useLocation();
  const { setSidebarExpandida, sidebarExpandida } = useContext(
    SidebarExpandidaContext
  );

  const navItemsRef = useRef(null);

  const showSidebarPlanejamento = location.pathname.includes("/planejamento");
  const showSidebarContabilidade = location.pathname.includes("/contabilidade");
  const showSidebarAcessoInterno =
    location.pathname.includes("/acesso-interno") ||
    location.pathname.includes("/home-test");

  const handleChecked = (e) => {
    setSidebarExpandida(e.target.checked);
  };

  const scrollToBottom = () => {

    if (navItemsRef.current) {
      navItemsRef.current.scrollTo({
        top: navItemsRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  

  return (
    <>
      <DivHideOnPrint>
        <InvisibleCheckbox
          id="check"
          checked={sidebarExpandida}
          onChange={handleChecked}
        />

        {/* Renderiza o SideBarGeral_Planejamento apenas se a URL contiver "/planejamento" */}
        {showSidebarPlanejamento && (
          <>
            {/* <SideBarGeralPlanejamento checkSidebar={sidebarExpandida} /> */}
            <SideBarComponent
              checkSidebar={sidebarExpandida}
              nomeDoModulo={"planejamento"}
            >
              <PlanejamentoNav checkSidebar={sidebarExpandida} />
            </SideBarComponent>
          </>
        )}
        {/* Renderiza o SideBarGeral apenas se a URL contiver "/acesso-interno"*/}
        {showSidebarAcessoInterno && window.location.pathname !== "/" && (
          <>
            {/* <SideBarGeral checkSidebar={sidebarExpandida} /> */}
            <SideBarComponent
              checkSidebar={sidebarExpandida}
              nomeDoModulo={"gerenciador"}
            >
              <GerenciadorNav checkSidebar={sidebarExpandida} />
            </SideBarComponent>
          </>
        )}
        {window.location.pathname !== "/" && <Header />}
        {showSidebarContabilidade && (
          <SideBarComponent
            ref={navItemsRef}
            checkSidebar={sidebarExpandida}
            nomeDoModulo={"contabilidade"}
          >
            <ContabilidadeNav
              checkSidebar={sidebarExpandida}
              scrollToBottom={scrollToBottom}
            />
          </SideBarComponent>
        )}
      </DivHideOnPrint>
    </>
  );
}

export default Estrutura;
