import React from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
  RowForm,
  RowToModal,
} from "../../../../components/Grid/CCol";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { toast } from "react-toastify";

import { Icon } from "@iconify/react/dist/iconify.js";

export const FonteDeRecursosAba = ({
  infosCadastro,
  setInfosCadastro,
  optionsFonte,
  inputFonteValue,
  setInputFonteValue,
  addInfosFonte,
  setAddInfosFonte,
}) => {
  const handleSelectFonte = (option) => {
    console.log(option);
    setAddInfosFonte(option);
  };

  const handleAddFonteRecurso = () => {
    console.log(addInfosFonte);

    const hasInfosFonte = Object.values(addInfosFonte).every(
      (fonte) => fonte !== ""
    );

    if (!hasInfosFonte) {
      toast.error("Informe uma Fonte de Recursos.");
      return;
    }

    const isFonteExists = infosCadastro.fontes.some(
      (fonte) => fonte.fonteId === addInfosFonte.fonteId
    );

    if (isFonteExists) {
      toast.info("Está Fonte de Recursos já foi adicionada.");
      return;
    }

    setInfosCadastro((prevState) => ({
      ...prevState,
      fontes: [...prevState.fontes, addInfosFonte],
    }));

    resetFieldsFonte();
    setInputFonteValue("");
  };

  const resetFieldsFonte = () => {
    setAddInfosFonte({
      fonteId: "",
      valor: "0,00",
      conta: "",
      titulo: "",
      label: "",
    });
  };

  const handleDeleteFonteRecurso = (fonteId) => {
    console.log(fonteId);
    const filterFontes = infosCadastro.fontes.filter(
      (fonte) => fonte.fonteId !== fonteId
    );
    setInfosCadastro((prevState) => ({
      ...prevState,
      fontes: filterFontes,
    }));
  };
  return (
    <>
      <RowToModal
        className={"row_form mt-3"}
        style={{
          borderRadius: "5px 5px 0px 0px",
          padding: "8px 10px",
        }}
      >
        <CCol
          style={{
            fontSize: "16px",
            color: "#515c70",
            fontWeight: "700",
          }}
        >
          Fonte de Recursos
        </CCol>
      </RowToModal>

      <RowForm $borderRadiusType={2} className="mt-1">
        <CCol>
          <PTitulosInputs>Fonte de Recursos</PTitulosInputs>
        </CCol>

        <CCol md={2}>
          <PTitulosInputs>Valor</PTitulosInputs>
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
        </CColButtonsAcoes2Icons>

        <RowAninhada>
          <CCol>
            <MyAutoComplete
              options={optionsFonte}
              labelFormat={(item) => item.label}
              inputValue={inputFonteValue}
              setInputValue={setInputFonteValue}
              onOptionSelect={handleSelectFonte}
              iconClearFunction={resetFieldsFonte}
            />
          </CCol>

          <CCol md={2}>
            <InputFormatRealBrasileiro
              listaDeInputs={true}
              externalValue={addInfosFonte.valor}
              disabled
              // onChange={(_, valor) =>
              //   setFonteParaAdicionar((prev) => ({
              //     ...prev,
              //     valor,
              //   }))
              // }
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <IconAdicionar
              onClick={handleAddFonteRecurso}
              height="28"
              style={{
                cursor: "pointer",
                marginLeft: "5px",
                marginTop: "1px",
              }}
            />
          </CColButtonsAcoes2Icons>
        </RowAninhada>
        {infosCadastro.fontes.map((item, index, array) => {
          if (item.fonteId === "") return null;
          return (
            <RowAninhada key={index} style={{ marginTop: "-1px" }}>
              <CCol>
                <FormControlListaDeInputs
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                  value={item.label}
                  disabled
                />
              </CCol>

              <CCol md={2}>
                <InputFormatRealBrasileiro
                  listaDeInputs
                  externalValue={item.valor}
                  disabled
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                <Icon
                  onClick={() => handleDeleteFonteRecurso(item.fonteId)}
                  icon="ic:baseline-delete"
                  color="#E79900"
                  height="26"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "1px",
                  }}
                />
              </CColButtonsAcoes2Icons>
            </RowAninhada>
          );
        })}
      </RowForm>
    </>
  );
};
