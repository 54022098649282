import api from "../../utils/api";

export const deleteRetencao = async (idRetencao) => {
  try {
    await api.delete(`retencao/${idRetencao}`);
    return true;
  } catch (error) {
    return null;
  }
};
