import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Col, Container, Row, Table } from "react-bootstrap";

import { toast, ToastContainer } from "react-toastify";

import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";

import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import InputSvg from "../../../../components/Grid/InputSvg";
import HeaderOptions from "../../../../components/HeaderOptions/HeaderOptions";
import ModalDeleteData from "../../../../components/ModalDeleteData/ModalDeleteData";
import { IconHistory } from "../../../../components/Icons/IconHistory";
import { IconEdit } from "../../../../components/Icons/IconEdit";
import { IconDelete } from "../../../../components/Icons/IconDelete";

import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";

import api from "../../../../utils/api";
import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

import { useFetchData } from "../../../../hooks/useFetchData";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";

import { optionsTipoAnulacao } from "./optionsTipoAnulacao";

import { colors } from "../../../../styles/colors";

function ConsultaAnulacaoReceita() {
  const { currentYear } = useCurrentYear();
  const { currentClient } = useContext(CurrentClientContext);
  const navigate = useNavigate();
  const { data: dataAnulacaoReceita, loading } = useFetchData(
    `anulacaoReceita/cliente/${currentClient.clienteId}/${currentYear}`
  );

  const [dataAnulacaoFormatted, setDataAnulacaoFormatted] = useState([
    {
      id: "",
      tipoAnulacao: "",
      data: "",
      numeroGuia: "",
      valorAnulacao: "",
      natureza: "",
      motivo: "",
      isDelete: "",
    },
  ]);
  const [modalOpened, setModalOpened] = useState(false);
  const [dataDelete, setDataDelete] = useState({ id: 0 });
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [dataAnulacaoFiltered, setDataAnulacaoFiltered] = useState([
    {
      id: "",
      tipoAnulacao: "",
      data: "",
      numeroGuia: "",
      valorAnulacao: "",
      natureza: "",
      motivo: "",
      isDelete: "",
    },
  ]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filteredItems = dataAnulacaoFormatted.filter((item) => {
      return (
        item.tipoAnulacao.toString().includes(value) ||
        item.numeroGuia.toString().includes(value) ||
        item.natureza
          .toLowerCase()
          .replaceAll(".", "")
          .trim()
          .includes(value.toLowerCase().replaceAll(".", "").trim()) ||
        item.valorAnulacao.includes(value.replaceAll(",", "."))
      );
    });
    setDataAnulacaoFiltered(filteredItems);
    setCurrentPage(0);
  };

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = dataAnulacaoFormatted.slice(startIndex, endIndex);
  const currentFilteredItens = dataAnulacaoFiltered.slice(startIndex, endIndex);

  const pages = Math.ceil(dataAnulacaoFormatted.length / itensPorPagina);
  const filteredPages = Math.ceil(dataAnulacaoFiltered.length / itensPorPagina);

  function openModal() {
    setModalOpened(true);
  }

  function closeModal() {
    setModalOpened(false);
  }
  const handleDeleteGuiaReceita = (id) => {
    openModal();
    setDataDelete({ id });
  };
  const deleteGuiaReceita = async () => {
    try {
      await api.delete(`anulacaoReceita/delete/${dataDelete.id}`);
      const filterDataGuiaReceita = dataAnulacaoFormatted.filter(
        (guia) => guia.id !== dataDelete.id
      );
      setDataAnulacaoFormatted(filterDataGuiaReceita);
      toast.success("Registro deletado com sucesso.");
    } catch (error) {
      toast.error("Algo deu errado ao deletar o registro.");
    }
  };
  useEffect(() => {
    console.log(dataAnulacaoReceita);

    if (dataAnulacaoReceita) {
      const formattedDataAnulacao = dataAnulacaoReceita
        .map((item) => {
          return item.guiasDeReceita.map((guia) => {
            const date = item.data.split("T")[0];
            const [year, month, day] = date.split("-");
            const dateFormatted = `${day}/${month}/${year}`;
            return {
              id: item.id,
              tipoAnulacao: item.tipoAnulacao,
              data: dateFormatted,
              numeroGuia: guia.info.numero,
              valorAnulacao: guia.valorAnulacao,
              natureza: `${guia.info.naturezaInfo.nr} - ${guia.info.naturezaInfo.especificacao}`,
              motivo: item.motivo,
              isDelete: item.delete === "on" ? true : false,
            };
          });
        })
        .flat();

      const orderData = formattedDataAnulacao.sort((dataA, dataB) => {
        return Number(dataB.numeroGuia) - Number(dataA.numeroGuia);
      });
      setDataAnulacaoFormatted(orderData);
    }
  }, [dataAnulacaoReceita]);

  const renderInfosTable = (infosTable, index) => {
    return (
      <tr key={index}>
        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
            display: "flex",
            alignItems: "center",
          }}
        >
          <button
            onClick={() =>
              navigate(
                `/contabilidade/receita/anulacao-de-receita/visualizar/${infosTable.id}`
              )
            }
            style={{
              width: "65px",
              backgroundColor: "transparent",
              outline: "none",
              border: "none",
              color: colors["text-table"],
              fontWeight: "600",
              fontSize: "14px",
              textAlign: "left",
            }}
          >
            <span>{infosTable.numeroGuia}</span>
          </button>
          <div>
            <IconHistory title={infosTable.motivo} />
          </div>
        </td>
        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
          title={
            optionsTipoAnulacao.find(
              (option) => option.type === infosTable.tipoAnulacao
            )?.label
          }
        >
          {infosTable.tipoAnulacao}
        </td>
        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
          title={infosTable.natureza}
        >
          {infosTable.natureza.length > 75
            ? `${infosTable.natureza.substring(0, 75)}...`
            : `${infosTable.natureza}`}
        </td>

        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
        >
          {`R$ ${FormatValueToLocaleString(
            parseFloat(infosTable.valorAnulacao)
          )}`}
        </td>

        <td
          style={{
            whiteSpace: "nowrap",
            verticalAlign: "middle",
          }}
          title={infosTable.data}
        >
          {infosTable.data}
        </td>
        <td
          style={{
            display: "flex",
            justifyContent: "start",
            flexShrink: "0",
            gap: "2px",
          }}
        >
          <IconEdit
            onClick={() =>
              navigate(
                `/contabilidade/receita/anulacao-de-receita/visualizar/${infosTable.id}`
              )
            }
          />
          <IconDelete
            style={{ marginLeft: "-2px" }}
            disabled={!infosTable.isDelete}
            handleDelete={() => handleDeleteGuiaReceita(infosTable.id)}
          />
        </td>
      </tr>
    );
  };

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderOptions
        withRow={true}
        TituloPagina={"Anulação de Receita"}
        SubTituloPagina={"Consulta"}
        ToPag={"/contabilidade/receita/anulacao-de-receita/cadastro"}
      />
      <ModalDeleteData
        modalOpened={modalOpened}
        closeModal={closeModal}
        setDataDelete={setDataDelete}
        deleteDataFunction={deleteGuiaReceita}
      />
      <ToastContainer />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loading ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        {currentItens.length > 0 && (
          <Table responsive style={{ marginTop: "-10px" }}>
            <thead>
              <tr>
                <TituloTable>Guia Anulada</TituloTable>
                <TituloTable>Tipo</TituloTable>
                <TituloTable>Natureza da Receita</TituloTable>
                <TituloTable>Valor</TituloTable>
                <TituloTable>Data</TituloTable>
                <TituloTableAction>Ação</TituloTableAction>
              </tr>
            </thead>

            <BodyTable>
              {searchTerm
                ? currentFilteredItens.map((item, index) => {
                    if (!item.id) return null;
                    return renderInfosTable(item, index);
                  })
                : currentItens.map((item, index) => {
                    if (!item.id) return null;
                    return renderInfosTable(item, index);
                  })}
            </BodyTable>
          </Table>
        )}
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm
              ? dataAnulacaoFiltered.length
              : dataAnulacaoFormatted
              ? dataAnulacaoFormatted.length
              : 0}{" "}
            registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>
            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default ConsultaAnulacaoReceita;
