import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { useLocation, useParams } from "react-router-dom";

import { Container } from "react-bootstrap";

import styled from "styled-components";

import { toast, ToastContainer } from "react-toastify";

import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputData from "../../../../components/Grid/InputData";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import { ProcedimentoContabel } from "../../Components/ProcedimentoContabel";
import { CustomToast } from "../../../../components/CustomToast";

import { HistoricoAba } from "./HistoricoAba";
import { FonteDeRecursosAba } from "./FonteDeRecursosAba";
import { TransferenciaRecebidaAba } from "./TransferenciaRecebidaAba";
import { RetencaoAba } from "./RetencaoAba";
import { ACCOUNTS_TYPE_TRANSFER_QDRDE } from "../QDRDE/accountsPCASP";

import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";
import { validateDescription } from "../../../../utils/Gerais/validateDescription";
import { formatLabelContaBancaria } from "../../../../utils/FormatacaoDeDados/FormatLabelContaBancaria";
import {
  checkIsValideData,
  formatDateToISO,
} from "../../../../utils/FormatacaoDeDados/FormatDateToRegister";

import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import { CurrentCityContext } from "../../../../contexts/CurrentCityContext";

import { useCurrentYear } from "../../../../hooks/useCurrentYear";

import { optionsTranferencias } from "./optionsTranferenciasRecebidas";

import { GreService } from "../../../../services/gre";
import { ContaBancariaService } from "../../../../services/contaBancaria";
import { CpfClientService } from "../../../../services/cpfCliente";
import { QdrdeService } from "../../../../services/qdrde";

const CColNumero = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const GreReceita = () => {
  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useCurrentYear();
  const { userAuthData } = useContext(AuthContext);
  const { currentCity } = useContext(CurrentCityContext);

  const { id: paramsIdGreReceita } = useParams();
  const location = useLocation();
  const hasRouteReplica = location.pathname.includes("replicar");

  const currentDate = () => {
    const date = new Date();
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");

    return `${day}/${month}/${currentYear}`;
  };

  const inputQdrdeRef = useRef(null);
  const inputDateRef = useRef(null);
  const inputContribuinteRef = useRef(null);
  const inputCaixaRef = useRef(null);
  const inputDocFinanceiroRef = useRef(null);
  const inputValorRef = useRef(null);
  const inputHistoricoRef = useRef(null);
  const [isOpenToast, setIsOpenToast] = useState(false);
  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Histórico",
    numeroAba: 0,
  });

  const [optionsContribuinte, setOptionsContribuintes] = useState([
    { id: "", name: "", cpf: "" },
  ]);
  const [optionsContaBancariaCaixa, setOptionsContaBancariaCaixa] = useState([
    { id: "", nameConta: "", agencia: "", conta: "" },
  ]);

  const [optionsQdrde, setOptionsQdrde] = useState([
    {
      id: "",
      titulo: "",
      funcao: "",
      qdrdeIsTranferencia: false,
      fontes: [{ fonteId: null, valor: "", data: "", label: "" }],
    },
  ]);

  const [optionsFonteDeRecursos, setOptionsFonteDeRecurso] = useState([
    { label: "", value: null },
  ]);

  const [optionsUnidadesGestoras, setOptionsUnidadesGestoras] = useState([
    { label: "", value: null, tipoNaturezaJuridica: "" },
  ]);

  const [optionsTipoTransferencia, setOptionsTipoTranferencias] =
    useState(optionsTranferencias);

  const [infosCadastro, setInfosCadastro] = useState({
    clienteId: currentClient.clienteId,
    num: "",
    evb: "",
    qdrdeId: null,
    qdrdeIsTranferencia: false,
    funcao: "",
    data: currentDate(),
    remessa: "",
    cpfContribuinteId: null,
    infoCaixaId: null,
    docFinanceiro: "",
    valor: 0,
    tipoTransferencia: "",
    historico: "",
    unidadeGestoraId: "",
    tipoNaturezaJuridica: "",
    fontes: [{ fonteId: null, valor: "", data: "", label: "" }],
  });

  const [inputContribuinte, setInputContribuinte] = useState("");
  const [inputContaBancaria, setInputContaBancaria] = useState("");
  const [inputQdrde, setInputQdrde] = useState("");
  const [inputUnidadeGestoraValue, setInputUnidadeGestoraValue] =
    useState(null);
  const [inputTipoTransferenciaValue, setInputTipoTransferenciaValue] =
    useState(null);

  const handleSelectQdrde = (option) => {
    const { fontes, funcao, qdrdeIsTranferencia } = option;
    const optionsFontes = formattedOptionsFonteDeRecursos(option.fontes);
    setOptionsFonteDeRecurso(optionsFontes);
    setInfosCadastro((prev) => ({
      ...prev,
      qdrdeId: option.id,
      funcao,
      fontes,
      qdrdeIsTranferencia,
    }));
    if (
      !qdrdeIsTranferencia &&
      abaSelecionada.nomeAba === "Transferência Recebida"
    )
      setAbaSelecionada({ nomeAba: "Histórico", numeroAba: 0 });

    inputDateRef?.current.focus();
  };

  const handleSelectContribuinte = (optionContrubuinte) => {
    setInfosCadastro((prev) => ({
      ...prev,
      cpfContribuinteId: optionContrubuinte.id,
    }));
    inputCaixaRef?.current.focus();
  };

  const handleSelectContaBancaria = (optionContaBancaria) => {
    setInfosCadastro((prev) => ({
      ...prev,
      infoCaixaId: optionContaBancaria.id,
    }));
    inputDocFinanceiroRef?.current.focus();
  };

  const handleChangeValorReceita = (_, valor) => {
    const MAX_LENGTH_VALUE = 16;
    const truncatedValue = valor.slice(0, MAX_LENGTH_VALUE);
    const fontesDeRecursos = infosCadastro.fontes.filter(
      (item) => item.fonteId !== null
    );

    if (fontesDeRecursos.length === 1) {
      const fontes = fontesDeRecursos.map((item) => {
        return {
          ...item,
          valor: truncatedValue,
        };
      });

      setInfosCadastro((prevState) => ({
        ...prevState,
        fontes,
        valor: truncatedValue,
      }));

      return;
    }

    setInfosCadastro((prev) => ({
      ...prev,
      valor: truncatedValue,
    }));
  };

  const validatedValuesRegister = () => {
    const infosPostGre = {
      clienteId: infosCadastro.clienteId,
      qdrdeId: infosCadastro.qdrdeId,
      data: formatDateToISO(infosCadastro.data),
      contribuinteId: infosCadastro.cpfContribuinteId,
      caixaId: infosCadastro.infoCaixaId,
      docFinanceiro: infosCadastro.docFinanceiro,
      valor: infosCadastro.valor,
      historico: infosCadastro.historico,
      fontes: infosCadastro.fontes,
    };

    const emptyInfosCadastro = Object.values(infosPostGre).some(
      (info) => info === "" || info === "0,00" || info === 0
    );
    if (emptyInfosCadastro) {
      toast.info("Preencha todas as informações");
      return;
    }

    const isValidDescription = validateDescription(infosPostGre.historico);

    if (!isValidDescription) {
      return;
    }

    const date = currentDate();
    const isValidDate = checkIsValideData(infosPostGre.data, date);

    if (!isValidDate && (!paramsIdGreReceita || !!hasRouteReplica)) {
      toast.info("Informe uma data válida.");
      return;
    }

    const hasFonteRecurso = infosPostGre.fontes.every((fonte) => fonte.fonteId);

    if (!hasFonteRecurso) {
      toast.info("Adicione uma Fonte de Recursos.");
      return;
    }

    const formattedFonteRecurso = infosPostGre.fontes.map((fonte) => {
      return {
        fonteId: fonte.fonteId,
        valor: fonte.valor.replace(/,/g, "."),
      };
    });

    const completeWithZerosDocFinanceiro = String(
      infosCadastro.docFinanceiro
    ).padEnd(11, "0");

    const infos = {
      ...infosPostGre,
      docFinanceiro: completeWithZerosDocFinanceiro,
      fontes: formattedFonteRecurso,
      valor: infosPostGre.valor.replace(/,/g, "."),
      tpTransferencia: infosCadastro.qdrdeIsTranferencia
        ? infosCadastro.tipoTransferencia
        : "",
      unidadeGestoraId: infosCadastro.qdrdeIsTranferencia
        ? infosCadastro.unidadeGestoraId
        : "",
    };

    return infos;
  };

  const handleSave = () => {
    const infosGre = validatedValuesRegister();

    if (!infosGre) return;

    setIsOpenToast(true);
  };

  const saveRegisterToast = async () => {
    const infosGre = validatedValuesRegister();

    if (!infosGre) return;
    if (!paramsIdGreReceita || hasRouteReplica) {
      await postInfosReceita(infosGre);
      return;
    }

    await putInfosReceita(infosGre);
  };

  const resetFieldsGreReceita = () => {
    console.log(infosCadastro);

    const resetInfosCadastro = {
      clienteId: currentClient.clienteId,
      num: "",
      evb: "",
      qdrdeId: null,
      qdrdeIsTranferencia: false,
      funcao: "",
      data: currentDate(),
      remessa: "",
      cpfContribuinteId: null,
      infoCaixaId: null,
      docFinanceiro: "",
      valor: "0,00",
      tipoTransferencia: "",
      historico: "",
      unidadeGestoraId: "",
      tipoNaturezaJuridica: "",
      fontes: [{ fonteId: null, valor: "", data: "", label: "" }],
    };
    setInfosCadastro(resetInfosCadastro);
    setInputQdrde("");
    setInputContribuinte("");
    setInputContaBancaria("");
    setInputTipoTransferenciaValue(null);
    setInputUnidadeGestoraValue(null);
  };

  const postInfosReceita = async (postInfos) => {
    const successsResponse = await GreService.postGre(postInfos);

    if (!successsResponse) {
      toast.error("Erro ao criar o cadastro. Tente novamente mais tarde.");
      return;
    }

    resetFieldsGreReceita();
    toast.success("Cadastro realizado com sucesso.");
  };

  const putInfosReceita = async (putInfos) => {
    const successsResponse = await GreService.putGre(
      paramsIdGreReceita,
      putInfos
    );
    if (!successsResponse) {
      toast.error("Erro ao atualizar registro. Tente novamente mais tarde.");
      return;
    }
    toast.success("Registro atualizado com sucesso");
  };

  const formattedOptionsFonteDeRecursos = (fonts) => {
    const filteredFonts = fonts.filter((item) => item.fonteId !== null);

    if (!filteredFonts.length) return [{ label: "", value: null }];

    const formattedFonts = filteredFonts.map((item) => {
      return {
        label: item.label,
        value: item.fonteId,
      };
    });
    return formattedFonts;
  };

  const formattedFonts = (data) => {
    const fonts = data.map((item) => {
      const fontInfo = item.fonteInfo.find((font) => font.id === item.fonteId);
      const date = new Date(item.dataCriacao);
      return {
        data: date.toISOString().split("T")[0],
        fonteId: item.fonteId,
        valor: item.valor,
        label: fontInfo ? `${fontInfo.conta} - ${fontInfo.titulo}` : "",
      };
    });
    return fonts;
  };

  const onChangeTextDocFinanceiro = (value) => {
    const inputValue = value.replace(/\D/g, "");
    const maskedValue = inputValue.slice(0, 11);
    setInfosCadastro((prevState) => ({
      ...prevState,
      docFinanceiro: maskedValue,
    }));
  };

  const getDataQdrde = useCallback(async () => {
    const dataQdrde = await QdrdeService.getAllQdrde(
      currentClient.clienteId,
      currentYear
    );

    if (!dataQdrde) {
      toast.error("Erro ao buscar dados. Tente novamente mais tarde.");
      return;
    }

    const formattedDataQdrde = dataQdrde.map((item) => {
      const titulo = `${item.categoriaContaPcaspInfo[0].conta} - ${item.titulo}`;
      const fontes = formattedFonts(item.fontes);
      const qdrdeIsTranferencia = ACCOUNTS_TYPE_TRANSFER_QDRDE.includes(
        item.categoriaContaPcaspInfo[0].conta
      );
      return {
        id: item.id,
        titulo,
        funcao: item.categoriaContaPcaspInfo[0].funcao,
        fontes,
        qdrdeIsTranferencia,
      };
    });
    setOptionsQdrde(formattedDataQdrde);
  }, [currentClient, currentYear]);

  const fetchOptionsContribuinte = useCallback(async () => {
    const dataCpf = await CpfClientService.getAllCpfsCliente(
      currentClient.clienteId
    );

    if (!dataCpf.length) {
      toast.error("Erro ao buscar dados. Tente novamente mais tarde.");
      return;
    }

    const optionsCpf = dataCpf.map((item) => {
      return {
        id: item.pessoaId,
        name: item.nomeCompleto,
        cpf: FormatCpfPaste(item.cpf),
      };
    });

    setOptionsContribuintes(optionsCpf);
  }, [currentClient]);

  const fetchContas = useCallback(async () => {
    const dataContaBancaria =
      await ContaBancariaService.getAllByClientIdContaBancaria(
        currentClient.clienteId,
        currentYear
      );

    if (!dataContaBancaria) {
      toast.error("Erro ao buscar dados. Tente novamente mais tarde.");
      return;
    }

    const optionsContaBancaria = dataContaBancaria.map((item) => {
      return {
        id: item.id,
        nameConta: item.nome,
        agencia: item.numeroConta,
        conta: formatLabelContaBancaria(item.numeroConta, item.digito),
      };
    });
    setOptionsContaBancariaCaixa(optionsContaBancaria);
  }, [currentClient, currentYear]);

  const formattedUnidadesGestoras = useCallback(() => {
    const unidades = userAuthData.clientes
      .map((item) => {
        const hasCityUnidade =
          item.endereco.municipio === currentCity.municipio &&
          item.endereco.estado === currentCity.estado;
        const currentClientSelected =
          currentClient.clienteId === item.clienteId;

        if (!hasCityUnidade || currentClientSelected)
          return { label: "", value: null };
        return {
          label: item.nomeFantasia,
          value: item.clienteId,
          tipoNaturezaJuridica: item.tipoNaturezaJuridica,
        };
      })
      .filter((item) => item.value);

    setOptionsUnidadesGestoras(unidades);
  }, [currentCity, userAuthData, currentClient]);

  // const formattedGreReceitaById = (data) => {
  //   console.log(optionsQdrde);

  //   const infosContaQdrde = optionsQdrde.find(
  //     (option) => option.id === data.gre.qdrdeId
  //   );
  //   console.log(infosContaQdrde);

  //   if (!infosContaQdrde) {
  //     toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
  //   }

  //   const infosContribuinte = optionsContribuinte.find(
  //     (option) => option.id === data.gre.contribuinteId
  //   );

  //   if (!infosContribuinte) {
  //     toast.error("Erro ao buscar os dados CONTRIBUINTE. Tente novamente mais tarde.");
  //   }
  //   console.log(infosContribuinte);

  //   const infosCaixa = optionsContaBancariaCaixa.find(
  //     (option) => option.id === data.gre.caixaId
  //   );

  //   if (!infosCaixa) {
  //     toast.error("Erro ao buscar os dados CAIXA. Tente novamente mais tarde.");
  //   }
  //   console.log(infosCaixa);

  //   const unidadeGestora = optionsUnidadesGestoras.find(
  //     (unidade) => unidade.value === data.gre.unidadeGestoraId
  //   );

  //   console.log(unidadeGestora);

  //   if (!unidadeGestora && data.gre.unidadeGestoraId !== null) {
  //     toast.error("Erro ao buscar os dados UNIDADE GESTORA. Tente novamente mais tarde.");
  //     return;
  //   }

  //   const date = data.gre.data.split("T")[0];
  //   const [year, month, day] = date.split("-");
  //   const dateFormatted = `${day}/${month}/${year}`;

  //   const formattedFonteRecurso = data.gre.fontes
  //     .map((fonte) => {
  //       const date = fonte.dataCriacao.split("T")[0];
  //       const [year, month, day] = date.split("-");
  //       const dateFormatted = `${day}/${month}/${year}`;

  //       const fonteInfos = fonte.fonteInfo.map((fonteInfo) => {
  //         return {
  //           fonteId: fonteInfo.id,
  //           valor: fonte.valor,
  //           data: dateFormatted,
  //           label: `${fonteInfo.conta} - ${fonteInfo.titulo}`,
  //           percentage: fonte.percentual,
  //         };
  //       });
  //       return fonteInfos;
  //     })
  //     .flat();

  //   const infosGreReceita = {
  //     clienteId: data.gre.clienteId,
  //     num: data.gre.id,
  //     evb: "",
  //     qdrdeId: data.gre.qdrdeId,
  //     qdrdeIsTranferencia: infosContaQdrde.qdrdeIsTranferencia,
  //     funcao: infosContaQdrde.funcao,
  //     data: dateFormatted,
  //     remessa: "",
  //     cpfContribuinteId: data.gre.contribuinteId,
  //     infoCaixaId: data.gre.caixaId,
  //     docFinanceiro: data.gre.docFinanceiro,
  //     valor: data.gre.valor,
  //     tipoTransferencia: data.gre.tpTransferencia ?? "",
  //     historico: data.gre.historico,
  //     unidadeGestoraId: data.gre.unidadeGestoraId ?? "",
  //     tipoNaturezaJuridica: unidadeGestora?.tipoNaturezaJuridica ?? "",
  //     fontes: formattedFonteRecurso,
  //   };
  //   console.log(infosGreReceita);
  //   const fonteOptions = formattedOptionsFonteDeRecursos(
  //     infosGreReceita.fontes
  //   );
  //   const filterOptionsTipoTranferencia = optionsTipoTransferencia.filter(
  //     (option) => option.label === data.gre.tpTransferencia
  //   );
  //   setOptionsFonteDeRecurso(fonteOptions);
  //   setInfosCadastro(infosGreReceita);
  //   setInputQdrde(`${infosContaQdrde.titulo}`);
  //   setInputContribuinte(
  //     `${infosContribuinte.cpf} - ${infosContribuinte.name}`
  //   );
  //   setInputContaBancaria(`${infosCaixa.nameConta} - ${infosCaixa.agencia}`);
  //   setInputUnidadeGestoraValue(unidadeGestora.value);
  //   setOptionsTipoTranferencias(
  //     filterOptionsTipoTranferencia.length
  //       ? filterOptionsTipoTranferencia
  //       : optionsTipoTransferencia
  //   );
  //   setInputTipoTransferenciaValue(parseInt(data.gre.tpTransferencia[0]) ?? "");
  // };

  const fetchGreReceitaById = useCallback(
    async (
      idGre,
      listOptionsQdrde,
      listOptionsContribuinte,
      listOptionsCaixa,
      listOptionsUnidadeGestora
    ) => {
      const dataGre = await GreService.getByIdGre(idGre);

      if (!dataGre) {
        toast.error(
          "Erro ao buscar os dados do GRE. Tente novamente mais tarde."
        );
        return;
      }

      formattedGreReceitaById(
        dataGre,
        listOptionsQdrde,
        listOptionsContribuinte,
        listOptionsCaixa,
        listOptionsUnidadeGestora
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const formattedGreReceitaById = (
    dataGre,
    listOptionsQdrde,
    listOptionsContribuinte,
    listOptionsCaixa,
    listOptionsUnidadeGestora
  ) => {
    const infosContaQdrde = listOptionsQdrde.find(
      (option) => option.id === dataGre.gre.qdrdeId
    );

    const infosContribuinte = listOptionsContribuinte.find(
      (option) => option.id === dataGre.gre.contribuinteId
    );

    const infosCaixa = listOptionsCaixa.find(
      (option) => option.id === dataGre.gre.caixaId
    );

    const unidadeGestora = listOptionsUnidadeGestora.find(
      (unidade) => unidade.value === dataGre.gre.unidadeGestoraId
    );

    const date = dataGre.gre.data.split("T")[0];
    const [year, month, day] = date.split("-");
    const dateFormatted = `${day}/${month}/${year}`;

    const formattedFonteRecurso = dataGre.gre.fontes
      .map((fonte) => {
        const date = fonte.dataCriacao.split("T")[0];
        const [year, month, day] = date.split("-");
        const dateFormatted = `${day}/${month}/${year}`;

        const fonteInfos = fonte.fonteInfo.map((fonteInfo) => {
          return {
            fonteId: fonteInfo.id,
            valor: hasRouteReplica ? "0,00" : fonte.valor,
            data: dateFormatted,
            label: `${fonteInfo.conta} - ${fonteInfo.titulo}`,
          };
        });
        return fonteInfos;
      })
      .flat();

    const infosGreReceita = {
      clienteId: dataGre.gre.clienteId,
      num: hasRouteReplica ? "" : dataGre.gre.numero,
      evb: "",
      qdrdeId: dataGre.gre.qdrdeId,
      qdrdeIsTranferencia: infosContaQdrde.qdrdeIsTranferencia,
      funcao: infosContaQdrde.funcao,
      data: hasRouteReplica ? currentDate() : dateFormatted,
      remessa: "",
      cpfContribuinteId: dataGre.gre.contribuinteId,
      infoCaixaId: dataGre.gre.caixaId,
      docFinanceiro: dataGre.gre.docFinanceiro,
      valor: hasRouteReplica ? "0,00" : dataGre.gre.valor,
      tipoTransferencia: dataGre.gre.tpTransferencia ?? "",
      historico: dataGre.gre.historico,
      unidadeGestoraId: dataGre.gre.unidadeGestoraId ?? "",
      tipoNaturezaJuridica: unidadeGestora?.tipoNaturezaJuridica ?? "",
      fontes: formattedFonteRecurso,
    };

    const fonteOptions = formattedOptionsFonteDeRecursos(
      infosGreReceita.fontes
    );
    const filterOptionsTipoTranferencia = optionsTipoTransferencia.filter(
      (option) => option.label === dataGre.gre.tpTransferencia
    );
    setOptionsFonteDeRecurso(fonteOptions);
    setInfosCadastro(infosGreReceita);
    setInputQdrde(`${infosContaQdrde.titulo}`);
    setInputContribuinte(
      `${infosContribuinte.cpf} - ${infosContribuinte.name}`
    );
    setInputContaBancaria(`${infosCaixa.nameConta} - ${infosCaixa.conta}`);
    setInputUnidadeGestoraValue(unidadeGestora?.value ?? "");
    setOptionsTipoTranferencias(
      filterOptionsTipoTranferencia.length
        ? filterOptionsTipoTranferencia
        : optionsTipoTransferencia
    );
    setInputTipoTransferenciaValue(
      dataGre.gre?.tpTransferencia
        ? parseInt(dataGre.gre.tpTransferencia[0]) || ""
        : ""
    );
  };

  // const fetchGreReceitaById = useCallback(async () => {
  //   console.log("oi");

  //   try {
  //     const resp = await api.get(`gre/${paramsIdGreReceita}`);
  //     const data = resp.data;
  //     const hasOptionsQdrde = optionsQdrde.filter((item) => item.id).length;
  //     const hasOptionsContribuinte = optionsContribuinte.filter(
  //       (item) => item.id
  //     ).length;
  //     const hasOptionsCaixa = optionsContaBancariaCaixa.filter(
  //       (item) => item.id
  //     ).length;
  //     if (hasOptionsQdrde && hasOptionsCaixa && hasOptionsContribuinte) {
  //       console.log('entrou');

  //       formattedGreReceitaById(data);
  //     }
  //     console.log(data);
  //   } catch (error) {
  //     console.log(error);

  //     toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   optionsContaBancariaCaixa,
  //   optionsContribuinte,
  //   optionsQdrde,
  //   paramsIdGreReceita,
  // ]);

  const handleKeyDown = async (e, nextRef) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      handleSave();
      return;
    }

    if (e.key === "Enter") {
      if (nextRef && nextRef.current) {
        e.preventDefault();
        nextRef.current.focus();
      }
    }
  };

  useEffect(() => {
    formattedUnidadesGestoras();
  }, [formattedUnidadesGestoras]);

  useEffect(() => {
    fetchOptionsContribuinte();
    fetchContas();
    getDataQdrde();
  }, [fetchContas, fetchOptionsContribuinte, getDataQdrde]);

  useEffect(() => {
    const listOptionsQdrde = optionsQdrde.filter((option) => option.id);
    const listOptionsContribuinte = optionsContribuinte.filter(
      (option) => option.id
    );
    const listOptionsCaixa = optionsContaBancariaCaixa.filter(
      (option) => option.id
    );
    const listOptionsUnidadeGestora = optionsUnidadesGestoras.filter(
      (option) => option.value
    );

    if (
      paramsIdGreReceita &&
      listOptionsQdrde.length &&
      listOptionsContribuinte.length &&
      listOptionsCaixa.length &&
      listOptionsUnidadeGestora.length
    ) {
      fetchGreReceitaById(
        paramsIdGreReceita,
        listOptionsQdrde,
        listOptionsContribuinte,
        listOptionsCaixa,
        listOptionsUnidadeGestora
      );
    }
  }, [
    fetchGreReceitaById,
    optionsContaBancariaCaixa,
    optionsContribuinte,
    optionsQdrde,
    optionsUnidadesGestoras,
    paramsIdGreReceita,
  ]);

  useEffect(() => {
    if (paramsIdGreReceita && !hasRouteReplica) return;

    if (hasRouteReplica) {
      inputValorRef.current.disabled = false;
      inputValorRef?.current.focus();
      return;
    }

    inputQdrdeRef?.current.focus();
  }, [hasRouteReplica, paramsIdGreReceita]);

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"GRE - Receita"}
        PaginaSubtitulo={"Cadastro"}
        RowTitle="Receita Extraorçamentária - GRE"
        ButtonSaveFunction={handleSave}
        PaginaConsulta={"/contabilidade/extra/gre-receita/consulta"}
        BotaoNovoAtivo={false}
      />
      <ToastContainer />
      <CustomToast
        isOpenToast={isOpenToast}
        setIsOpenToast={setIsOpenToast}
        saveRegister={saveRegisterToast}
      />
      <RowForm className="mt-1">
        <CColNumero md={1}>
          <FloatingLabelInput
            disabled
            placeholder="Número"
            label="Número"
            value={infosCadastro.num}
          />
        </CColNumero>
        <CCol md={2}>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="EVB"
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol>
          <MyAutoComplete
            ref={inputQdrdeRef}
            style={{ height: "37px" }}
            labelInput="QDRDE"
            options={optionsQdrde}
            labelFormat={(item) => `${item.titulo} `}
            inputValue={inputQdrde}
            setInputValue={setInputQdrde}
            onOptionSelect={handleSelectQdrde}
          />
        </CCol>
        <CCol md={2}>
          <InputData
            ref={inputDateRef}
            label="Data"
            onChange={(e) =>
              setInfosCadastro((prev) => ({
                ...prev,
                data: e.target.value,
              }))
            }
            externalValue={infosCadastro.data}
            onKeyDown={(e) => handleKeyDown(e, inputContribuinteRef)}
          />
        </CCol>
      </RowForm>

      {infosCadastro.funcao && (
        <RowInfosAcao>
          <CCol>
            <p>
              <span>{infosCadastro.funcao}</span>
            </p>
          </CCol>
        </RowInfosAcao>
      )}

      <RowForm style={{ marginTop: "-14px" }}>
        <CColNumero md={1}>
          <FloatingLabelInput disabled placeholder="Remessa" label="Remessa" />
        </CColNumero>
        <CCol>
          <MyAutoComplete
            ref={inputContribuinteRef}
            style={{ height: "37px" }}
            labelInput="Contribuinte"
            options={optionsContribuinte}
            labelFormat={(item) => `${item.cpf} - ${item.name}`}
            inputValue={inputContribuinte}
            setInputValue={setInputContribuinte}
            onOptionSelect={handleSelectContribuinte}
          />
        </CCol>
      </RowForm>

      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <MyAutoComplete
            ref={inputCaixaRef}
            style={{ height: "37px" }}
            labelInput="Caixa e Equivalente Caixa"
            options={optionsContaBancariaCaixa}
            labelFormat={(item) => `${item.nameConta} - ${item.conta}`}
            inputValue={inputContaBancaria}
            setInputValue={setInputContaBancaria}
            onOptionSelect={handleSelectContaBancaria}
          />
        </CCol>

        <CCol md={2}>
          <FloatingLabelInput
            ref={inputDocFinanceiroRef}
            type="text"
            label="Doc Financeiro"
            placeholder="Doc Financeiro"
            value={infosCadastro.docFinanceiro}
            onChange={(e) => onChangeTextDocFinanceiro(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, inputValorRef)}
            max={11}
          />
        </CCol>

        <CCol md={2}>
          <InputFormatRealBrasileiro
            ref={inputValorRef}
            externalValue={infosCadastro.valor}
            disabled={infosCadastro.qdrdeId === null}
            onChange={handleChangeValorReceita}
            iconReal
            placeholder="0,00"
            max={16}
            label={infosCadastro.valor ? "Valor" : ""}
            onKeyDown={(e) => handleKeyDown(e, inputHistoricoRef)}
          />
        </CCol>
      </RowForm>

      <RowSelecaoAbasInferior
        abas={[
          "Histórico",
          "Fonte de Recursos",
          `${
            infosCadastro.qdrdeIsTranferencia ? "Transferência Recebida" : ""
          }`,
          "Retenção",
          "Procedimentos Contábeis",
        ]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      />

      {abaSelecionada.nomeAba === "Histórico" && (
        <HistoricoAba
          ref={inputHistoricoRef}
          infosCadastro={infosCadastro}
          setInfosCadastro={setInfosCadastro}
          handleKeyDown={handleKeyDown}
        />
      )}
      {abaSelecionada.nomeAba === "Fonte de Recursos" && (
        <FonteDeRecursosAba
          infosCadastro={infosCadastro}
          setInfosCadastro={setInfosCadastro}
          optionsFonteDeRecursos={optionsFonteDeRecursos}
        />
      )}
      {abaSelecionada.nomeAba === "Transferência Recebida" && (
        <TransferenciaRecebidaAba
          optionsUnidadesGestoras={optionsUnidadesGestoras}
          // handleSelectTipoTransferencia={handleSelectTipoTransferencia}
          // handleSelectUnidadeGestora={handleSelectUnidadeGestora}
          infosCadastro={infosCadastro}
          setInfosCadastro={setInfosCadastro}
          currentClient={currentClient}
          inputUnidadeGestoraValue={inputUnidadeGestoraValue}
          setInputUnidadeGestoraValue={setInputUnidadeGestoraValue}
          inputTipoTransferenciaValue={inputTipoTransferenciaValue}
          setInputTipoTransferenciaValue={setInputTipoTransferenciaValue}
          optionsTipoTransferencia={optionsTipoTransferencia}
          setOptionsTipoTranferencias={setOptionsTipoTranferencias}
          // handleOptionUnidadeGestora={handleOptionUnidadeGestora}
        />
      )}
      {abaSelecionada.nomeAba === "Retenção" && <RetencaoAba />}
      {abaSelecionada.nomeAba === "Procedimentos Contábeis" && (
        <ProcedimentoContabel />
      )}
    </Container>
  );
};

export default GreReceita;
