import React from "react";
import { Icon } from "@iconify/react";
import { DivAction } from "../../components/TableConsulta/TableConsulta";
import { NavLink } from "react-router-dom";

const DivActionFunc = ({
  buttonOrder = ["i", "e", "r", "a", "s", "d"],
  FuncaoIconDelete,
  FuncaoIconPrint,
  CaminhoPagUpdate,
  IconDelete,
  CaminhoPagReplica,
  CaminhoPagAnulacao,
  CaminhoPagSubstituir,
  onClick,
  deactivated,
}) => {
  const buttonMap = {
    i: FuncaoIconPrint && (
      <span title={"imprimir"}>
        <Icon
          icon="fa-solid:print"
          height="20"
          style={{
            marginBottom: "8px",
            marginTop: "-2px",
            cursor: "pointer",
          }}
          onClick={FuncaoIconPrint}
        />
      </span>
    ),
    e: CaminhoPagUpdate && (
      <NavLink
        title={"editar"}
        to={!deactivated ? CaminhoPagUpdate : () => console.log("Desativado")}
        style={{ cursor: deactivated ? "default" : "pointer" }}
      >
        <Icon
          icon="bx:edit"
          color={!deactivated ? "#5971C8" : "#b6b6b6"}
          height="22"
          style={{
            marginBottom: "8px",
            marginTop: "-2px",
            marginLeft: "1px",
          }}
          onClick={!deactivated ? onClick : () => console.log("Desativado")}
        />
      </NavLink>
    ),
    r: CaminhoPagReplica && (
      <NavLink title={"replicar"} to={CaminhoPagReplica}>
        <Icon
          icon="gg:copy"
          color="#3E347B"
          height="22"
          style={{
            marginBottom: "10px",
            marginTop: "-2px",
            cursor: "pointer",
            marginLeft: "-1px",
          }}
        />
      </NavLink>
    ),
    a: CaminhoPagAnulacao && (
      <NavLink title={"anular"} to={CaminhoPagAnulacao}>
        <Icon
          icon="fluent:document-dismiss-16-regular"
          height="22"
          style={{
            color: "#f00",
            marginBottom: "10px",
            marginTop: "-2px",
            cursor: "pointer",
            marginLeft: "-1px",
          }}
        />
      </NavLink>
    ),
    s: CaminhoPagSubstituir && (
      <NavLink title={"Substituir"} to={CaminhoPagSubstituir}>
        <Icon
          icon="mdi:file-document-edit-outline"
          height="22"
          style={{
            color: "#f24e1e",
            marginBottom: "10px",
            marginTop: "-2px",
            cursor: "pointer",
            marginLeft: "-1px",
          }}
        />
      </NavLink>
    ),
    d: (IconDelete === undefined || IconDelete) && FuncaoIconDelete && (
      <span title={"deletar"}>
        <Icon
          icon="ic:baseline-delete"
          disabled
          color={typeof FuncaoIconDelete === "function" ? "#E79900" : "#f3cd7e"}
          height="22"
          style={{
            marginBottom: "8px",
            marginTop: "-2px",
            marginLeft: "-3px",
            cursor:
              typeof FuncaoIconDelete === "function" ? "pointer" : "default",
          }}
          onClick={FuncaoIconDelete}
        />
      </span>
    ),
  };

  return (
    <DivAction
      style={{
        display: "flex",
      }}
    >
      {buttonOrder.map((key) => (
        <div key={key} style={{ margin: "0 -2px" }}>
          {buttonMap[key]}
        </div>
      ))}
    </DivAction>
  );
};

const ButtonsAcoesListas = ({
  FuncaoIconDelete,
  FuncaoIconPrint,
  CaminhoPagUpdate,
  IconDelete,
  CaminhoPagReplica,
  CaminhoPagAnulacao,
  CaminhoPagSubstituir,
  onClick,
  deactivated,
  buttonOrder = ["i", "e", "r", "a", "s", "d"],
}) => {
  return DivActionFunc({
    buttonOrder,
    FuncaoIconDelete,
    FuncaoIconPrint,
    CaminhoPagUpdate,
    IconDelete,
    CaminhoPagReplica,
    CaminhoPagAnulacao,
    CaminhoPagSubstituir,
    onClick,
    deactivated,
  });
};

export default ButtonsAcoesListas;
