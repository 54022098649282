import api from "../../utils/api";

export const postRetencao = async (infosRetencao) => {
  try {
    await api.post('retencao',infosRetencao);
    return true;
  } catch (error) {
    console.log(error);
    
    return null;
  }
};
