import api from "../../utils/api";

export const postGde = async (infosGde) => {
  try {
    await api.post(`gde`, infosGde);
    return true;
  } catch (error) {
    return null;
  }
};
