import api from "../../utils/api";

export const getByIdGde = async (idGde) => {
  try {
    const resp = await api.get(`gde/${idGde}`);
    return resp.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
