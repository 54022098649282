import { toast } from "react-toastify";

const MIN_LENGTH_DESCRIPTION = 10;
const MAX_LENGTH_DESCRIPTION = 500;
export const validateDescription = (
  text,
  minLength = MIN_LENGTH_DESCRIPTION,
  maxLength = MAX_LENGTH_DESCRIPTION
) => {
  const isValidDescription =
    text.length >= minLength && text.length <= maxLength;

  if (!isValidDescription) {
    toast.info(
      `A descrição deve conter de ${minLength} a ${maxLength} caracteres.`
    );
  }

  return isValidDescription;
};
