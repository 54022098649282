import { colors } from "../../../styles/colors";

export const IconReplica = ({ title = "Replicar", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="22"
      viewBox="0 0 24 24"
      {...props}
    >
      <title>{title}</title>
      <g fill={colors["icon-replica"]}>
        <path d="M13 7H7V5h6zm0 4H7V9h6zm-6 4h6v-2H7z"></path>
        <path
          fillRule="evenodd"
          d="M3 19V1h14v4h4v18H7v-4zm12-2V3H5v14zm2-10v12H9v2h10V7z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>

  );
};
