import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Container } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";

import styled from "styled-components";

import { CCol, RowForm } from "../../../../components/Grid/CCol";
import InputData from "../../../../components/Grid/InputData";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import { ProcedimentoContabel } from "../../Components/ProcedimentoContabel";
import { CustomToast } from "../../../../components/CustomToast";

import { HistoricoAba } from "./HistoricoAba";
import { ReceitaExtraAba } from "./ReceitaExtraAba";
import { TransfConcedidaAba } from "./TransfConcedidaAba";
import { FonteDeRecursosAba } from "./FonteDeRecursosAba";
import { optionsTranferencias } from "../GreReceita/optionsTranferenciasRecebidas";
import { ACCOUNTS_TYPE_TRANSFER_QDRDE } from "../QDRDE/accountsPCASP";

import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import { CurrentCityContext } from "../../../../contexts/CurrentCityContext";

import { useCurrentYear } from "../../../../hooks/useCurrentYear";

import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";
import { validateDescription } from "../../../../utils/Gerais/validateDescription";

import { GdeService } from "../../../../services/gde";
import { QdrdeService } from "../../../../services/qdrde";
import { CpfClientService } from "../../../../services/cpfCliente";
import { checkIsValideData } from "../../../../utils/FormatacaoDeDados/FormatDateToRegister";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";

const CColNumero = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;
const GdeDespesa = () => {
  const { id: paramsIdGdeDespesa } = useParams();
  const location = useLocation();
  const hasRouteReplica = location.pathname.includes("replicar");

  const currentDate = () => {
    const date = new Date();
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");

    return `${day}/${month}/${currentYear}`;
  };

  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useCurrentYear();
  const { userAuthData } = useContext(AuthContext);
  const { currentCity } = useContext(CurrentCityContext);

  const inputQdrdeRef = useRef(null);
  const inputDateRef = useRef(null);
  const inputBeneficiarioRef = useRef(null);
  const inputValueRef = useRef(null);
  const inputHistoricoRef = useRef(null);

  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Histórico",
    numeroAba: 0,
  });
  const [isOpenToast, setIsOpenToast] = useState(false);

  const [inputQdrde, setInputQdrde] = useState("");
  const [inputBeneficiario, setInputBeneficiario] = useState("");
  const [inputExercicio, setInputExercicio] = useState("");
  const [inputGuiaReceita, setInputGuiaReceita] = useState("");
  const [inputUnidadeGestoraValue, setInputUnidadeGestoraValue] =
    useState(null);
  const [inputTipoTransferenciaValue, setInputTipoTransferenciaValue] =
    useState(null);

  const [infosAddReceitaExtra, setInfosAddReceitaExtra] = useState({
    exercicio: "",
    guiaReceitaId: "",
    saldo: "",
    label: "",
    numero: "",
  });

  const [optionsBeneficiario, setOptionsBeneficiario] = useState([
    { id: "", name: "", cpf: "" },
  ]);

  const [optionsUnidadesGestoras, setOptionsUnidadesGestoras] = useState([
    { label: "", value: null, tipoNaturezaJuridica: "" },
  ]);

  const [optionsQdrde, setOptionsQdrde] = useState([
    {
      id: "",
      titulo: "",
      funcao: "",
      qdrdeIsTranferencia: false,
    },
  ]);

  const [optionsTipoTransferencia, setOptionsTipoTranferencias] =
    useState(optionsTranferencias);

  const [infosCadastro, setInfosCadastro] = useState({
    clienteId: currentClient.clienteId,
    num: "",
    evb: "",
    qdrdeId: "",
    qdrdeIsTranferencia: false,
    funcao: "",
    data: "",
    beneficiarioId: "",
    valor: 0,
    historico: "",
    tipoTransferencia: "",
    unidadeGestoraId: "",
    tipoNaturezaJuridica: "",
    receitaExtra: [
      { exercicio: "", guiaReceitaId: "", saldo: "", label: "", numero: "" },
    ],
  });

  const handleSelectQdrde = (option) => {
    const { funcao, qdrdeIsTranferencia } = option;

    setInfosCadastro((prev) => ({
      ...prev,
      qdrdeId: option.id,
      funcao,
      qdrdeIsTranferencia,
      tipoNaturezaJuridica: "",
      unidadeGestoraId: "",
      tipoTransferencia: "",
      receitaExtra: [
        { exercicio: "", guiaReceitaId: "", label: "", saldo: "", numero: "" },
      ],
    }));
    setInputExercicio("");
    setInputGuiaReceita("");
    setInfosAddReceitaExtra({
      exercicio: "",
      guiaReceitaId: "",
      label: "",
      saldo: "0,00",
      numero: "",
    });
    if (
      !qdrdeIsTranferencia &&
      abaSelecionada.nomeAba === "Transferência Concedida"
    )
      setAbaSelecionada({ nomeAba: "Histórico", numeroAba: 0 });
    inputDateRef?.current.focus();
  };

  const handleClearQdrde = () => {
    setInputExercicio("");
    setInputGuiaReceita("");
    setInfosAddReceitaExtra({
      exercicio: "",
      guiaReceitaId: "",
      label: "",
      saldo: "0,00",
      numero: "",
    });
    if (infosCadastro.qdrdeIsTranferencia) {
      setAbaSelecionada({ nomeAba: "Histórico", numeroAba: 0 });
    }
    setInfosCadastro((prevState) => ({
      ...prevState,
      qdrdeId: "",
      qdrdeIsTranferencia: false,
      tipoNaturezaJuridica: "",
      unidadeGestoraId: "",
      tipoTransferencia: "",
      receitaExtra: [
        { exercicio: "", guiaReceitaId: "", label: "", saldo: "", numero: "" },
      ],
    }));
  };

  const handleSelectBeneficiario = (optionBeneficiario) => {
    setInfosCadastro((prev) => ({
      ...prev,
      beneficiarioId: optionBeneficiario.id,
    }));
    inputValueRef?.current.focus();
  };

  const handleChangeValor = (_, valor) => {
    const MAX_LENGTH_VALUE = 16;
    const truncatedValue = valor.slice(0, MAX_LENGTH_VALUE);

    setInfosCadastro((prevState) => ({
      ...prevState,
      valor: truncatedValue,
    }));
  };

  const handleKeyDown = async (e, nextRef) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      handleSave();
      return;
    }

    if (e.key === "Enter") {
      if (nextRef && nextRef.current) {
        e.preventDefault();
        nextRef.current.focus();
      }
    }
  };

  const validatedValuesRegister = () => {
    console.log(infosCadastro);

    const infosRegisterGde = {
      clienteId: infosCadastro.clienteId,
      qdrdeId: infosCadastro.qdrdeId,
      data: infosCadastro.data,
      beneficiarioId: infosCadastro.beneficiarioId,
      valor: infosCadastro.valor.replace(/,/g, "."),
      historico: infosCadastro.historico,
      receitas: infosCadastro.receitaExtra,
    };
    const hasInfosRegisterGde = Object.values(infosRegisterGde).every(
      (info) => info !== ""
    );

    if (!hasInfosRegisterGde) {
      toast.info("Preencha todas as informações");
      return;
    }

    const isValidDescription = validateDescription(infosRegisterGde.historico);

    if (!isValidDescription) {
      return;
    }

    const date = currentDate();
    const isValidDate = checkIsValideData(infosRegisterGde.data, date);

    if (!isValidDate && (!paramsIdGdeDespesa || !!hasRouteReplica)) {
      toast.info("Informe uma data válida.");
      return;
    }

    const hasGuiaReceita = infosRegisterGde.receitas.every(
      (info) => info.guiaReceitaId
    );

    if (!hasGuiaReceita) {
      toast.info("Adicione uma Guia de Receita.");
      return;
    }

    const totalReceitas = infosRegisterGde.receitas.reduce((acc, receita) => {
      return (acc += receita.saldo ? parseFloat(receita.saldo) : 0);
    }, 0);

    if (totalReceitas !== parseFloat(infosRegisterGde.valor)) {
      toast.info("O valor da despesa deve ter o total das guias de receita.");
      return;
    }

    const formattedInfosGuiaReceita = infosRegisterGde.receitas.map((item) => ({
      guiaId: item.guiaReceitaId,
      exercicio: item.exercicio,
      saldo: item.saldo,
    }));

    const infosGde = {
      ...infosRegisterGde,
      unidadeGestoraId: infosCadastro.qdrdeIsTranferencia
        ? infosCadastro.unidadeGestoraId
        : "",
      tpTransferencia: infosCadastro.qdrdeIsTranferencia
        ? infosCadastro.tipoTransferencia
        : "",
      receitas: formattedInfosGuiaReceita,
    };

    return infosGde;
  };

  const saveRegisterToast = async () => {
    const infosGde = validatedValuesRegister();

    if (!infosGde) return;
    if (!paramsIdGdeDespesa || hasRouteReplica) {
      await postInfosGde(infosGde);
      return;
    }

    await putInfosGde(infosGde);
  };

  const handleSave = () => {
    const infosGde = validatedValuesRegister();

    if (!infosGde) return;

    setIsOpenToast(true);
  };

  const resetFieldsInfosCadastro = () => {
    setInfosCadastro({
      clienteId: currentClient.clienteId,
      num: "",
      evb: "",
      qdrdeId: "",
      qdrdeIsTranferencia: false,
      funcao: "",
      data: "",
      beneficiarioId: "",
      valor: "0,00",
      historico: "",
      tipoTransferencia: "",
      unidadeGestoraId: "",
      tipoNaturezaJuridica: "",
      receitaExtra: [
        { exercicio: "", guiaReceitaId: "", saldo: "", label: "", numero: "" },
      ],
    });
    setInputQdrde("");
    setInputBeneficiario("");
    setInputExercicio("");
    setInputGuiaReceita("");
    setInfosAddReceitaExtra({
      exercicio: "",
      guiaReceitaId: "",
      saldo: "",
      label: "",
      numero: "",
    });
  };

  const postInfosGde = async (infos) => {
    const isSuccessResponse = await GdeService.postGde(infos);
    if (!isSuccessResponse) {
      toast.error("Erro ao criar o cadastro. Tente novamente mais tarde.");
      return;
    }

    resetFieldsInfosCadastro();
    toast.success("Cadastro realizado com sucesso.");
  };

  const putInfosGde = async (infos) => {
    console.log(infos);
  };

  const getDataQdrde = useCallback(async () => {
    const dataQdrde = await QdrdeService.getAllQdrde(
      currentClient.clienteId,
      currentYear
    );

    if (!dataQdrde) {
      toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
      return;
    }

    const formattedOptionsQdrde = dataQdrde.map((item) => {
      const titulo = `${item.categoriaContaPcaspInfo[0].conta} - ${item.titulo}`;

      const qdrdeIsTranferencia = ACCOUNTS_TYPE_TRANSFER_QDRDE.includes(
        item.categoriaContaPcaspInfo[0].conta
      );
      return {
        id: item.id,
        titulo,
        funcao: item.categoriaContaPcaspInfo[0].funcao,
        qdrdeIsTranferencia,
      };
    });

    setOptionsQdrde(formattedOptionsQdrde);
  }, [currentClient, currentYear]);

  const fetchOptionsBeneficiario = useCallback(async () => {
    const dataCpf = await CpfClientService.getAllCpfsCliente(
      currentClient.clienteId
    );

    if (!dataCpf) {
      toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
      return;
    }
    const optionsCpf = dataCpf.map((item) => {
      return {
        id: item.pessoaId,
        name: item.nomeCompleto,
        cpf: FormatCpfPaste(item.cpf),
      };
    });

    setOptionsBeneficiario(optionsCpf);
  }, [currentClient]);

  const formattedUnidadesGestoras = useCallback(() => {
    const unidades = userAuthData.clientes
      .map((item) => {
        const hasCityUnidade =
          item.endereco.municipio === currentCity.municipio &&
          item.endereco.estado === currentCity.estado;
        const currentClientSelected =
          currentClient.clienteId === item.clienteId;

        if (!hasCityUnidade || currentClientSelected)
          return { label: "", value: null };
        return {
          label: item.nomeFantasia,
          value: item.clienteId,
          tipoNaturezaJuridica: item.tipoNaturezaJuridica,
        };
      })
      .filter((item) => item.value);

    setOptionsUnidadesGestoras(unidades);
  }, [currentCity, userAuthData, currentClient]);

  const formattedGdeDespesaById = (
    dataGde,
    listOptionsQdrde,
    listOptionsUnidadeGestora
  ) => {
    const infosQdrde = listOptionsQdrde.find(
      (option) => option.id === dataGde.qdrdeId
    );

    if (!infosQdrde) {
      toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
      return;
    }

    const date = dataGde.data.split("T")[0];
    const [year, month, day] = date.split("-");
    const dateFormatted = `${day}/${month}/${year}`;

    const unidadeGestora = listOptionsUnidadeGestora.find(
      (unidade) => unidade.value === dataGde.unidadeGestoraId
    );

    if (!unidadeGestora && dataGde.unidadeGestoraId !== null) {
      toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
      return;
    }

    const formattedReceitaExtra = dataGde.receitas.map((receita) => ({
      exercicio: receita.exercicio,
      guiaReceitaId: receita.gdeId,
      saldo: receita.saldo,
      label: `${receita.greInfo.numero} - ${dataGde.qdrdeInfo.titulo}`,
      numero: receita.greInfo.numero,
    }));

    const infosGde = {
      clienteId: dataGde.clienteId,
      num: hasRouteReplica ? "" : dataGde.numero,
      evb: "",
      qdrdeId: dataGde.qdrdeId,
      qdrdeIsTranferencia: infosQdrde.qdrdeIsTranferencia,
      funcao: infosQdrde.funcao,
      data: hasRouteReplica ? currentDate() : dateFormatted,
      beneficiarioId: dataGde.beneficiarioId,
      valor: hasRouteReplica ? "0,00" : dataGde.valor,
      historico: hasRouteReplica ? "" : dataGde.historico,
      tipoTransferencia: dataGde.tpTransferencia ?? "",
      unidadeGestoraId: dataGde.unidadeGestoraId ?? "",
      unidadeGestora: unidadeGestora?.label ?? "",
      tipoNaturezaJuridica: unidadeGestora?.tipoNaturezaJuridica ?? "",
      receitaExtra: formattedReceitaExtra,
    };

    const filterOptionsTipoTranferencia = optionsTipoTransferencia.filter(
      (option) => option.label === dataGde.tpTransferencia
    );

    setInfosCadastro(infosGde);
    setInputQdrde(infosQdrde.titulo);
    setInputBeneficiario(
      `${dataGde.beneficiarioInfo.cpf} - ${dataGde.beneficiarioInfo.nomeCompleto}`
    );
    setInputUnidadeGestoraValue(unidadeGestora?.value);
    setOptionsTipoTranferencias(
      filterOptionsTipoTranferencia.length
        ? filterOptionsTipoTranferencia
        : optionsTipoTransferencia
    );
    setInputTipoTransferenciaValue(
      dataGde.tpTransferencia ? parseInt(dataGde.tpTransferencia[0]) || "" : ""
    );
  };

  const fetchDataGdeDespesaById = useCallback(
    async (idGde, listOptionsQdrde, listOptionsUnidadeGestora) => {
      const dataGde = await GdeService.getByIdGde(idGde);

      if (!dataGde) {
        toast.error("Erro ao buscar os dados. Tente novamente mais tarde.");
        return;
      }

      formattedGdeDespesaById(
        dataGde,
        listOptionsQdrde,
        listOptionsUnidadeGestora
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const listOptionsQdrde = optionsQdrde.filter((option) => option.id);
    const listOptionsUnidadeGestora = optionsUnidadesGestoras.filter(
      (option) => option.value
    );

    if (
      paramsIdGdeDespesa &&
      listOptionsQdrde.length &&
      listOptionsUnidadeGestora.length
    ) {
      fetchDataGdeDespesaById(
        paramsIdGdeDespesa,
        listOptionsQdrde,
        listOptionsUnidadeGestora
      );
    }
  }, [
    fetchDataGdeDespesaById,
    optionsQdrde,
    optionsUnidadesGestoras,
    paramsIdGdeDespesa,
  ]);

  useEffect(() => {
    formattedUnidadesGestoras();
  }, [formattedUnidadesGestoras]);

  useEffect(() => {
    getDataQdrde();
    fetchOptionsBeneficiario();
  }, [fetchOptionsBeneficiario, getDataQdrde]);

  useEffect(() => {
    if (paramsIdGdeDespesa) return;

    inputQdrdeRef?.current.focus();
  }, [paramsIdGdeDespesa]);

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"GDE - Despesa"}
        PaginaSubtitulo={"Cadastro"}
        RowTitle="Despesa Extraorçamentária - GDE"
        ButtonSaveFunction={handleSave}
        PaginaConsulta={"/contabilidade/extra/gde-despesa/consulta"}
        BotaoNovoAtivo={false}
      />
      <ToastContainer />
      <CustomToast
        isOpenToast={isOpenToast}
        setIsOpenToast={setIsOpenToast}
        saveRegister={saveRegisterToast}
      />
      <RowForm className="mt-1">
        <CColNumero md={1}>
          <FloatingLabelInput
            label="Número"
            placeholder="Número"
            disabled
            value={infosCadastro.num}
          />
        </CColNumero>
        <CCol md={2}>
          <MyAutoComplete
            options={[{ label: "teste", value: "teste" }]}
            labelFormat={(item) => `${item.label} - ${item.value}`}
            labelInput="EVB"
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol>
          <MyAutoComplete
            ref={inputQdrdeRef}
            style={{ height: "37px" }}
            labelInput="QDRDE"
            options={optionsQdrde}
            labelFormat={(item) => `${item.titulo} `}
            inputValue={inputQdrde}
            setInputValue={setInputQdrde}
            onOptionSelect={handleSelectQdrde}
            iconClearFunction={handleClearQdrde}
          />
        </CCol>
        <CCol md={2}>
          <InputData
            ref={inputDateRef}
            label="Data"
            onChange={(e) =>
              setInfosCadastro((prev) => ({
                ...prev,
                data: e.target.value,
              }))
            }
            externalValue={infosCadastro.data}
            onKeyDown={(e) => handleKeyDown(e, inputBeneficiarioRef)}
          />
        </CCol>
      </RowForm>

      {infosCadastro.funcao && (
        <RowInfosAcao>
          <CCol>
            <p>
              <span>{infosCadastro.funcao}</span>
            </p>
          </CCol>
        </RowInfosAcao>
      )}

      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <MyAutoComplete
            ref={inputBeneficiarioRef}
            style={{ height: "37px" }}
            labelInput="Beneficiário"
            options={optionsBeneficiario}
            labelFormat={(item) => `${item.cpf} - ${item.name}`}
            inputValue={inputBeneficiario}
            setInputValue={setInputBeneficiario}
            onOptionSelect={handleSelectBeneficiario}
          />
        </CCol>
        <CCol md={3}>
          <InputFormatRealBrasileiro
            ref={inputValueRef}
            iconReal
            onChange={handleChangeValor}
            externalValue={infosCadastro.valor}
            label="Valor"
            max={16}
            onKeyDown={(e) => handleKeyDown(e, inputHistoricoRef)}
          />
        </CCol>
      </RowForm>

      <RowSelecaoAbasInferior
        abas={[
          "Histórico",
          "Fonte de Recursos",
          "Receita Extra",
          `${
            infosCadastro.qdrdeIsTranferencia ? "Transferência Concedida" : ""
          }`,
          "Procedimentos Contábeis",
        ]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      />

      {abaSelecionada.nomeAba === "Histórico" && (
        <HistoricoAba
          ref={inputHistoricoRef}
          handleKeyDown={handleKeyDown}
          infosCadastro={infosCadastro}
          setInfosCadastro={setInfosCadastro}
        />
      )}
      {abaSelecionada.nomeAba === "Fonte de Recursos" && <FonteDeRecursosAba />}
      {abaSelecionada.nomeAba === "Receita Extra" && (
        <ReceitaExtraAba
          infosCadastro={infosCadastro}
          setInfosCadastro={setInfosCadastro}
          clienteId={currentClient.clienteId}
          inputExercicio={inputExercicio}
          setInputExercicio={setInputExercicio}
          inputGuiaReceita={inputGuiaReceita}
          setInputGuiaReceita={setInputGuiaReceita}
          infosAddReceitaExtra={infosAddReceitaExtra}
          setInfosAddReceitaExtra={setInfosAddReceitaExtra}
        />
      )}
      {abaSelecionada.nomeAba === "Transferência Concedida" && (
        <TransfConcedidaAba
          optionsUnidadesGestoras={optionsUnidadesGestoras}
          infosCadastro={infosCadastro}
          setInfosCadastro={setInfosCadastro}
          inputUnidadeGestoraValue={inputUnidadeGestoraValue}
          setInputUnidadeGestoraValue={setInputUnidadeGestoraValue}
          inputTipoTransferenciaValue={inputTipoTransferenciaValue}
          setInputTipoTransferenciaValue={setInputTipoTransferenciaValue}
          optionsTipoTransferencia={optionsTipoTransferencia}
          setOptionsTipoTranferencias={setOptionsTipoTranferencias}
        />
      )}
      {abaSelecionada.nomeAba === "Procedimentos Contábeis" && (
        <ProcedimentoContabel />
      )}
    </Container>
  );
};

export default GdeDespesa;
