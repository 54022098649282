import React, { useContext, useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { RowInfosAcao } from "../../../../components/RowInfosAcao/RowInfosAcao";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import { NotaFiscal } from "./NotaFiscal";
import { OrdemCronologicaAba } from "./OrdemCronologicaAba";
import { EfdReinfAba } from "./EfdReinfAba";
import { TestButton } from "../../../../utils/TestButton";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { useOpenedModals } from "../../../../hooks/useOpenedModals";
import { getCurrentDate } from "../../../../utils/getCurrentDate";
import { Agrupamento } from "./Agrupamento";
import { CurrentYearContext } from "../../../../contexts/YearContext";
import ProdutoEAquisicaoAba from "../NotaDeEmpenho/ProdutoEAquisicaoAba";
import { toast, ToastContainer } from "react-toastify";
import HistoricoAba from "../NotaDeEmpenho/HistoricoAba";
import { useParams } from "react-router-dom";
import api from "../../../../utils/api";
import { formatValue } from "../../../Paginas_Sistemas_Planejamento/Impressos/Relatorios/utils/formatValue";
import SaldosAba from "../NotaDeEmpenho/SaldosAba";
import ProcedimentosContabeisAba from "../NotaDeEmpenho/ProcedimentosContabeisAba";

const LiquidacaoDeEmpenho = () => {
  const { liquidacaoAtualId, idEmpenho } = useParams();
  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useContext(CurrentYearContext);
  const { openedModals, setOpenedModals } = useOpenedModals();
  const [empenho, setEmpenho] = useState([]);
  const [produtoAquisicao, setProdutoAquisicao] = useState([]);
  const [abaSelecionada, setAbaSelecionada] = useState({});
  const [fichas, setOptionsFichas] = useState([]);
  const [saldos, setSaldos] = useState({
    empenhado: "",
    liquidado: "",
  });
  const [values, setValues] = useState({
    empenho: {},
    data: getCurrentDate(),
    responsavelPeloAtesto: {
      responsavel: "",
      data: getCurrentDate(),
    },
    unidadeOrcamentaria: {
      codigo: "",
      titulo: "",
    },
    elementoDespesa: {
      codigo: "",
      descricao: "",
      tipoPessoa: "",
    },
    subelemento: {
      codigo: "",
      descricao: "",
    },
    fonteRecursos: {
      codigo: "",
      tipoDeRecurso: "",
      descricao: "",
    },
    modalidade: "",
    notaFiscal: {
      numero: "",
      serie: "",
      tipo: "",
      data: "",
      valor: "",
      numeroChave: "",
    },
    acao: {},
    liquidacao: null,
    descricao: "",
  });
  const [inputFornecedor, setInputFornecedor] = useState("");
  const [inputEmpenho, setInputEmpenho] = useState("");
  const [optionsCpf, setOptionsCpf] = useState([]);
  const [abas, setAbas] = useState([
    "Nota Fiscal",
    "Produto e Aquisição",
    "Ordem Cronológica",
    "EFD-Reinf",
    "Agrupamentos",
    "Saldos",
    "Procedimentos Contábeis",
  ]);
  const [valuesToTest, setValuesToTest] = useState({
    elemento: "",
    subElemento: "",
  });
  const [valorMaximo, setValorMaximo] = useState(null);

  const inputRealRef = useRef(null);
  const inputResponsavelAtestoRef = useRef(null);
  const inputFornecedorRef = useRef(null);

  const elementsList = ["30", "32", "35", "36", "39", "51", "52"];
  const hasAgrupamentoFolha = ["01", "03", "04", "11", "16"];
  const hasNotaFiscal = ["1", "2", "3", "6", "7", "8", "13", "15", "16", "17"];

  const handleGoRef = (nextRef) => {
    nextRef?.current?.focus();
  };

  const formatCpfOrCnpj = (value) => {
    if (!value) return "";

    const cleanedValue = value.replace(/\D/g, "");

    if (cleanedValue.length === 11) {
      // Formato CPF: 000.000.000-00
      return `${cleanedValue.slice(0, 3)}.${cleanedValue.slice(
        3,
        6
      )}.${cleanedValue.slice(6, 9)}-${cleanedValue.slice(9)}`;
    }

    if (cleanedValue.length === 14) {
      // Formato CNPJ: 00.000.000/0000-00
      return `${cleanedValue.slice(0, 2)}.${cleanedValue.slice(
        2,
        5
      )}.${cleanedValue.slice(5, 8)}/${cleanedValue.slice(
        8,
        12
      )}-${cleanedValue.slice(12)}`;
    }

    return value; // Retorna sem formatação se não for CPF nem CNPJ
  };

  const fetchCpfs = async (reqDeModal) => {
    try {
      const response = await api.get(
        `/pessoas/clientes/${currentClient.clienteId}`
      );

      const data = response.data.map((item) => ({
        id: item.dados.id,
        nome: item.dados.nomeCompleto || item.dados.nomeFantasia,
        documento: formatCpfOrCnpj(item.dados.cpf || item.dados.cnpj),
      }));

      if (data) {
        setOptionsCpf(
          data.map((item) => ({
            id: item.id,
            nome: item.nome,
            cpf: item.documento || "",
          }))
        );

        if (reqDeModal) {
          const dado = [...data].reverse()[0];

          setInputFornecedor(`${dado.nome} - ${dado.documento}`);

          setOpenedModals((prev) =>
            prev.filter((item) => item.tituloModal !== "CPF/CNPJ")
          );
        }
      }
    } catch (error) {
      console.error("Erro ao buscar dados do cliente:", error);
    }
  };

  const fetchLiquidacao = async () => {
    if (values?.empenho?.id && !liquidacaoAtualId) {
      try {
        const response = await api.get(
          `/liquidacaoDeEmpenho/nextLiquidacao/${values.empenho?.id}`
        );
        const saldoEmpenhado = parseFloat(values?.empenho?.saldoEmpenhado || 0);
        const saldoLiquidado = parseFloat(values?.empenho?.saldoLiquidado || 0);

        const valorRestante = saldoEmpenhado - saldoLiquidado;
        console.log(
          "AQUIII: ",
          values?.empenho?.saldoEmpenhado,
          values?.empenho?.saldoLiquidado,
          valorRestante
        );
        if (valorRestante !== 0) {
          setValues((prevValues) => ({
            ...prevValues,
            liquidacao: response.data.liquidacao,
            valor: formatValue(valorRestante),
          }));
          setValorMaximo(valorRestante);
        } else {
          handleClearEmpenhoAndData();
          setInputEmpenho("");
          setInputFornecedor("");
          toast.warn("Esse empenho já está liquidado!");
        }
        console.log("RESPONSE: ", response.data);
      } catch (error) {
        toast.warn(
          error.response.data.message ||
            "Não foi possível buscar o número de liquidação"
        );
        console.log(
          error.response.data.error ||
            "Não foi possível buscar o número de liquidação."
        );
      }
    }
  };

  const handleAddNewCpf = () => {
    setOpenedModals((prev) => [
      ...prev,
      { tituloModal: "CPF/CNPJ", requisicaoDoModalConcluida: false },
    ]);
  };

  const handleOptionSelect = (item) => {
    setValues((prevValues) => ({
      ...prevValues,
      responsavelPeloAtesto: {
        ...prevValues.responsavelPeloAtesto,
        responsavel: item.id,
      },
    }));
  };

  const fetchEmpenho = async () => {
    try {
      const response = await api.get(
        `/notaEmpenho/${currentClient.clienteId}/${currentYear}`
      );
      setEmpenho(response.data);
      console.log("Carregamo: ", response.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (idEmpenho && empenho) {
      const empenhoEncontrado = empenho.find(
        (item) => parseInt(item.id) === parseInt(idEmpenho)
      );
      if (empenhoEncontrado) {
        console.log("Aqui o empenho encontrado: ", empenhoEncontrado);
        handleSelectEmpenho(empenhoEncontrado);
      }
    }
    // eslint-disable-next-line
  }, [empenho]);

  const handleSelectEmpenho = (option) => {
    console.log("HANDLE SELECT EMPENHO: ", option);
    const [codigo, ...descricaoArray] = option.subelemento.split(" - ");
    setValues((prevValues) => ({
      ...prevValues,
      empenho: option,
      subelemento: {
        codigo: codigo.trim(),
        descricao: descricaoArray.join(" - ").trim(),
      },
    }));
    setProdutoAquisicao(
      option.produtos.map((item) => ({
        ...item,
        produtoAquisicao: {
          id: item?.produtoAquisicaoId,
          value: item?.numero,
          label: `${item?.numero} - ${item?.produtoAquisicao}`,
        },
        unidadeMedida: item.unidadeDeMedida,
      }))
    );
    setSaldos({
      empenhado: option.saldoEmpenhado || "00.00",
      liquidado: option.saldoLiquidado || "00.00",
    });

    if (!liquidacaoAtualId && !idEmpenho) {
      const parts = option.label.trim().split(" - ");
      const firstPart = parts[0].trim(); // Primeira parte (0000001)
      const lastPart = parts[parts.length - 1].trim();
      setInputEmpenho(`${firstPart.trim()} - ${lastPart}`);
    } else {
      setInputEmpenho(
        `${option.numero} - ${
          option.fornecedorInfo.dados.nomeCompleto
            ? option.fornecedorInfo.dados.nomeCompleto
            : option.fornecedorInfo.dados.nomeFantasia
        }`
      );
    }
  };

  useEffect(() => {
    if (idEmpenho || values.empenho.numero) {
      verifyModalidade();
    }
    // eslint-disable-next-line
  }, [values.empenho]);

  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      empenho: {
        ...prevValues.empenho,
        produtos: produtoAquisicao, // Aqui você atualiza apenas a propriedade produtos
      },
    }));
  }, [produtoAquisicao]);

  const fetchQddFichas = async () => {
    try {
      const resp = await api.get(
        `/qdd/${currentClient.clienteId}/${currentYear}`
      );
      const dados = resp.data;

      const arrayDeFichas = dados.map((item) => {
        const fichaReferente = item.despesaFixadaQDD.fichasQDD;
        return {
          label: `Ficha ${fichaReferente.numeroFicha}`,
          value: fichaReferente.id,
          ficha: item,
        };
      });

      setOptionsFichas(arrayDeFichas);
    } catch (error) {
      console.error("Erro ao buscar fichas:", error);
    }
  };

  useEffect(() => {
    const selectedFicha = fichas.find(
      (item) => item.value === values.empenho.fichaId
    );

    if (selectedFicha) {
      const { nAcao, titulo } = selectedFicha.ficha.acaoGovernamentalInfo;
      setValues((prevValues) => ({
        ...prevValues,
        acao: `${nAcao} - ${titulo}`,
        unidadeOrcamentaria: {
          titulo:
            selectedFicha.ficha.acaoGovernamentalInfo.unidadeOrcamentariaId
              .tituloUnidadeOrcamentaria,
          codigo:
            selectedFicha.ficha.acaoGovernamentalInfo.unidadeOrcamentariaId
              .codigoUnidadeOrcamentaria,
        },
        elementoDespesa: {
          codigo:
            selectedFicha.ficha.despesaFixadaQDD.fichasQDD.naturezaDaDespesa.codigo.slice(
              0,
              -3
            ),
          descricao:
            selectedFicha.ficha.despesaFixadaQDD.fichasQDD.naturezaDaDespesa
              .funcao,
          tipoPessoa:
            selectedFicha.ficha.despesaFixadaQDD.fichasQDD.naturezaDaDespesa
              .nome,
        },
        fonteRecursos: {
          codigo: selectedFicha.ficha.despesaFixadaQDD.fonteDeRecurso.conta,
          tipoDeRecurso:
            selectedFicha.ficha.despesaFixadaQDD.fonteDeRecurso.titulo,
          descricao: selectedFicha.ficha.despesaFixadaQDD.fonteDeRecurso.funcao,
        },
        modalidade: values.empenho.tipoDeEmpenho,
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        acao: ``,
        liquidacao: null,
      }));
    }
  }, [fichas, values.empenho]);

  useEffect(() => {
    const modalCpfCnpj = openedModals.find(
      (item) => item.tituloModal === "CPF/CNPJ"
    );

    if (modalCpfCnpj && modalCpfCnpj.requisicaoDoModalConcluida) {
      fetchCpfs(true);
    }

    //eslint-disable-next-line
  }, [openedModals]);

  useEffect(() => {
    if (!elementsList.includes(valuesToTest.elemento.slice(-2))) {
      setAbas((prevAbas) =>
        prevAbas.filter(
          (aba) => aba !== "Nota Fiscal" && aba !== "Agrupamentos"
        )
      );
    } else {
      setAbas((prevAbas) => {
        const newAbas = [...prevAbas];

        if (!newAbas.includes("Nota Fiscal")) {
          newAbas.unshift("Nota Fiscal");
        }

        if (!newAbas.includes("Agrupamentos")) {
          newAbas.splice(-1, 0, "Agrupamentos");
        }

        return newAbas;
      });
    }
    // eslint-disable-next-line
  }, [valuesToTest]);

  useEffect(() => {
    setValuesToTest({
      elemento: "3.3.90.39",
      subElemento: "17",
    });
  }, []);

  useEffect(() => {
    fetchCpfs();
    fetchEmpenho();
    fetchQddFichas();
    if (!idEmpenho) {
      handleGoRef(inputFornecedorRef);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchLiquidacaoAtual();
    // eslint-disable-next-line
  }, [optionsCpf]);

  const verifyModalidade = () => {
    if (values.modalidade && values.modalidade !== "1 - Ordinário") {
      handleGoRef(inputRealRef);
    } else {
      handleGoRef(inputResponsavelAtestoRef);
    }
  };

  useEffect(() => {
    if (idEmpenho) {
      verifyModalidade();
    }
    // eslint-disable-next-line
  }, [values.modalidade, idEmpenho]);

  const elementos = [
    `30`,
    `31`,
    `32`,
    `33`,
    `34`,
    `35`,
    `36`,
    `37`,
    `38`,
    `39`,
    `51`,
    `52`,
  ];

  useEffect(() => {
    const codigo = values?.elementoDespesa?.codigo.split(".")[3];
    if (codigo && elementos.includes(codigo)) {
      setAbas(["Histórico", "Produto e Aquisição", "Complemento", "Saldos"]);
    } else {
      setAbas(["Histórico", "Complemento", "Saldos"]);
    }
    // eslint-disable-next-line
  }, [values]);

  const handleClearEmpenhoAndData = () => {
    setValues((prevValues) => ({
      ...prevValues,
      empenho: {},
      data: getCurrentDate(),
      responsavelPeloAtesto: {
        responsavel: "",
        data: getCurrentDate(),
      },
      unidadeOrcamentaria: {
        codigo: "",
        titulo: "",
      },
      elementoDespesa: {
        codigo: "",
        descricao: "",
        tipoPessoa: "",
      },
      subelemento: {
        codigo: "",
        descricao: "",
      },
      fonteRecursos: {
        codigo: "",
        tipoDeRecurso: "",
        descricao: "",
      },
      modalidade: "",
      notaFiscal: {
        numero: "",
        serie: "",
        tipo: "",
        data: "",
        valor: "",
        numeroChave: "",
      },
      acao: {},
      liquidacao: null,
      valor: "0,00",
    }));
    setInputFornecedor("");
    setValorMaximo(null);
  };

  const handleSave = async () => {
    const valuesToPost = {
      clienteId: currentClient.clienteId,
      empenhoId: values.empenho.id,
      acaoId: values.empenho.fichaInfo[0].acaoGovernamentalId,
      liquidacao: String(values.liquidacao),
      descricao: values.descricao || "",
      data: values.data,
      responsavelId: values.responsavelPeloAtesto.responsavel,
      valor: values.valor.includes(",")
        ? values.valor.replace(/\./g, "").replace(",", ".")
        : values.valor,
      produtos:
        values?.empenho?.produtos?.length > 0
          ? values.empenho.produtos.map((item) => ({
              produtoAquisicaoId: item.produtoAquisicao.id,
              quantidade: item.quantidade,
              valor: item.valor.includes(",")
                ? item.valor.replace(/\./g, "").replace(",", ".")
                : item.valor,
            }))
          : null,
    };

    if (!liquidacaoAtualId) {
      try {
        console.log("Valores Enviados: ", valuesToPost);
        const response = await api.post("/liquidacaoDeEmpenho", valuesToPost);
        toast.success("Liquidação de empenho cadastrada com sucesso!");
        console.log(response.data);
        handleClearEmpenhoAndData();
      } catch (error) {
        const missingFields = [];
        if (!valuesToPost.responsavelId) {
          missingFields.push("Faltando Responsável");
        }

        const errorMessage =
          error.response?.data?.error ||
          "Não foi possível cadastrar essa liquidação de empenho";

        const detailedMessage = missingFields.length
          ? `${errorMessage}, ${missingFields.join(", ")}`
          : errorMessage;

        console.log(detailedMessage, "Os dados enviados foram: ", valuesToPost);

        toast.error(detailedMessage);

        console.log(error);
      }
    } else {
      const valuesToPut = {
        ...valuesToPost,
        id: values?.empenhoId,
        responsavelId: values.responsavelId,
      };
      console.log("AAAAAAAAAAAAAAAAA", values);
      try {
        console.log(valuesToPut);
        const response = await api.put(
          `/liquidacaoDeEmpenho/${liquidacaoAtualId}`,
          valuesToPut
        );
        toast.success("Liquidação de empenho atualizada com sucesso!");
        console.log(response.data);
      } catch (error) {
        const missingFields = [];
        if (!valuesToPut.responsavelId) {
          missingFields.push("Faltando Responsável");
        }

        const errorMessage =
          error.response?.data?.error ||
          "Não foi possível atualizar essa liquidação de empenho";

        const detailedMessage = missingFields.length
          ? `${errorMessage}, ${missingFields.join(", ")}`
          : errorMessage;

        console.log(detailedMessage, "Os dados enviados foram: ", valuesToPut);

        toast.error(detailedMessage);

        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchLiquidacao();
    // eslint-disable-next-line
  }, [values.empenho.id]);

  const fetchLiquidacaoAtual = async () => {
    if (liquidacaoAtualId) {
      try {
        const response = await api.get(
          `/liquidacaoDeEmpenho/${liquidacaoAtualId}`
        );
        setValues((prevValues) => ({
          ...prevValues,
          ...response.data,
          data: new Date(response.data.data).toISOString().split("T")[0],
        }));
        handleSelectEmpenho(response.data.empenho);
        const fornecedorEncontrado = optionsCpf.find(
          (option) => option.id === parseInt(response.data.responsavelId)
        );
        setInputFornecedor(fornecedorEncontrado?.nome);
        console.log("A RESPONSE VEIO ASIIIIM: ", response.data);
      } catch (error) {
        if (error?.response?.data) {
          toast.warn(
            error?.response?.data?.error ||
              "Não foi possível carregar a Liquidação!"
          );
        }
        console.log(error);
      }
    }
  };

  return (
    <Container
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <ToastContainer />
      <TestButton onClick={() => console.log("valus", values)}>
        values
      </TestButton>
      <HeaderCadastros
        PaginaSubtitulo={"Cadastro"}
        NomePaginaTitulo={"Liquidação de Empenho - LE"}
        ButtonSaveFunction={handleSave}
        PaginaConsulta={"/contabilidade/despesa/consulta/liquidacao-de-empenho"}
      />

      <RowForm className="mt-1">
        <TestButton
          onClick={() => console.log("Produtos", values.empenho.produtos)}
        >
          produtos
        </TestButton>
        <CCol>
          <MyAutoComplete
            ref={inputFornecedorRef}
            iconsOff={liquidacaoAtualId}
            disabled={liquidacaoAtualId}
            inputValue={inputEmpenho}
            setInputValue={setInputEmpenho}
            options={empenho}
            labelFormat={(item) => {
              const fichaCorrespondente = fichas.find(
                (ficha) => ficha.value === item.fichaId
              );
              const fornecedorEncontrado = optionsCpf.find(
                (option) => option.id === parseInt(item.fornecedor)
              );

              const numero = item.numero || "N/A";
              const nAcao =
                fichaCorrespondente?.ficha?.acaoGovernamentalInfo?.nAcao ||
                "N/A";
              const naturezaDaDespesa =
                fichaCorrespondente?.ficha?.despesaFixadaQDD?.fichasQDD?.naturezaDaDespesa?.codigo.slice(
                  0,
                  -3
                ) || "N/A";
              const fornecedor = fornecedorEncontrado
                ? fornecedorEncontrado.nome
                : "N/A";

              return `${numero} - ${nAcao} - ${naturezaDaDespesa} - ${fornecedor}`;
            }}
            labelInput="Empenho"
            style={{ height: "37px" }}
            onOptionSelect={(option) => handleSelectEmpenho(option)}
            iconClearFunction={handleClearEmpenhoAndData}
          />
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            label="Liquidação"
            placeholder="Liquidação"
            disabled
            value={
              values.empenho.numero && values.liquidacao
                ? `${values.empenho.numero} ${values.liquidacao}`
                : ""
            }
          />
        </CCol>
        <CCol md={2}>
          <InputData
            disabled={liquidacaoAtualId}
            value={values.data}
            onInput={(e) => {
              setValues({
                ...values,
                data: e.target.value,
              });
            }}
          />
        </CCol>
      </RowForm>

      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <FloatingLabelInput
            disabled
            placeholder="Ação"
            label="Ação"
            value={values.acao}
          />
        </CCol>
        <CCol md={4}>
          <InputFormatRealBrasileiro
            ref={inputRealRef}
            disabled={
              values?.modalidade === "1 - Ordinário" || liquidacaoAtualId
            }
            iconReal
            value={values?.valor}
            onChange={(_, valor) => {
              // Converte o valor digitado para número
              let valorNumerico = parseFloat(
                valor.replace(/\./g, "").replace(",", ".")
              );

              // Se o valor for maior que valorMaximo, ajusta para valorMaximo
              if (valorMaximo && valorNumerico > valorMaximo) {
                valorNumerico = parseFloat(valorMaximo);
              }

              // Converte o valor novamente para o formato de string (com a vírgula)
              const valorCorrigido = valorNumerico
                .toFixed(2) // "1234567.89"
                .replace(".", ",") // "1234567,89"
                .replace(/\B(?=(\d{3})+(?!\d))/g, "."); // "1.234.567,89"

              console.log(valorCorrigido);

              // Atualiza o estado com o valor corrigido
              setValues((prevValues) => ({
                ...prevValues,
                valor: valorCorrigido,
              }));
            }}
            putDot
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleGoRef(inputResponsavelAtestoRef);
              }
            }}
          />
        </CCol>
      </RowForm>

      {values.empenho.fichaId && (
        <RowInfosAcao>
          <CCol>
            <p>
              Unidade Orçamentária:{" "}
              <span>{`${values?.unidadeOrcamentaria?.codigo} - ${values?.unidadeOrcamentaria?.titulo}`}</span>
            </p>
            <p>
              Elemento de Despesa:{" "}
              <span>
                {`${values?.elementoDespesa?.codigo} - ${values?.elementoDespesa?.tipoPessoa}`}
              </span>
            </p>
            <p>
              Subelemento:{" "}
              <span>
                {`${values?.subelemento?.codigo} - ${values?.subelemento?.descricao}`}
              </span>
            </p>
            <p>
              Fonte de Recursos - CO:{" "}
              <span>
                {`${values?.fonteRecursos?.codigo} - ${values?.fonteRecursos?.tipoDeRecurso}`}
              </span>
            </p>
            <p>
              Modalidade: <span>{values?.modalidade}</span>
            </p>
          </CCol>
        </RowInfosAcao>
      )}
      <RowForm className="mt-1">
        <CCol>
          <MyAutoComplete
            ref={inputResponsavelAtestoRef}
            iconsOff={liquidacaoAtualId}
            disabled={liquidacaoAtualId}
            labelInput="Responsável pelo Atesto"
            options={optionsCpf}
            inputValue={inputFornecedor}
            setInputValue={setInputFornecedor}
            addNewDataFunction={handleAddNewCpf}
            labelFormat={(item) => `${item.nome} - ${item.cpf}`}
            style={{ height: "37px" }}
            onOptionSelect={handleOptionSelect}
          />
        </CCol>
      </RowForm>
      {hasAgrupamentoFolha.includes(
        values.elementoDespesa.codigo.slice(-2)
      ) && (
        <RowForm style={{ marginTop: "-15px" }}>
          <CCol>
            <FloatingLabelInput
              disabled
              // disabled={liquidacaoAtualId}
              label="Agrupamento Folha"
              placeholder="Agrupamento Folha"
              value={"Agrupamento Folha"}
            />
          </CCol>
        </RowForm>
      )}
      {hasNotaFiscal.includes(values.elementoDespesa.codigo.slice(-2)) && (
        <>
          <RowForm style={{ marginTop: "-15px" }}>
            <CCol>
              <FloatingLabelInput
                disabled
                // disabled={liquidacaoAtualId}
                label="Agrupamento Folha"
                placeholder="Agrupamento Folha"
                value={"Agrupamento Folha"}
              />
            </CCol>
            <CCol>
              <FloatingLabelInput
                disabled
                // disabled={liquidacaoAtualId}
                label="Tipo Nota Fiscal"
                placeholder="Tipo Nota Fiscal"
                value={"Tipo Nota Fiscal"}
              />
            </CCol>
          </RowForm>
          <RowForm style={{ marginTop: "-15px" }}>
            <CCol>
              <FloatingLabelInput
                disabled
                // disabled={liquidacaoAtualId}
                label="Número Nota Fiscal"
                placeholder="Número Nota Fiscal"
                value={"Número Nota Fiscal"}
              />
            </CCol>
            <CCol md={2}>
              <FloatingLabelInput
                disabled
                // disabled={liquidacaoAtualId}
                label="Série NF"
                placeholder="Série NF"
                value={"Série NF"}
              />
            </CCol>
            <CCol md={2}>
              <InputData
                disabled
                // disabled={liquidacaoAtualId}
              />
            </CCol>
            <CCol>
              <InputFormatRealBrasileiro
                disabled
                // disabled={liquidacaoAtualId}
              />
            </CCol>
          </RowForm>
        </>
      )}

      {Object.keys(values?.empenho || {}).length > 0 ? (
        <RowSelecaoAbasInferior
          abas={abas}
          abaSelecionada={abaSelecionada}
          setAbaSelecionada={setAbaSelecionada}
          alwaysSelectFirst
        />
      ) : (
        <></>
      )}

      {abaSelecionada.nomeAba === "Histórico" && (
        <HistoricoAba
          values={values.empenho}
          setValues={setValues}
          disabled={true}
        />
      )}

      {abaSelecionada.nomeAba === "Nota Fiscal" && (
        <NotaFiscal values={values} setValues={setValues} />
      )}
      {abaSelecionada.nomeAba === "Produto e Aquisição" && (
        <ProdutoEAquisicaoAba
          produtoAquisicao={produtoAquisicao}
          setProdutoAquisicao={setProdutoAquisicao}
          valorTotal={
            values?.empenho?.saldoEmpenhado - values?.empenho?.saldoLiquidado
          }
          liquidacaoAtualId={liquidacaoAtualId}
        />
      )}
      {abaSelecionada.nomeAba === "Ordem Cronológica" && (
        <OrdemCronologicaAba />
      )}
      {abaSelecionada.nomeAba === "EFD-Reinf" && <EfdReinfAba />}
      {abaSelecionada.nomeAba === "Saldos" && <SaldosAba saldos={saldos} />}
      {abaSelecionada.nomeAba === "Agrupamentos" && <Agrupamento />}
      {abaSelecionada.nomeAba === "Procedimentos Contábeis" && (
        <ProcedimentosContabeisAba />
      )}

      <div className="p-3" style={{ minHeight: "80px" }}></div>
    </Container>
  );
};

export default LiquidacaoDeEmpenho;
