import React, { forwardRef } from "react";
import { CCol, RowToModal } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";

const HistoricoAba = forwardRef(
  (
    { setValuesReceitaOrcamentaria, valuesReceitaOrcamentaria, handleKeyDown,onFocus },
    ref
  ) => {
    return (
      <>
        <RowToModal
          className="row_form mt-3"
          style={{
            borderRadius: "5px 5px 0px 0px",
            padding: "8px 10px",
          }}
        >
          <CCol md={1}>
            <span
              style={{ fontSize: "16px", color: "#515c70", fontWeight: "700" }}
            >
              Histórico
            </span>
          </CCol>
        </RowToModal>
        <RowToModal className="row_form_2 mt-1">
          <CCol>
            <FloatingLabelInput
              ref={ref}
              tipo="text_area"
              voiceInput
              as="textarea"
              label="Descrição"
              onChange={(e) =>
                setValuesReceitaOrcamentaria((prev) => ({
                  ...prev,
                  descricao: e.target.value,
                }))
              }
              onKeyDown={(e) => handleKeyDown(e)}
              onFocus={onFocus}
              value={valuesReceitaOrcamentaria.descricao}
              placeholder="Descrição"
              style={{ minHeight: "98px" }}
              min={10}
              max={500}
            />
          </CCol>
        </RowToModal>
      </>
    );
  }
);

export default HistoricoAba;
