import api from "../../utils/api";

export const postGre = async (infosGre) => {
  try {
    await api.post(`gre`, infosGre);
    return true;
  } catch (error) {
    return null;
  }
};
