import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";
import {
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
} from "../../../../components/TableConsulta/TableConsulta";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import HeaderOptions from "../../../../components/HeaderOptions/HeaderOptions";
import InputSvg from "../../../../components/Grid/InputSvg";
// import ModalDeleteData from "../../../../components/ModalDeleteData/ModalDeleteData";
// import { useCurrentYear } from "../../../../hooks/useCurrentYear";
import { ToastContainer } from "react-toastify";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { CurrentYearContext } from "../../../../contexts/YearContext";
import { TestButton } from "../../../../utils/TestButton";
import api from "../../../../utils/api";
import TableLiquidado from "./TableLiquidado";
import TableALiquidar from "./TableALiquidar";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { formatToCurrency } from "../../../../utils/FormatacaoDeDados/FormatToCurrency";

function LiquidacaoConsulta() {
  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useContext(CurrentYearContext);
  // const { currentYear } = useCurrentYear();
  // eslint-disable-next-line
  const [liquidacoes, setLiquidacoes] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  // eslint-disable-next-line
  const [liquidacoesFiltradas, setLiquidacoesFiltradas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("liquidado");
  const [optionsCpf, setOptionsCpf] = useState([]);
  const [optionsFichas, setOptionsFichas] = useState([]);
  const [notaEmpenho, setNotaEmpenho] = useState([]);
  const [notaEmpenhoFiltrados, setNotaEmpenhoFiltrados] = useState([]);
  // const [filteredData, setFilteredData] = useState([]);
  const [empenhados, setEmpenhados] = useState([]);
  const [naturezaContratacao, setNaturezaContratacao] = useState(null);

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/liquidacaoDeEmpenho/cliente/${currentClient.clienteId}`
      );
      setLiquidacoes(response.data);
      console.log(response.data);

      // Cria um array para armazenar os números de empenho
      const empenhoNumeros = response.data.reduce((acc, item) => {
        if (item.empenho && item.empenho.numero) {
          acc.push(item.empenho.numero); // Adiciona o número de empenho ao array
        }
        return acc;
      }, []);

      setEmpenhados(empenhoNumeros);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const fetchDataEmpenho = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/notaEmpenho/${currentClient.clienteId}/${currentYear}`
      );
      const sortedData = [...response.data]
        .sort((a, b) => b.numero - a.numero) // Ordena os dados de forma decrescente
        .filter((item) => !empenhados.includes(item.numero)); // Remove itens cujos números estão em 'empenhados'

      console.log("Notas aqui: ", sortedData);
      setNotaEmpenho(sortedData);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    setCurrentPage(0);
    fetchQddFichas();
    fetchCpfs();
    // }, [month]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchDataEmpenho();
    // eslint-disable-next-line
  }, [liquidacoes]);

  const handleSearch = (event) => {
    console.log(naturezaContratacao);
    const { value } = event.target;
    setSearchTerm(value);
    if (status === "liquidado") {
      const filteredItems = liquidacoes.filter((item) => {
        return (
          item?.empenho?.numero?.includes(value) ||
          item?.empenho?.fornecedorInfo?.dados?.nomeCompleto
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          String(
            new Date(item.data).toLocaleDateString("pt-BR", {
              timeZone: "UTC",
            })
          ).includes(value) ||
          String(
            new Date(item.data).toLocaleDateString("pt-BR", {
              timeZone: "UTC",
            })
          )
            .replace(/\//g, "")
            .includes(value) ||
          formatToCurrency(item?.valor).includes(value) ||
          formatToCurrency(item?.valor).replace(/[.,]/g, "").includes(value)
        );
      });
      setLiquidacoesFiltradas(filteredItems);
    } else {
      const filteredItems = notaEmpenho.filter((item) => {
        return item?.numero?.includes(value);
      });
      setNotaEmpenhoFiltrados(filteredItems);
    }
    setCurrentPage(0);
  };

  const fetchCpfs = async () => {
    try {
      const response = await api.get(
        `/pessoas/clientes/${currentClient.clienteId}`
      );

      const data = response.data.map((item) => ({
        id: item.dados.id,
        nome: item.dados.nomeCompleto || item.dados.nomeFantasia,
        documento: formatCpfOrCnpj(item.dados.cpf || item.dados.cnpj),
      }));

      if (data) {
        setOptionsCpf(
          data.map((item) => ({
            id: item.id,
            nome: item.nome,
            cpf: item.documento || "",
          }))
        );
      }
    } catch (error) {
      console.error("Erro ao buscar dados do cliente:", error);
    }
  };

  const fetchQddFichas = async () => {
    try {
      const resp = await api.get(
        `/qdd/${currentClient.clienteId}/${currentYear}`
      );
      const dados = resp.data;

      const arrayDeFichas = dados.map((item) => {
        const fichaReferente = item.despesaFixadaQDD.fichasQDD;
        return {
          label: `Ficha ${fichaReferente.numeroFicha}`,
          value: fichaReferente.id,
          ficha: item,
        };
      });

      setOptionsFichas(arrayDeFichas);
      console.log(arrayDeFichas);
    } catch (error) {
      console.error("Erro ao buscar fichas:", error);
    }
  };

  const formatCpfOrCnpj = (value) => {
    if (!value) return "";

    const cleanedValue = value.replace(/\D/g, "");

    if (cleanedValue.length === 11) {
      // Formato CPF: 000.000.000-00
      return `${cleanedValue.slice(0, 3)}.${cleanedValue.slice(
        3,
        6
      )}.${cleanedValue.slice(6, 9)}-${cleanedValue.slice(9)}`;
    }

    if (cleanedValue.length === 14) {
      // Formato CNPJ: 00.000.000/0000-00
      return `${cleanedValue.slice(0, 2)}.${cleanedValue.slice(
        2,
        5
      )}.${cleanedValue.slice(5, 8)}/${cleanedValue.slice(
        8,
        12
      )}-${cleanedValue.slice(12)}`;
    }

    return value; // Retorna sem formatação se não for CPF nem CNPJ
  };

  const optionsOrdemCronologica = [
    { label: "Todos", value: 5 },
    {
      label: "0 - Outros",
      value: 0,
    },
    {
      label: "1 - Fornecimento de Bens",
      value: 1,
    },
    {
      label: "2 - Locações",
      value: 2,
    },
    {
      label: "3 - Prestação de Serviços",
      value: 3,
    },
    {
      label: "4 - Realização de Obras",
      value: 4,
    },
  ];

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = liquidacoes.slice(startIndex, endIndex);
  const currentItensEmpenho = notaEmpenho.slice(startIndex, endIndex);
  const currentFilteredItens = liquidacoesFiltradas.slice(startIndex, endIndex);
  const currentFilteredItensEmpenho = notaEmpenhoFiltrados.slice(
    startIndex,
    endIndex
  );

  const pages = Math.ceil(liquidacoes.length / itensPorPagina);
  const pagesEmpenho = Math.ceil(notaEmpenho.length / itensPorPagina);
  const filteredPages = Math.ceil(liquidacoesFiltradas.length / itensPorPagina);
  const filteredPagesEmpenho = Math.ceil(
    notaEmpenhoFiltrados.length / itensPorPagina
  );

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderOptions
        withRow={true}
        TituloPagina={"Liquidação de Empenho"}
        SubTituloPagina={"Consulta"}
        ToPag={"/contabilidade/despesa/liquidacao-de-empenho"}
      />
      <TestButton onClick={() => console.log(liquidacoes)}>Teste</TestButton>
      <ToastContainer />
      <Row className="row_form mt-1">
        <Col md={8} style={{ display: "flex" }}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
          <FilterContainer style={{ marginLeft: "20px" }}>
            <div style={{ display: "flex" }}>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "5px",
                }}
              >
                <input
                  type="radio"
                  name="filterOption"
                  value="liquidado"
                  onChange={handleChange}
                  checked={status === "liquidado"}
                  style={{ marginRight: "4px" }}
                />
                Liquidado
              </label>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "5px",
                  marginRight: "10px",
                }}
              >
                <input
                  type="radio"
                  name="filterOption"
                  value="liquidar"
                  onChange={handleChange}
                  checked={status === "liquidar"}
                  style={{ marginRight: "3px", marginLeft: "10px" }}
                />
                A Liquidar
              </label>
            </div>
          </FilterContainer>
          {status === "liquidar" && (
            <div style={{ flexGrow: 1 }}>
              <FloatingLabelInput
                tipo="input_select"
                new
                placeholder={"Natureza da Contratação"}
                label={"Natureza da Contratação"}
                options={[
                  { label: "Natureza da Contratação", value: null },
                  ...optionsOrdemCronologica,
                ]}
                onSelect={(option) => setNaturezaContratacao(option)}
                value={naturezaContratacao}
              />
            </div>
          )}
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loading ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        {status === "liquidado" ? (
          <TableLiquidado
            loading={loading}
            currentItens={currentItens}
            currentFilteredItens={currentFilteredItens}
            searchTerm={searchTerm}
          />
        ) : (
          <TableALiquidar
            loading={loading}
            searchTerm={searchTerm}
            optionsCpf={optionsCpf}
            optionsFichas={optionsFichas}
            currentItens={currentItensEmpenho}
            currentFilteredItens={currentFilteredItensEmpenho}
            naturezaContratacao={naturezaContratacao?.value}
          />
        )}
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm ? liquidacoesFiltradas.length : liquidacoes.length}{" "}
            registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de{" "}
              {searchTerm
                ? status === "liquidado"
                  ? filteredPages
                  : filteredPagesEmpenho
                : status === "liquidado"
                ? pages
                : pagesEmpenho}{" "}
              páginas
            </span>
            <Pagination
              pages={
                searchTerm
                  ? status === "liquidado"
                    ? filteredPages
                    : filteredPagesEmpenho
                  : status === "liquidado"
                  ? pages
                  : pagesEmpenho
              }
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default LiquidacaoConsulta;
