import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row, Table } from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";

import outlineSearch from "@iconify/icons-ic/outline-search";
import { Icon } from "@iconify/react";

import ButtonsAcoesListas from "../../../../styles/StyledComponents/ButtonsAcoesListas";

import api from "../../../../utils/api";

import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";

import { useCurrentYear } from "../../../../hooks/useCurrentYear";

import InputSvg from "../../../../components/Grid/InputSvg";
import ModalDeleteData from "../../../../components/ModalDeleteData/ModalDeleteData";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";


export const ConsultaQdrde = () => {
  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useCurrentYear();

  const navigate = useNavigate();

  const [dataQdrde, setDataQdrde] = useState([
    {
      id: null,
      titulo: "",
      categoriaContaPcasp: "",
      naturezaSaldo: "",
    },
  ]);

  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [isLoadingDataQdrde, setIsLoadingDataQdrde] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itensFiltrados, setItensFiltrados] = useState([]);
  const [modalOpened, setModalOpened] = useState(false);
  const [dataDelete, setDataDelete] = useState({ id: 0 });

  const pages = Math.ceil(dataQdrde.length / itensPorPagina);
  const filteredPages = Math.ceil(itensFiltrados.length / itensPorPagina);

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = dataQdrde.slice(startIndex, endIndex);
  const currentFilteredItens = itensFiltrados.slice(startIndex, endIndex);

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();

    setSearchTerm(searchValue);

    const filteredArray = dataQdrde.filter((item) => {
      const title = item.titulo.toLocaleLowerCase();
      const categoria = item.categoriaContaPcasp.toLocaleLowerCase();

      return title.includes(searchValue) || categoria.includes(searchValue);
    });
    setItensFiltrados(filteredArray);
  };

  function openModal() {
    setModalOpened(true);
  }

  function closeModal() {
    setModalOpened(false);
  }

  const handleDeleteDataQdrde = async () => {
    console.log("delete");
    try {
      await api.delete(`qdrde/${dataDelete.id}`);
      setDataQdrde((prev) => prev.filter((item) => item.id !== dataDelete.id));
      toast.success("Registro deletado com sucesso.");
    } catch (error) {
      console.log(error);
      toast.error("Algo deu errado ao deletar o registro.");
    }
  };

  const getDataQdrde = useCallback(async () => {
    try {
      const resp = await api.get(
        `qdrde/${currentClient.clienteId}/${currentYear}`
      );
      const data = resp.data;
      const dataQdrde = data.map((item) => {
        const categoriaContaPcasp = `${item.categoriaContaPcaspInfo[0].conta} - ${item.categoriaContaPcaspInfo[0].titulo}`;
        return {
          id: item.id,
          titulo: item.titulo,
          categoriaContaPcasp,
          naturezaSaldo: item.naturezaSaldo,
        };
      });
      setDataQdrde(dataQdrde);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingDataQdrde(false);
    }
  }, [currentClient, currentYear]);

  useEffect(() => {
    getDataQdrde();
  }, [getDataQdrde]);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"QDRDE"}
        PaginaSubtitulo={"Consulta"}
        BotaoSaveAtivo={false}
        RowTitle="QDRDE"
        BotaoNovoFunction={() =>
          navigate("/contabilidade/extra/qdrde/cadastro")
        }
      />
      <ModalDeleteData
        modalOpened={modalOpened}
        closeModal={closeModal}
        setDataDelete={setDataDelete}
        deleteDataFunction={handleDeleteDataQdrde}
      />
      <ToastContainer />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>

      <Row className="row_form mt-1">
        {isLoadingDataQdrde && (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        )}

        <Table
          responsive
          style={
            isLoadingDataQdrde ? { display: "none" } : { marginTop: "-10px" }
          }
        >
          <thead>
            <tr>
              <TituloTable>Título</TituloTable>
              <TituloTable>Categoria</TituloTable>
              <TituloTable>Natureza Saldo</TituloTable>
              <TituloTableAction>Ação</TituloTableAction>
            </tr>
          </thead>
          <BodyTable>
            {searchTerm
              ? currentFilteredItens.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        whiteSpace: "nowrap",
                        textTransform: "capitalize",
                      }}
                      title={item.titulo}
                    >
                      {item.titulo.length > 40
                        ? item.titulo.substring(0, 40) + "..."
                        : item.titulo}
                    </td>
                    <td
                      style={{
                        whiteSpace: "nowrap",
                        textTransform: "capitalize",
                      }}
                      title={item.categoriaContaPcasp.toLowerCase()}
                    >
                      {item.categoriaContaPcasp.length > 60
                        ? item.categoriaContaPcasp.substring(0, 60) + "..."
                        : item.categoriaContaPcasp}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {item.naturezaSaldo}
                    </td>
                    <td>
                      <ButtonsAcoesListas
                        FuncaoIconDelete={() => {
                          openModal();
                          setDataDelete({ id: item.id });
                        }}
                        CaminhoPagUpdate={`/contabilidade/extra/qdrde/visualizar/${item.id}`}
                      />
                    </td>
                  </tr>
                ))
              : currentItens.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        whiteSpace: "nowrap",
                        textTransform: "capitalize",
                      }}
                      title={item.titulo}
                    >
                      {item.titulo.length > 40
                        ? item.titulo.substring(0, 40) + "..."
                        : item.titulo}
                    </td>
                    <td
                      style={{
                        whiteSpace: "nowrap",
                        textTransform: "capitalize",
                      }}
                      title={item.categoriaContaPcasp.toLowerCase()}
                    >
                      {item.categoriaContaPcasp.length > 60
                        ? item.categoriaContaPcasp.substring(0, 60) + "..."
                        : item.categoriaContaPcasp}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {item.naturezaSaldo}
                    </td>
                    <td>
                      <ButtonsAcoesListas
                        FuncaoIconDelete={() => {
                          openModal();
                          setDataDelete({ id: item.id });
                        }}
                        CaminhoPagUpdate={`/contabilidade/extra/qdrde/visualizar/${item.id}`}
                      />
                    </td>
                  </tr>
                ))}
          </BodyTable>
        </Table>
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm ? itensFiltrados.length : dataQdrde.length} registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>

            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
};
