import { Table } from "react-bootstrap";
import {
  BodyTable,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import { formatToCurrency } from "../../../../utils/FormatacaoDeDados/FormatToCurrency";
import { NavLink } from "react-router-dom";
import { IconHistory } from "../../../../components/Icons/IconHistory";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const TableALiquidar = ({
  loading,
  searchTerm,
  optionsCpf,
  currentItens,
  currentFilteredItens,
  naturezaContratacao,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log(naturezaContratacao);
  }, [naturezaContratacao]);

  const [itens, setItens] = useState([]);

  useEffect(() => {
    console.log(naturezaContratacao);

    if (!naturezaContratacao || naturezaContratacao === 5) {
      setItens({
        itensFiltrados: currentItens,
        itensFiltradosFiltrados: currentFilteredItens,
      });
      return;
    }

    setItens({
      itensFiltrados: currentItens.filter(
        (item) => item.ordemCronologica === naturezaContratacao
      ),
      itensFiltradosFiltrados: currentFilteredItens.filter(
        (item) => item.ordemCronologica === naturezaContratacao
      ),
    });
  }, [naturezaContratacao, currentItens, currentFilteredItens]);

  return (
    <Table
      responsive
      style={loading ? { display: "none" } : { marginTop: "-10px" }}
    >
      <thead>
        <tr>
          <TituloTable>Número</TituloTable>
          <TituloTable>Data</TituloTable>
          <TituloTable>Código NR</TituloTable>
          <TituloTable>Descrição NR</TituloTable>
          <TituloTable>Devedor</TituloTable>
          <TituloTable>Valor</TituloTable>
          <TituloTableAction>Ação</TituloTableAction>
        </tr>
      </thead>
      <BodyTable>
        {searchTerm
          ? itens?.itensFiltradosFiltrados?.map((item, index) => {
              const user = optionsCpf.find(
                (option) => option.id === parseInt(item.fornecedor)
              );
              return (
                <tr key={index}>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      display: "flex",
                      alignItems: "center",
                    }}
                    title={item.numero}
                  >
                    <NavLink
                      style={{
                        width: "65px",
                        textDecoration: "none",
                        color: "inherit",
                      }}
                      to={`/contabilidade/despesa/empenho-direto/liquidacao-de-empenho/${item.id}`}
                    >
                      {item.numero}
                    </NavLink>
                    <div>
                      <IconHistory
                        title={item.descricao || "Descrição não Disponível"}
                      />
                    </div>
                  </td>
                  <td
                    style={{ whiteSpace: "nowrap" }}
                    title={new Date(item.data).toLocaleDateString("pt-BR", {
                      timeZone: "UTC",
                    })}
                  >
                    {new Date(item.data).toLocaleDateString("pt-BR", {
                      timeZone: "UTC",
                    })}
                  </td>
                  <td
                    title={
                      item?.fichaInfo[0]?.despesaFixadaQDD?.fichasQDD
                        ?.naturezaDaDespesa?.codigo
                    }
                  >
                    {
                      item?.fichaInfo[0]?.despesaFixadaQDD?.fichasQDD
                        ?.naturezaDaDespesa?.codigo
                    }
                  </td>
                  <td
                    title={
                      item?.fichaInfo[0]?.despesaFixadaQDD?.fichasQDD
                        ?.naturezaDaDespesa?.funcao
                    }
                  >
                    {item?.fichaInfo[0]?.despesaFixadaQDD?.fichasQDD
                      ?.naturezaDaDespesa?.funcao.length > 20
                      ? `${item?.fichaInfo[0]?.despesaFixadaQDD?.fichasQDD?.naturezaDaDespesa?.funcao.slice(
                          0,
                          60
                        )}...`
                      : item?.fichaInfo[0]?.despesaFixadaQDD?.fichasQDD
                          ?.naturezaDaDespesa?.funcao}
                  </td>
                  <td
                    style={{ whiteSpace: "nowrap" }}
                    title={`${user?.cpf} - ${user?.nome}`}
                  >
                    {user?.nome.length > 25
                      ? user?.nome.slice(0, 25) + "..."
                      : user?.nome ||
                        `Fornecedor ${item.fornecedor} não encontrado`}
                  </td>
                  <td
                    style={{ whiteSpace: "nowrap" }}
                    title={`R$ ${formatToCurrency(item?.valor)}`}
                  >
                    {`R$ ${formatToCurrency(item?.valor)}`}
                  </td>
                  <td>
                    <IconAdicionar
                      onClick={() =>
                        navigate(
                          `/contabilidade/despesa/empenho-direto/liquidacao-de-empenho/${item.id}`
                        )
                      }
                    />
                  </td>
                </tr>
              );
            })
          : itens?.itensFiltrados?.map((item, index) => {
              const user = optionsCpf.find(
                (option) => option.id === parseInt(item.fornecedor)
              );
              return (
                <tr key={index}>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      display: "flex",
                      alignItems: "center",
                    }}
                    title={item.numero}
                  >
                    <NavLink
                      style={{
                        width: "65px",
                        textDecoration: "none",
                        color: "inherit",
                      }}
                      to={`/contabilidade/despesa/empenho-direto/liquidacao-de-empenho/${item.id}`}
                    >
                      {item.numero}
                    </NavLink>
                    <div>
                      <IconHistory
                        title={item.descricao || "Descrição não Disponível"}
                      />
                    </div>
                  </td>
                  <td
                    style={{ whiteSpace: "nowrap" }}
                    title={new Date(item.data).toLocaleDateString("pt-BR", {
                      timeZone: "UTC",
                    })}
                  >
                    {new Date(item.data).toLocaleDateString("pt-BR", {
                      timeZone: "UTC",
                    })}
                  </td>
                  <td
                    title={
                      item?.fichaInfo[0]?.despesaFixadaQDD?.fichasQDD
                        ?.naturezaDaDespesa?.nome
                    }
                  >
                    {
                      item?.fichaInfo[0]?.despesaFixadaQDD?.fichasQDD
                        ?.naturezaDaDespesa?.codigo
                    }
                  </td>
                  <td
                    title={
                      item?.fichaInfo[0]?.despesaFixadaQDD?.fichasQDD
                        ?.naturezaDaDespesa?.funcao
                    }
                  >
                    {item?.fichaInfo[0]?.despesaFixadaQDD?.fichasQDD
                      ?.naturezaDaDespesa?.funcao.length > 20
                      ? `${item?.fichaInfo[0]?.despesaFixadaQDD?.fichasQDD?.naturezaDaDespesa?.funcao.slice(
                          0,
                          60
                        )}...`
                      : item?.fichaInfo[0]?.despesaFixadaQDD?.fichasQDD
                          ?.naturezaDaDespesa?.funcao}
                  </td>
                  <td
                    style={{ whiteSpace: "nowrap" }}
                    title={`${user?.cpf} - ${user?.nome}`}
                  >
                    {user?.nome.length > 25
                      ? user?.nome.slice(0, 25) + "..."
                      : user?.nome ||
                        `Fornecedor ${item.fornecedor} não encontrado`}
                  </td>
                  <td
                    style={{ whiteSpace: "nowrap" }}
                    title={`R$ ${formatToCurrency(item?.valor)}`}
                  >
                    {`R$ ${formatToCurrency(item?.valor)}`}
                  </td>
                  <td>
                    <IconAdicionar
                      onClick={() =>
                        navigate(
                          `/contabilidade/despesa/empenho-direto/liquidacao-de-empenho/${item.id}`
                        )
                      }
                      style={{ marginTop: "-4px" }}
                    />
                  </td>
                </tr>
              );
            })}
      </BodyTable>
    </Table>
  );
};

export default TableALiquidar;
