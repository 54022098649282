import * as Style from "./style";

export const CheckBox = ({  label, value, checked, onChange }) => {
  return (
    <Style.CustomCheckBox
      label={label}
      value={value}
      checked={checked}
      onChange={onChange}
    />
  );
};
