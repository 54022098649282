
import ButtonDropOrHover from "./ButtonDropOrHover";
import { NavLinksMenores, TitulosNavs } from "./SideBarComponent";
import { LineDivider } from "../LineDivider";

const arrayDropdownsPrincipais = [
  {
    titulo: "Receita",
    options: [
      {
        titulo: "Guia de Receita",
        path: "/contabilidade/receita/guia-de-receita/consulta",
        activePath: "guia-de-receita",
      },
      {
        titulo: "Anulação de Receita",
        path: "/contabilidade/receita/anulacao-de-receita/consulta",
        activePath: "anulacao-de-receita",
      },
    ],
    icon: "fluent:document-arrow-right-20-filled",
  },
  {
    titulo: "Despesa",
    options: [
      {
        titulo: "Nota de Empenho",
        path: "/contabilidade/despesa/consulta/nota-de-empenho",
        activePath: "nota-de-empenho",
      },
      {
        titulo: "Liquidação de Empenho",
        path: "/contabilidade/despesa/consulta/liquidacao-de-empenho",
        activePath: "liquidacao-de-empenho",
      },
      {
        titulo: "Anulação da Despesa",
        path: "/contabilidade/despesa/consulta/anulacao-da-despesa",
        activePath: "anulacao-da-despesa",
      },
      {
        titulo: "Atualização Orçamentária",
        path: "/contabilidade/despesa/atualizacao-orcamentaria",
        activePath: "atualizacao-orcamentaria",
      },
    ],
    icon: "fluent:document-arrow-left-20-filled",
  },

  {
    titulo: "Extra",
    options: [
      {
        titulo: "GRE - Receita",
        path: "/contabilidade/extra/gre-receita/consulta",
        activePath: "gre-receita",
      },
      {
        titulo: "GDE - Despesa",
        path: "/contabilidade/extra/gde-despesa/consulta",
        activePath: "gde-despesa",
      },
      {
        titulo: "Anulação Extra",
        path: "/contabilidade/extra/anulacao-extra/cadastro",
        activePath: "anulacao-extra",
      },
      {
        titulo: "QDRDE",
        path: "/contabilidade/extra/qdrde/consulta",
        activePath: "qdrde",
      },
    ],
    icon: "fluent:document-chevron-double-20-filled",
  },

  {
    titulo: "Restos a Pagar",
    options: [
      {
        titulo: "GRP - Despesa",
        path: "/contabilidade/restos-a-pagar/grp-despesa",
        activePath: "grp-despesa",
      },
      {
        titulo: "Liquidação RP",
        path: "/contabilidade/restos-a-pagar/liquidacao-rp",
        activePath: "liquidacao-rp",
      },
      {
        titulo: "Anulação RP",
        path: "/contabilidade/restos-a-pagar/anulacao-rp",
        activePath: "anulacao-rp",
      },
      {
        titulo: "Inscrição RP",
        path: "/contabilidade/restos-a-pagar/inscricao-rp",
        activePath: "inscricao-rp",
      },
    ],
    icon: "fluent:document-link-24-filled",
  },

  {
    titulo: "Financeiro",
    options: [
      {
        titulo: "Pagamento",
        path: "/contabilidade/financeiro/pagamento",
        activePath: "pagamento",
      },
      {
        titulo: "Conciliação",
        path: "/contabilidade/financeiro/conciliacao",
        activePath: "conciliacao",
      },
      {
        titulo: "Anulação Pagamento",
        path: "/contabilidade/financeiro/anulacao-pagamento",
        activePath: "anulacao-pagamento",
      },
      {
        titulo: "Conta Bancária",
        path: "/contabilidade/financeiro/conta-bancaria/consulta",
        activePath: "conta-bancaria",
      },
    ],
    icon: "fluent:document-checkmark-20-filled",
  },
];

const ContabilidadeNav = ({ checkSidebar, scrollToBottom }) => {
  return (
    <>
      {/* <NavLink to={"/contabilidade/inicio"} className={"link_direto"}>
        <Icon icon="ion:home" width={25} />
        <span style={{ marginLeft: "3px" }}>Início</span>
      </NavLink> */}
      <ButtonDropOrHover
        checkSidebar={checkSidebar}
        tituloButton="Início"
        iconSrc={"ion:home"}
        titleRoutePath="/contabilidade/inicio"
      />
      {arrayDropdownsPrincipais.map((item, index) => (
        <ButtonDropOrHover
          key={index}
          checkSidebar={checkSidebar}
          tituloButton={item.titulo}
          navOptions={item.options}
          iconSrc={item.icon}
        />
      ))}
      {/* <NavLink to={"/contabilidade/pcasp"} className={"link_direto"}>
        <Icon icon="uiw:dot-chart" width={22} style={{ marginLeft: "2px" }} />
        <span style={{ marginLeft: "4px" }}>PCASP</span>
      </NavLink> */}
      <ButtonDropOrHover
        checkSidebar={checkSidebar}
        tituloButton="PCASP"
        iconSrc={"uiw:dot-chart"}
        titleRoutePath="/contabilidade/pcasp"
      />

      {!checkSidebar && <TitulosNavs>Recorrentes</TitulosNavs>}
      {checkSidebar && (
        <LineDivider style={{ marginTop: "12px", height: "4px" }} />
      )}
      <NavLinksMenores>
        {/* <NavLink to={"/contabilidade/cadastros/eva"} className={"link_direto"}>
          <Icon icon="tabler:copy-plus-filled" width={25} />
          <span style={{ marginLeft: "3px" }}>EVA</span>
        </NavLink> */}
        <ButtonDropOrHover
          checkSidebar={checkSidebar}
          tituloButton="EVA"
          iconSrc={"tabler:copy-plus-filled"}
          titleRoutePath="/contabilidade/cadastros/eva"
        />
        {/* <NavLink to={"/contabilidade/cadastros/evb"} className={"link_direto"}>
          <Icon icon="tabler:copy-plus" width={25} />
          <span style={{ marginLeft: "3px" }}>EVB</span>
        </NavLink> */}
        <ButtonDropOrHover
          checkSidebar={checkSidebar}
          tituloButton="EVB"
          iconSrc={"tabler:copy-plus"}
          titleRoutePath="/contabilidade/cadastros/evb"
        />
      </NavLinksMenores>

      {!checkSidebar && <TitulosNavs>Impressos</TitulosNavs>}
      {checkSidebar && (
        <LineDivider style={{ marginTop: "12px", height: "4px" }} />
      )}
      <NavLinksMenores>
        {/* <NavLink to={"/contabilidade/relatorios"} className={"link_direto"}>
          <Icon icon="gg:loadbar-doc" width={25} />
          <span style={{ marginLeft: "3px" }}>Relatórios</span>
        </NavLink> */}
        <ButtonDropOrHover
          checkSidebar={checkSidebar}
          tituloButton="Relatórios"
          iconSrc={"gg:loadbar-doc"}
          titleRoutePath="/contabilidade/relatorios"
        />
        {/* <NavLink
          to={"/contabilidade/leis-e-documentos"}
          className={"link_direto"}
        >
          <Icon icon="mdi:file-document-box-check-outline" width={25} />
          <span style={{ marginLeft: "3px" }}>Leis e Documentos</span>
        </NavLink> */}
        <ButtonDropOrHover
          checkSidebar={checkSidebar}
          tituloButton="Leis e Documentos"
          iconSrc={"mdi:file-document-box-check-outline"}
          titleRoutePath="/contabilidade/leis-e-documentos"
        />
      </NavLinksMenores>

      {!checkSidebar && <TitulosNavs>Ferramentas</TitulosNavs>}
      {checkSidebar && (
        <LineDivider style={{ marginTop: "12px", height: "4px" }} />
      )}
      <ButtonDropOrHover
        scrollToBottom={scrollToBottom}
        smallerType
        checkSidebar={checkSidebar}
        tituloButton={"Cadastros"}
        iconSrc={"ic:round-addchart"}
        navOptions={[
          {
            titulo: "QDR - Receita",
            path: "/contabilidade/cadastros/qdr-receita/consulta",
            activePath: "qdr-receita",
          },
          {
            titulo: "QDD - Despesa",
            path: "/contabilidade/consulta/qdd-despesa",
            activePath: "qdd-despesa",
          },
          {
            titulo: "Fonte de Recursos",
            path: "/contabilidade/cadastros/fonte-de-recursos/consulta",
            activePath: "fonte-de-recursos",
          },
          {
            titulo: "Licitação",
            path: "/contabilidade/cadastros/licitacao/consulta",
            activePath: "licitacao",
          },
          {
            titulo: "Divida Pública",
            path: "/contabilidade/cadastros/divida-publica",
            activePath: "divida-publica",
          },
          {
            titulo: "Garantias Concedidas",
            path: "/contabilidade/cadastros/garantias-concedidas",
            activePath: "garantias-concedidas",
          },
          {
            titulo: "Obra",
            path: "/contabilidade/cadastros/obras/consulta",
            activePath: "obras",
          },
          {
            titulo: "Produtos - Aquisição",
            path: "/contabilidade/cadastros/produtos-aquisicao/consulta ",
            activePath: "produtos-aquisicao",
          },
          {
            titulo: "CPF - CNPJ",
            path: "/contabilidade/cadastros/cpf-cnpj/consulta",
            activePath: "cpf-cnpj",
          },
          {
            titulo: "Retenção",
            path: "/contabilidade/consulta/retencao",
            activePath: "retencao",
          },
          {
            titulo: "Dívida Pública",
            path: "/contabilidade/cadastros/divida-publica",
            activePath: "divida-publica",
          },
          {
            titulo: "Convênio",
            path: "/contabilidade/cadastros/convenio/consulta",
            activePath: "convenio",
          },
          {
            titulo: "Natureza de Rendimentos",
            path: "/contabilidade/cadastros/natureza-de-rendimentos",
            activePath: "natureza-de-rendimentos",
          },
        ]}
      />
      <ButtonDropOrHover
      smallerType
          checkSidebar={checkSidebar}
          tituloButton="Utilitários"
          iconSrc={"carbon:gui-management"}
          titleRoutePath="/contabilidade/cadastro/utilitarios/"
        />
      {/* <NavLinksMenores>
        <NavLink
          to={"/contabilidade/cadastro/utilitarios/"}
          className={"link_direto"}
        >
          <Icon
            icon={ "carbon:gui-management"}
            width={25}
          />
          <span style={{ marginLeft: "3px" }}>Utilitários</span>
        </NavLink>
        
      </NavLinksMenores> */}
    </>
  );
};

export default ContabilidadeNav;
