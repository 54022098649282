import * as postRetencao from "./post";
import * as getRetencao from "./gets";
import * as putRetencao from "./put";
import * as deleteRetencao from './delete'

export const RetencaoService = {
  ...postRetencao,
  ...getRetencao,
  ...putRetencao,
  ...deleteRetencao
};
