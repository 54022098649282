import React, { useEffect, useState } from "react";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import { toast } from "react-toastify";

const LiquidacaoAba = ({
  data,
  optionsCpf,
  handleAddNewCpf,
  tipoEmpenho,
  valor,
  liquidacaoData,
  setLiquidacaoData,
  elementoDespesaCodigo,
}) => {
  const [inputFornecedor, setInputFornecedor] = useState();

  const hasAgrupamentoFolha = ["01", "03", "04", "11", "16"];
  const hasNotaFiscal = ["1", "2", "3", "6", "7", "8", "13", "15", "16", "17"];

  const handleSelectFornecedor = (option) => {
    console.log(option);
    setLiquidacaoData((prevValues) => ({
      ...prevValues,
      fornecedor: option?.id,
      fornecedorData: option?.cpf.replace(/\D/g, ""),
      fornecedorAtt: !prevValues.fornecedorAtt,
    }));
  };

  const formatCurrency = (value) => {
    // Remove qualquer caractere não numérico
    const numericValue = value.replace(/[^0-9]/g, "");

    // Remove os zeros à esquerda
    const trimmedValue = numericValue.replace(/^0+/, "");

    // Adiciona zeros à esquerda para garantir que tenhamos pelo menos 3 dígitos
    const paddedValue = trimmedValue.padStart(3, "0");

    // Divide os últimos dois dígitos do restante
    const cents = paddedValue.slice(-2);
    const integerPart = paddedValue.slice(0, -2);

    // Adiciona pontos a cada três dígitos na parte inteira
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      "."
    );

    // Formata o valor no estilo brasileiro
    const formattedValue = `${formattedIntegerPart || "0"},${cents}`;

    return formattedValue;
  };

  useEffect(() => {
    let valorNumerico = parseFloat(
      liquidacaoData.valor.replace(/\./g, "").replace(",", ".")
    );

    let valorComparacaoNumerico = parseFloat(
      valor.replace(/\./g, "").replace(",", ".")
    );

    if (valorNumerico > valorComparacaoNumerico) {
      valorNumerico = valorComparacaoNumerico;
    }

    // Converte o valorAtual novamente para o formato de string (com a vírgula)
    const valorCorrigido = valorNumerico.toFixed(2).replace(".", ",");

    // Atualiza o estado com o valorAtual corrigido
    setLiquidacaoData((prevValues) => ({
      ...prevValues,
      valor: formatCurrency(valorCorrigido),
    }));
    // setLiquidacaoData((prevValues) => ({
    //   ...prevValues,
    //   valor: valor,
    // }));
    // eslint-disable-next-line
  }, [valor]);

  useEffect(() => {
    let valorNumerico = parseFloat(
      liquidacaoData.valor.replace(/\./g, "").replace(",", ".")
    );

    let valorComparacaoNumerico = parseFloat(
      valor.replace(/\./g, "").replace(",", ".")
    );

    console.log(valorNumerico, valorComparacaoNumerico, tipoEmpenho);

    if (
      valorNumerico === valorComparacaoNumerico &&
      valorNumerico > 0 &&
      tipoEmpenho !== "1 - Ordinário"
    ) {
      toast.warn(
        "O valor da liquidação Global ou Estimativo não pode ser igual ao valor do empenho!"
      );
    }
    // eslint-disable-next-line
  }, [liquidacaoData.valor, tipoEmpenho]);

  console.log("AQUIII: ", elementoDespesaCodigo);

  return (
    <>
      <RowForm className="mt-1">
        <CCol md={2}>
          <FloatingLabelInput placeholder={"Empenho"} disabled />
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput placeholder={"Liquidação"} disabled />
        </CCol>
        <CCol>
          <MyAutoComplete
            labelInput="Responsável pelo Atesto"
            options={optionsCpf}
            inputValue={inputFornecedor}
            setInputValue={setInputFornecedor}
            addNewDataFunction={handleAddNewCpf}
            labelFormat={(item) => `${item.nome} - ${item.cpf}`}
            style={{ height: "37px" }}
            onOptionSelect={(option) => handleSelectFornecedor(option)}
          />
        </CCol>
        <CCol md={1} style={{ width: "14%" }}>
          <InputData disabled value={data} />
        </CCol>
        <CCol md={1} style={{ width: "14%" }}>
          <InputFormatRealBrasileiro
            value={
              tipoEmpenho === "1 - Ordinário" ? valor : liquidacaoData.valor
            }
            disabled={tipoEmpenho === "1 - Ordinário"}
            onChange={(_, valorAtual) => {
              // Converte o valorAtual digitado para número
              let valorNumerico = parseFloat(
                valorAtual.replace(/\./g, "").replace(",", ".")
              );

              let valorComparacaoNumerico = parseFloat(
                valor.replace(/\./g, "").replace(",", ".")
              );

              // Se o valorAtual for maior que somaValores, ajusta para somaValores
              if (valorNumerico > valorComparacaoNumerico) {
                valorNumerico = valorComparacaoNumerico;
              }

              // Converte o valorAtual novamente para o formato de string (com a vírgula)
              const valorCorrigido = valorNumerico.toFixed(2).replace(".", ",");

              // Atualiza o estado com o valorAtual corrigido
              setLiquidacaoData((prevValues) => ({
                ...prevValues,
                valor: formatCurrency(valorCorrigido),
              }));
            }}
            putDot
          />
        </CCol>
      </RowForm>

      {hasAgrupamentoFolha.includes(elementoDespesaCodigo) && (
        <RowForm style={{ marginTop: "-14px", marginBottom: "-5px" }}>
          <CCol>
            <FloatingLabelInput
              // disabled={liquidacaoAtualId}
              label="Agrupamento Folha"
              placeholder="Agrupamento Folha"
              value={"Agrupamento Folha"}
            />
          </CCol>
        </RowForm>
      )}
      {hasNotaFiscal.includes(elementoDespesaCodigo) && (
        <>
          <RowForm style={{ marginTop: "-15px" }}>
            <CCol>
              <FloatingLabelInput
                // disabled={liquidacaoAtualId}
                label="Número da chave de acesso da NF-e"
                placeholder="Número da chave de acesso da NF-e"
                value={"Número da chave de acesso da NF-e"}
              />
            </CCol>
            <CCol>
              <FloatingLabelInput
                // disabled={liquidacaoAtualId}
                label="Tipo Nota Fiscal"
                placeholder="Tipo Nota Fiscal"
                value={"Tipo Nota Fiscal"}
              />
            </CCol>
          </RowForm>
          <RowForm style={{ marginTop: "-15px" }}>
            <CCol>
              <FloatingLabelInput
                // disabled={liquidacaoAtualId}
                label="Número Nota Fiscal"
                placeholder="Número Nota Fiscal"
                value={"Número Nota Fiscal"}
              />
            </CCol>
            <CCol md={2}>
              <FloatingLabelInput
                // disabled={liquidacaoAtualId}
                label="Série NF"
                placeholder="Série NF"
                value={"Série NF"}
              />
            </CCol>
            <CCol md={2}>
              <InputData

              // disabled={liquidacaoAtualId}
              />
            </CCol>
            <CCol>
              <InputFormatRealBrasileiro

              // disabled={liquidacaoAtualId}
              />
            </CCol>
          </RowForm>
        </>
      )}
    </>
  );
};

export default LiquidacaoAba;
