import React, { useEffect, useImperativeHandle, forwardRef } from "react";
import { Row } from "react-bootstrap";
import { CCol } from "./CCol";
import { OptionsStyle } from "../../pages/Paginas_Sistemas_Planejamento/Cadastros/NaturezaDaReceita/NaturezaDaReceita";

const RowSelecaoAbasInferior = forwardRef(
  (
    { abas, abaSelecionada, setAbaSelecionada, style, alwaysSelectFirst },
    ref
  ) => {
    useEffect(() => {
      if (alwaysSelectFirst && abas.length > 0) {
        setAbaSelecionada({ nomeAba: abas[0], numeroAba: 0 });
      }
      // eslint-disable-next-line
    }, [abas, alwaysSelectFirst]);

    const selecionarProximaAba = () => {
      const nextIndex =
        abaSelecionada.numeroAba < abas.length - 1
          ? abaSelecionada.numeroAba + 1
          : 0; // Volta para a primeira aba se estiver na última
      setAbaSelecionada({ nomeAba: abas[nextIndex], numeroAba: nextIndex });
    };

    const selecionarAbaAnterior = () => {
      const prevIndex =
        abaSelecionada.numeroAba > 0
          ? abaSelecionada.numeroAba - 1
          : abas.length - 1; // Vai para a última aba se estiver na primeira
      setAbaSelecionada({ nomeAba: abas[prevIndex], numeroAba: prevIndex });
    };

    // Expondo as funções para o componente externo
    useImperativeHandle(ref, () => ({
      selecionarProximaAba,
      selecionarAbaAnterior,
    }));

    return (
      <Row style={style}>
        <CCol className="d-flex align-items-center row_endereco_aba mt-1">
          {abas.map((aba, index) => (
            <OptionsStyle
              onClick={() =>
                setAbaSelecionada({ nomeAba: aba, numeroAba: index })
              }
              selecionado={
                abaSelecionada.numeroAba === index ||
                abaSelecionada.nomeAba === aba
              }
            >
              {aba}
            </OptionsStyle>
          ))}
        </CCol>
      </Row>
    );
  }
);

export default RowSelecaoAbasInferior;
