import React from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { Row } from "react-bootstrap";
import { Icon } from "@iconify/react/dist/iconify.js";
import { PTitulosInputs } from "../../../../styles/StyledComponents/ListaDeInputs";
import { FormControlListaDeInputs } from "../../../../styles/StyledComponents/ListaDeInputs";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { SpanTotalPorFonte } from "../../../Paginas_Sistemas_Planejamento/LOA_QDD/StyledComponentsQDD";

const mapToMap = [0];

const RetencaoAba = () => {
  return (
    <>
      <Row className={"row_form_2"} style={{ marginTop: "3px" }}>
        <RowAninhada
          style={{
            margin: "0px",
            marginTop: "5px",
            padding: "0px",
          }}
        >
          <CCol>
            <PTitulosInputs>Desconto</PTitulosInputs>
            <FloatingLabelInput
              placeholder={"Desconto"}
              style={{ height: "30px" }}
              radiusborder={mapToMap.length > 0 && "5px 5px 0 0"}
            />
          </CCol>
          <CCol md={2}>
            <PTitulosInputs>Valor</PTitulosInputs>
            <InputFormatRealBrasileiro
              listaDeInputs={true}
              style={{ textAlign: "end", fontSize: "15px" }}
              radiusborder={mapToMap.length > 0 && "5px 5px 0 0"}
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
            <Icon
              icon="ic:baseline-add-box"
              color="#105200"
              height="28"
              style={{
                cursor: "pointer",
                marginLeft: "0px",
                marginTop: "1px",
              }}
            />
          </CColButtonsAcoes2Icons>
        </RowAninhada>
        {mapToMap.map((index) => (
          <RowAninhada
            style={{
              padding: "0px",
              margin: "0px",
              marginTop: "-1px",
            }}
            key={index}
          >
            <CCol>
              <FormControlListaDeInputs
                style={{ fontSize: "15px" }}
                radiusborder={
                  index === mapToMap.length - 1 ? "0 0 5px 5px" : "0"
                }
                disabled
              />
            </CCol>
            <CCol md={2}>
              <InputFormatRealBrasileiro
                listaDeInputs={true}
                style={{ fontSize: "15px", textAlign: "right" }}
                onKeyDown={(e) => {}}
                radiusborder={
                  index === mapToMap.length - 1 ? "0 0 5px 5px" : "0"
                }
                disabled
              />
            </CCol>
            <CColButtonsAcoes2Icons md={1}>
              <Icon
                icon="bx:edit"
                color="#5971C8"
                height="26"
                style={{
                  cursor: "pointer",
                  marginLeft: "2px",
                  marginTop: "3px",
                }}
              />
              <Icon
                icon="ic:baseline-delete"
                color="#E79900"
                height="28"
                style={{
                  cursor: "pointer",
                  marginLeft: "1px",
                  marginTop: "2px",
                }}
              />
            </CColButtonsAcoes2Icons>
          </RowAninhada>
        ))}
        <RowAninhada
          style={{
            padding: "0px",
            margin: "0px",
            marginTop: "9px",
          }}
        >
          <CCol md={/*selfArray.length - 1 === indexDespesa ? 3 : 7*/ 7}></CCol>
          <CCol
            md={2}
            style={{
              textAlign: "center",
              marginTop: "4px",
            }}
          >
            <SpanTotalPorFonte>Total por Fonte</SpanTotalPorFonte>
          </CCol>
          <CCol>
            <FormControlListaDeInputs
              style={{
                borderRadius: "10px",
                padding: "15px",
                border: "none",
                textAlign: "end",
              }}
              value={`R$ 10.000,00`}
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1} />
        </RowAninhada>
      </Row>
    </>
  );
};

export default RetencaoAba;
