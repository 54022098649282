import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  CCol,
  ContainerToModal,
  RowForm,
  RowToModal,
} from "../../../../components/Grid/CCol";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import styled from "styled-components";
import InputData from "../../../../components/Grid/InputData";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { OptionsStyle } from "../../../Paginas_Sistemas_Planejamento/Cadastros/NaturezaDaReceita/NaturezaDaReceita";
import HistoricoAba from "./HistoricoAba";
import FonteDeRecursosAba from "./FonteDeRecursosAba";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { useCurrentYear } from "../../../../hooks/useCurrentYear";
import api from "../../../../utils/api";
import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";
import { toast, ToastContainer } from "react-toastify";
import AutoCompleteFichas from "../../../../components/Inputs/AutoCompleteFichas";
import { useLocation, useParams } from "react-router-dom";
import { getGuiaDeReceitaPorId } from "../../../../services/guiaDeReceita/gets";
import { optionsTipoLancamento } from "./optionsTipoLancamento";
import { ProcedimentoContabel } from "../../Components/ProcedimentoContabel";
import { CustomToast } from "../../../../components/CustomToast";
import {
  checkIsValideData,
  formatDateToISO,
} from "../../../../utils/FormatacaoDeDados/FormatDateToRegister";
import { completeWithZerosDocFinanceiro } from "../../../../utils/FormatacaoDeDados/CompleteWithZerosDocFinanceiro";
import { validateDescription } from "../../../../utils/Gerais/validateDescription";
import { formatLabelContaBancaria } from "../../../../utils/FormatacaoDeDados/FormatLabelContaBancaria";

const CColNumeroAndEVB = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const CColRemessa = styled(CCol)`
  @media (min-width: 768px) {
    width: 20%;
  }
`;

const optionsAba = [
  {
    label: "Histórico",
    optionId: 1,
  },
  {
    label: "Fonte de Recursos",
    optionId: 2,
  },
  {
    label: "Procedimentos Contábeis",
    optionId: 3,
  },
];

const ReceitaOrcamentaria = () => {
  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useCurrentYear();

  const { id: paramsId } = useParams();

  const location = useLocation();

  const hasRouteReplica = location.pathname.includes("replicar");

  const currentDate = () => {
    const date = new Date();
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");

    return `${day}/${month}/${currentYear}`;
  };

  const inputNaturezaRef = useRef(null);
  const inputTipoLancamentoRef = useRef(null);
  const inputDateRef = useRef(null);
  const inputDevedorRef = useRef(null);
  const inputCaixaRef = useRef(null);
  const inputDocFinanceiroRef = useRef(null);
  const inputValorRef = useRef(null);
  const inputHistoricoRef = useRef(null);

  const [isOpenToast, setIsOpenToast] = useState(false);
  const [inputNatRec, setInputNatRec] = useState("");
  const [inputDevedor, setInputDevedor] = useState("");
  const [inputCaixa, setInputCaixa] = useState("");
  const [inputFonteRec, setInputFonteRec] = useState("");

  const [valuesReceitaOrcamentaria, setValuesReceitaOrcamentaria] = useState({
    clienteId: currentClient.clienteId,
    numero: "",
    evb: "",
    naturezaId: null,
    descricaoNatureza: "",
    contaBancariaId: null,
    dataCriacao: currentDate(),
    tipoDeLancamento: 0,
    descricao: "",
    devedorId: null,
    docFinanceiro: "",
    valor: 0,
    fontes: [],
  });
  // const [descricaoNatRec, setDescricaoNatRec] = useState("");
  const [fontesDeRecursos, setFontesDeRecursos] = useState([
    // {
    //   clienteId: 0,
    //   fonteId: 0,
    //   valor: 0
    // }
  ]);
  const [fonteDeRecursoAdicionar, setFonteDeRecursoAdicionar] = useState({
    clienteId: currentClient.clienteId,
    fonteId: 0,
    valor: 0,
  });

  const [optionAbaSelected, setOptionAbaSelected] = useState(
    optionsAba[0].optionId
  );

  const [optionsNaturezaDaReceita, setOptionsNaturezaDaReceita] = useState([]);
  const [optionsDevedor, setOptionsDevedor] = useState([]);
  const [optionsFontesDeRecursos, setOptionsFontesDeRecursos] = useState([]);
  const [optionsCaixaEquivalenteDeCaixa, setOptionsCaixaEquivalenteDeCaixa] =
    useState([]);

  const [filteredOptionsFonteRec, setFilteredOptionsFonteRec] = useState([]);
  const [
    filteredOptionsCaixaEquivalenteDeCaixa,
    setFilteredOptionsCaixaEquivalente,
  ] = useState([]);

  // const handleFilterOptionsFonteRec = (contaBancariaId) => {
  //   const naturezaOption = optionsNaturezaDaReceita.find(
  //     (item) => item.id === contaBancariaId
  //   );
  //   console.log(naturezaOption);
  //   console.log(optionsNaturezaDaReceita);
  //   console.log(contaBancariaId);

  //   const fichaKey = naturezaOption.fichas ? "fichas" : "ficha";
  //   let fontesVinculadasANaturezaIds = [];

  //   if (fichaKey === "ficha") {
  //     //parou aqui
  //   } else {
  //     fontesVinculadasANaturezaIds = naturezaOption[fichaKey].map(
  //       (item) => item.fonteId
  //     );
  //   }
  //   console.log(optionsFontesDeRecursos);

  //   const arrayFontesFiltradas = optionsFontesDeRecursos.filter((fonte) =>
  //     fontesVinculadasANaturezaIds.includes(fonte.id)
  //   );
  //   console.log(arrayFontesFiltradas);

  //   setFilteredOptionsFonteRec([...arrayFontesFiltradas]);
  // };

  const filterOptionsCaixa = useCallback(
    (dataGuiaReceita) => {
      const infosCaixa = optionsCaixaEquivalenteDeCaixa.filter(
        (item) => item.id === dataGuiaReceita.contaBancariaId
      );

      setFilteredOptionsCaixaEquivalente(infosCaixa);
    },
    [optionsCaixaEquivalenteDeCaixa]
  );

  const getDescriptionNatureza = (naturezaId) => {
    const infosNatureza = optionsNaturezaDaReceita.find(
      (natureza) => natureza.id === naturezaId
    );

    if (!infosNatureza) {
      toast.info("Não foi possível informar a descrição da Natureza.");
      return;
    }
    return infosNatureza.descricao;
  };

  const formattedDataGuiaReceitaById = useCallback(async () => {
    const dataGuiaReceita = await getGuiaDeReceitaPorId(paramsId, currentYear);
    if (!dataGuiaReceita) {
      toast.error(
        "Não foi possível buscar os dados. Tente novamente mais tarde."
      );
      return;
    }

    //filtrando as opcoes de fonte de recursos
    // handleFilterOptionsFonteRec(dataGuiaReceita.contaBancariaId);
    // essa função Engels fez mas n está funcionando
console.log('oi');

    const fontes = dataGuiaReceita.fontes.map((item) => ({
      clienteId: currentClient.clienteId,
      fonteId: item.fonteId,
      valor: hasRouteReplica ? "0,00" : item.valor,
    }));
    const fonteOptions = dataGuiaReceita.fontes.map((item) => ({
      id: item.fonteId,
      conta: item.info.conta,
      titulo: item.info.titulo,
    }));

    setFontesDeRecursos(fontes);
    setFilteredOptionsFonteRec(fonteOptions);

    const date = dataGuiaReceita.dataCriacao.split("T")[0];
    const [year, month, day] = date.split("-");
    const dateFormatted = `${day}/${month}/${year}`;

    setValuesReceitaOrcamentaria((prev) => ({
      ...prev,
      numero: hasRouteReplica ? "" : dataGuiaReceita.numero,
      naturezaId: dataGuiaReceita.naturezaId,
      descricaoNatureza: getDescriptionNatureza(dataGuiaReceita.naturezaId),
      tipoDeLancamento: dataGuiaReceita.tipoDeLancamento,
      dataCriacao: hasRouteReplica ? currentDate() : dateFormatted,
      contaBancariaId: dataGuiaReceita.contaBancariaId,
      devedorId: dataGuiaReceita.devedorId,
      descricao: dataGuiaReceita.descricao,
      docFinanceiro: dataGuiaReceita.docFinanceiro,
      valor: hasRouteReplica ? "0,00" : dataGuiaReceita.valor,
      fontes: dataGuiaReceita.fontes,
    }));

    setInputNatRec(
      `${dataGuiaReceita.naturezaInfo.nr} - ${dataGuiaReceita.naturezaInfo.especificacao}`
    );

    setInputDevedor(
      `${FormatCpfPaste(dataGuiaReceita.devedorInfo.cpf)} - ${
        dataGuiaReceita.devedorInfo.nomeCompleto
      }`
    );
    const formattedConta = formatLabelContaBancaria(
      dataGuiaReceita.contaBancariaInfo.numeroConta,
      dataGuiaReceita.contaBancariaInfo.digito
    );

    setInputCaixa(
      `${formattedConta} - ${dataGuiaReceita.contaBancariaInfo.nome}`
    );

    filterOptionsCaixa(dataGuiaReceita);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient.clienteId,
    currentYear,
    filterOptionsCaixa,
    hasRouteReplica,
    paramsId,
    // getDescriptionNatureza
  ]);

  
  useEffect(() => {
    setValuesReceitaOrcamentaria((prev) => ({
      ...prev,
      fontes: [...fontesDeRecursos],
    }));
  }, [fontesDeRecursos]);

  const fetchFontesDeRecursos = async () => {
    try {
      const resp = await api.get(`fonteDeRecurso/por-ano/${currentYear}`);
      const data = resp.data;
      const dataFilter = data.filter((item) => item.conta.length > 4);
console.log(dataFilter);

      setOptionsFontesDeRecursos(
        dataFilter.map((item) => ({
          id: item.id,
          conta: item.conta,
          titulo: item.titulo,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchContas = async () => {
    try {
      const resp = await api.get(
        `contaBancaria/cliente/${currentClient.clienteId}/${currentYear}`
      );
      const data = resp.data;

      console.log(data);
      const formattedOptionsCaixa = data.map((item) => ({
        id: item.id,
        nome: item.nome,
        numero: formatLabelContaBancaria(item.numeroConta, item.digito),
        fontesDeRecursos: item.fontesDeRecursos,
      }));
      console.log(formattedOptionsCaixa);

      setOptionsCaixaEquivalenteDeCaixa(formattedOptionsCaixa);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchNaturezasDaReceita = async () => {
    try {
      const resp = await api.get(
        `naturezaDaReceita/vinculo-qdr/${currentClient.clienteId}/${currentYear}`
      );
      const data = resp.data;

      const arrayDeNaturezas = data.map((item) => ({
        id: item.id,
        codigo: item.nr,
        titulo: item.titulo,
        descricao: item.descricao,
        fichas: [...item.fontes],
      }));

      let arrayDeFichas = [];

      data.forEach((item) => {
        const { fontes } = item;

        fontes.forEach((ficha) => {
          const object = {
            pill: "Ficha",
            id: item.id,
            codigo: item.nr,
            titulo: item.titulo,
            descricao: item.descricao,
            ficha: {
              ...ficha,
            },
          };

          arrayDeFichas.push(object);
        });
      });

      setOptionsNaturezaDaReceita([...arrayDeFichas, ...arrayDeNaturezas]);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOptionsDevedor = async () => {
    try {
      const resp = await api.get(`cpf/por-cliente/${currentClient.clienteId}`);
      const data = resp.data;

      setOptionsDevedor(
        data.map((item) => ({
          id: item.pessoaId,
          nome: item.nomeCompleto,
          cpf: FormatCpfPaste(item.cpf),
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (Object.keys(currentClient).length > 0 && currentYear) {
      fetchContas();
      fetchNaturezasDaReceita();
      fetchOptionsDevedor();
      fetchFontesDeRecursos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient, currentYear]);

  useEffect(() => {
    if (
      paramsId &&
      optionsFontesDeRecursos.length > 0 &&
      optionsNaturezaDaReceita.length > 0 &&
      optionsCaixaEquivalenteDeCaixa.length > 0
    ) {
      formattedDataGuiaReceitaById();
    }
  }, [
    paramsId,
    optionsFontesDeRecursos,
    optionsNaturezaDaReceita,
    optionsCaixaEquivalenteDeCaixa,
    formattedDataGuiaReceitaById,
  ]);

  useEffect(() => {
    if (inputNatRec.length === 0) {
      setValuesReceitaOrcamentaria((prev) => ({
        ...prev,
        naturezaId: null,
        contaBancariaId: null,
        valor: "0,00",
        descricaoNatureza: "",
      }));
      setInputCaixa("");
      setFilteredOptionsFonteRec([]);
      setFontesDeRecursos([]);
      // setDescricaoNatRec("");
    }

    if (inputCaixa.length === 0) {
      setValuesReceitaOrcamentaria((prev) => ({
        ...prev,
        contaBancariaId: null,
        valor: "0,00",
      }));
      setFilteredOptionsFonteRec([]);
      setFontesDeRecursos([]);
      setFonteDeRecursoAdicionar((prev) => ({
        ...prev,
        fonteId: 0,
      }));
      setInputFonteRec("");
    }

    if (inputDevedor.length === 0) {
      setValuesReceitaOrcamentaria((prev) => ({
        ...prev,
        devedorId: null,
      }));
    }
  }, [inputNatRec, inputCaixa, inputDevedor]);

  const handleSelectNatRec = (item) => {
    setValuesReceitaOrcamentaria((prev) => ({
      ...prev,
      naturezaId: item.id,
      descricaoNatureza: item.descricao,
    }));
    // setDescricaoNatRec(item.descricao);

    if (item.ficha) {
      console.log(item.ficha.fonteId);
      setFilteredOptionsCaixaEquivalente(
        optionsCaixaEquivalenteDeCaixa.filter((element) =>
          element.fontesDeRecursos.some(
            (fonte) => fonte.fonteId === item.ficha.fonteId
          )
        )
      );

      setValuesReceitaOrcamentaria((prev) => ({
        ...prev,
        tipoDeLancamento: item.ficha.tipo,
      }));
    } else {
      setValuesReceitaOrcamentaria((prev) => ({
        ...prev,
        tipoDeLancamento: 1,
      }));

      setFilteredOptionsCaixaEquivalente(
        optionsCaixaEquivalenteDeCaixa.filter((element) =>
          element.fontesDeRecursos.some((fonte) =>
            item.fichas.some((ficha) => ficha.fonteId === fonte.fonteId)
          )
        )
      );

      const fontesIds = item.fichas.map((ficha) => ficha.fonteId);
      const options = optionsFontesDeRecursos.filter((fonte) =>
        fontesIds.includes(fonte.id)
      );

      setFilteredOptionsFonteRec([...options]);
    }
    inputTipoLancamentoRef.current.focus();
  };

  const handleCaixaEquivalenteDeCaixaSelect = (item) => {
    console.log(item);
    console.log(optionsCaixaEquivalenteDeCaixa);
    
    setValuesReceitaOrcamentaria((prev) => ({
      ...prev,
      contaBancariaId: item.id,
    }));

    console.log(item.fontesDeRecursos);

    if (item.fontesDeRecursos.length === 1) {
      const fonteDeRecurso = item.fontesDeRecursos[0];

      const option = optionsFontesDeRecursos.find(
        (fonte) => fonte.id === fonteDeRecurso.fonteId
      );
      console.log(option);
      
      setFilteredOptionsFonteRec([option]);
      setFontesDeRecursos([
        {
          clienteId: currentClient.clienteId,
          fonteId: fonteDeRecurso.fonteId,
          valor: "0,00",
        },
      ]);
    } else if (item.fontesDeRecursos.length > 1) {
      const fontesIds = item.fontesDeRecursos.map((fonte) => fonte.fonteId);
      const options = optionsFontesDeRecursos.filter((option) =>
        fontesIds.includes(option.id)
      );

      setFilteredOptionsCaixaEquivalente([...options]);
    }
    inputDocFinanceiroRef.current.focus();
  };

  const handleSelectDevedor = (item) => {
    setValuesReceitaOrcamentaria((prev) => ({
      ...prev,
      devedorId: item.id,
    }));
    inputCaixaRef.current.focus();
  };

  const handleChangeValorReceita = (_, valor) => {
    const MAX_LENGTH_VALUE = 16;

    const truncatedValue = valor.slice(0, MAX_LENGTH_VALUE);

    setValuesReceitaOrcamentaria((prev) => ({
      ...prev,
      valor: truncatedValue,
    }));

    if (fontesDeRecursos.length === 1) {
      const fonte = fontesDeRecursos[0];
      fonte.valor = truncatedValue;
      setFontesDeRecursos([fonte]);
    }
  };

  const formattedFontesPost = (fontes) => {
    if (!fontes.length) return [];
    const arrayFontesToPost = fontes.map((item) => ({
      ...item,
      valor: parseFloat(item.valor.replace(/,/g, ".")),
    }));
    return arrayFontesToPost;
  };

  const validatedValuesRegister = () => {
    const infosReceita = {
      clienteId: valuesReceitaOrcamentaria.clienteId,
      naturezaId: valuesReceitaOrcamentaria.naturezaId,
      contaBancariaId: valuesReceitaOrcamentaria.contaBancariaId,
      tipoDeLancamento: valuesReceitaOrcamentaria.tipoDeLancamento,
      descricao: valuesReceitaOrcamentaria.descricao,
      devedorId: valuesReceitaOrcamentaria.devedorId,
      docFinanceiro: completeWithZerosDocFinanceiro(
        valuesReceitaOrcamentaria.docFinanceiro
      ),
      valor: parseFloat(valuesReceitaOrcamentaria.valor.replace(/,/g, ".")),
      dataCriacao: formatDateToISO(valuesReceitaOrcamentaria.dataCriacao),
      fontes: formattedFontesPost(valuesReceitaOrcamentaria.fontes),
    };

    console.log(infosReceita);

    const hasInfosCadastro = Object.values(infosReceita).every(
      (item) =>
        item !== "" &&
        item !== null &&
        item !== 0 &&
        String(item)?.trim() !== ""
    );

    if (!hasInfosCadastro) {
      toast.error("Informe todos os campos.");
      return;
    }

    const hasFontes = infosReceita.fontes.length;

    if (!hasFontes) {
      toast.error("Adicione uma Fonte de Recursos.");
      return;
    }
    const date = currentDate();
    const isValidDate = checkIsValideData(infosReceita.dataCriacao, date);

    if (!isValidDate && (!paramsId || hasRouteReplica)) {
      toast.info("Informe uma data válida.");
      return;
    }

    const isValidDescription = validateDescription(infosReceita.descricao);

    if (!isValidDescription) {
      return;
    }
    setIsOpenToast(true);
    return infosReceita;
  };

  const handleSave = () => {
    const infosReceita = validatedValuesRegister();
    if (!infosReceita) return;

    setIsOpenToast(true);
  };

  const saveRegisterToast = async () => {
    const infosReceita = validatedValuesRegister();

    if (!infosReceita) return;
    if (hasRouteReplica || !paramsId) {
      console.log(infosReceita);
      
      await postGuiaReceita(infosReceita);
      return;
    }
    await putGuiaReceita(infosReceita);
  };

  const resetFieldsCadastro = () => {
    setValuesReceitaOrcamentaria({
      clienteId: currentClient.clienteId,
      naturezaId: null,
      descricaoNatureza: "",
      contaBancariaId: null,
      dataCriacao: currentDate(),
      tipoDeLancamento: 0,
      descricao: "",
      devedorId: null,
      docFinanceiro: "",
      valor: 0,
      fontes: [],
    });

    setInputCaixa("");
    setInputDevedor("");
    setInputFonteRec("");
    setInputNatRec("");
  };

  const postGuiaReceita = async (infosReceita) => {
    try {
      const res = await api.post(`guiaDeReceita`, infosReceita);
      resetFieldsCadastro();
      toast.success(
        `Receita número ${res.data.nextNumber} cadastrada com sucesso.`
      );
      inputNaturezaRef.current.focus();
    } catch (error) {
      console.log(error);
      if (error.response.status === 400) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Algo deu errado. Por favor, tente mais tarde.");
      }
    }
  };

  const putGuiaReceita = async (infosReceita) => {
    toast.info("atualizar não implementado");
  };

  const onChangeTextDocFinanceiro = (value) => {
    const inputValue = value.replace(/\D/g, "");
    const maskedValue = inputValue.slice(0, 11);
    setValuesReceitaOrcamentaria((prev) => ({
      ...prev,
      docFinanceiro: maskedValue,
    }));
  };

  const handleKeyDown = async (e, nextRef) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      handleSave();
      return;
    }

    if (e.key === "Enter") {
      if (nextRef && nextRef.current) {
        e.preventDefault();
        nextRef.current.focus();
      }
    }
  };

  const handleFocus = () => {
    inputHistoricoRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  useEffect(() => {
    if (paramsId && !hasRouteReplica) return;
    if (hasRouteReplica) {
      inputValorRef.current.disabled = false;
      inputValorRef.current.focus();
      return;
    }
    inputNaturezaRef.current.focus();
  }, [hasRouteReplica, paramsId]);

  return (
    <ContainerToModal
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <ToastContainer />
      <CustomToast
        isOpenToast={isOpenToast}
        setIsOpenToast={setIsOpenToast}
        saveRegister={saveRegisterToast}
      />
      <HeaderCadastros
        PaginaConsulta={"/contabilidade/receita/guia-de-receita/consulta"}
        NomePaginaTitulo={"Orçamentário"}
        PaginaSubtitulo={"Receita"}
        ButtonSaveFunction={handleSave}
        RowTitle={"Receita Orçamentária"}
        BotaoNovoAtivo={false}
      />
      <RowToModal className="row_form mt-1">
        {/* <button onClick={debug}>debug</button> */}
        <CColNumeroAndEVB md={1}>
          <FloatingLabelInput
            placeholder="Número"
            label="Número"
            disabled
            value={valuesReceitaOrcamentaria.numero}
          />
        </CColNumeroAndEVB>
        <CColNumeroAndEVB md={1}>
          <MyAutoComplete
            labelInput={"EVB"}
            // ref={inputEvbRef}
            // inputValue={inputEvb}
            // setInputValue={setInputEvb}
            options={[{ label: "Teste", value: "teste" }]}
            labelFormat={(option) => `${option.label} - ${option.value}`}
            style={{ height: "38px" }}
            // handleNextInput={(e) => handleKeyDown(e, inputNaturezaRef)}
          />
        </CColNumeroAndEVB>
        <CCol>
          <AutoCompleteFichas
            ref={inputNaturezaRef}
            inputValue={inputNatRec}
            setInputValue={setInputNatRec}
            labelInput={"Natureza da Receita"}
            options={optionsNaturezaDaReceita}
            labelFormat={(option) => `${option.codigo} - ${option.titulo}`}
            style={{ height: "38px" }}
            onOptionSelect={handleSelectNatRec}
          />
        </CCol>
      </RowToModal>
      {valuesReceitaOrcamentaria.descricaoNatureza !== "" && (
        <RowForm style={{ marginTop: "-15px" }}>
          <CCol>
            <p
              style={{
                backgroundColor: "#F2F2F2",
                padding: "10px",
                borderRadius: "5px",
                color: "#515c70",
                fontWeight: "500",
              }}
            >
              {valuesReceitaOrcamentaria.descricaoNatureza}
            </p>
          </CCol>
        </RowForm>
      )}

      <RowToModal
        className="row_form"
        style={{
          marginTop: `${
            valuesReceitaOrcamentaria.descricaoNatureza !== ""
              ? "-30px"
              : "-15px"
          }`,
        }}
      >
        <CColRemessa md={1}>
          <FloatingLabelInput placeholder="Remessa" label="Remessa" disabled />
        </CColRemessa>
        <CCol md={7}>
          <FloatingLabelInput
            ref={inputTipoLancamentoRef}
            placeholder="Tipo de Lançamento"
            new
            value={valuesReceitaOrcamentaria.tipoDeLancamento}
            tipo="input_select"
            label="Tipo de Lançamento"
            onSelect={(option) => {
              setValuesReceitaOrcamentaria((prev) => ({
                ...prev,
                tipoDeLancamento: parseInt(option.value),
              }));
              inputDateRef.current.focus();
            }}
            options={optionsTipoLancamento}
          />
        </CCol>
        <CCol>
          <InputData
            label="data"
            ref={inputDateRef}
            externalValue={valuesReceitaOrcamentaria.dataCriacao}
            onChange={(e) =>
              setValuesReceitaOrcamentaria((prev) => ({
                ...prev,
                dataCriacao: e.target.value,
              }))
            }
            onKeyDown={(e) => handleKeyDown(e, inputDevedorRef)}
          />
        </CCol>
      </RowToModal>
      <RowToModal className="row_form" style={{ marginTop: "-15px" }}>
        <CCol>
          <MyAutoComplete
            ref={inputDevedorRef}
            inputValue={inputDevedor}
            setInputValue={setInputDevedor}
            labelInput={"Devedor"}
            options={optionsDevedor}
            labelFormat={(option) => `${option.cpf} - ${option.nome}`}
            style={{ height: "37px" }}
            onOptionSelect={handleSelectDevedor}
          />
        </CCol>
      </RowToModal>
      <RowToModal className="row_form" style={{ marginTop: "-15px" }}>
        <CCol md={8}>
          <MyAutoComplete
            ref={inputCaixaRef}
            inputValue={inputCaixa}
            setInputValue={setInputCaixa}
            labelInput={"Caixa e Equivalente de Caixa"}
            disabled={!valuesReceitaOrcamentaria.naturezaId}
            options={filteredOptionsCaixaEquivalenteDeCaixa}
            onOptionSelect={handleCaixaEquivalenteDeCaixaSelect}
            labelFormat={(option) => `${option.numero} - ${option.nome}`}
            style={{ height: "37px" }}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            ref={inputDocFinanceiroRef}
            placeholder="Doc Financeiro"
            label="Doc Financeiro"
            value={valuesReceitaOrcamentaria.docFinanceiro}
            onChange={(e) => onChangeTextDocFinanceiro(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, inputValorRef)}
            max={11}
          />
        </CCol>
        <CCol md={2}>
          <InputFormatRealBrasileiro
            iconReal
            ref={inputValorRef}
            disabled={
              !(
                valuesReceitaOrcamentaria.contaBancariaId &&
                valuesReceitaOrcamentaria.naturezaId
              )
            }
            externalValue={valuesReceitaOrcamentaria.valor}
            onChange={handleChangeValorReceita}
            onKeyDown={(e) => handleKeyDown(e, inputHistoricoRef)}
            label={
              !(
                valuesReceitaOrcamentaria.contaBancariaId &&
                valuesReceitaOrcamentaria.naturezaId
              )
                ? ""
                : "Valor"
            }
            placeholder="0,00"
            max={16}
          />
        </CCol>
      </RowToModal>

      <RowToModal
        style={{ backgroundColor: "#fff", borderRadius: "0px 0px 5px 5px" }}
        className={"mt-1"}
      >
        <CCol style={{ display: "flex", alignItems: "center", padding: "0px" }}>
          {optionsAba.map((option, index) => (
            <OptionsStyle
              key={index}
              selecionado={optionAbaSelected === option.optionId}
              onClick={() => setOptionAbaSelected(option.optionId)}
            >
              {option.label}
            </OptionsStyle>
          ))}
        </CCol>
      </RowToModal>

      {optionAbaSelected === 1 && (
        <HistoricoAba
          ref={inputHistoricoRef}
          handleKeyDown={handleKeyDown}
          onFocus={handleFocus}
          valuesReceitaOrcamentaria={valuesReceitaOrcamentaria}
          setValuesReceitaOrcamentaria={setValuesReceitaOrcamentaria}
        />
      )}
      {optionAbaSelected === 2 && (
        <FonteDeRecursosAba
          contaBancariaId={valuesReceitaOrcamentaria.contaBancariaId}
          naturezaId={valuesReceitaOrcamentaria.naturezaId}
          setFontesDeRecursos={setFontesDeRecursos}
          fontesDeRecursos={fontesDeRecursos}
          filteredOptionsFonteRec={filteredOptionsFonteRec}
          valorTotalReceita={parseFloat(valuesReceitaOrcamentaria.valor)}
          fonteDeRecursoAdicionar={fonteDeRecursoAdicionar}
          setFonteDeRecursoAdicionar={setFonteDeRecursoAdicionar}
          inputFonteRec={inputFonteRec}
          setInputFonteRec={setInputFonteRec}
        />
      )}

      {optionAbaSelected === 3 && <ProcedimentoContabel />}
      <div className="p-3" style={{ minHeight: "80px" }}></div>
    </ContainerToModal>
  );
};

export default ReceitaOrcamentaria;
