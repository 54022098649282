import React, { useState } from "react";
import { RowTituloAba } from "../../../PagesAcessoInterno/PagContaPerfil/StyledComponentsContaPerfil";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
  RowForm,
} from "../../../../components/Grid/CCol";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { Icon } from "@iconify/react/dist/iconify.js";
import { toast } from "react-toastify";

export const FonteDeRecursosAba = ({
  infosAnulacaoReceita,
  setInfosAnulacaoReceita,
  optionsFonteRecurso,
  optionTipoAnulacaoIsRestituicao,
}) => {
  console.log(optionsFonteRecurso);

  const [inputFonteValue, setInputFonteValue] = useState("");
  const [valuesFonteAntesDaEdicao, setValuesFonteAntesDaEdicao] = useState({
    porcentagem: "",
    valor: "",
  });

  const [indexEditFonteDeRecurso, setIndexEditFonteDeRecurso] = useState(null);

  const [fonteParaAdicionar, setFonteParaAdicionar] = useState({
    guiaReceitaId: "",
    numeroGuia:'',
    fonteId: "",
    codigo: "",
    titulo: "",
    porcentagem: "0,00",
    valor: "0,00",
  });

  console.log(infosAnulacaoReceita.fontesGuiaReceita);

  const handleSelectFonte = (optionFonteRecurso) => {
    console.log(optionFonteRecurso);
    setFonteParaAdicionar({
      ...optionFonteRecurso,
      porcentagem: "0,00",
    });
  };

  const handleAddFonteRecurso = () => {
    console.log(fonteParaAdicionar);

    const hasValuesFonte = Object.values(fonteParaAdicionar).every(
      (item) => item !== ""
    );

    if (!hasValuesFonte) {
      toast.error(
        "Preencha todos os campos corretamente para adicionar a Fonte de Recurso."
      );
      return;
    }

    const fonteGuia = {
      guiaReceitaId: fonteParaAdicionar.guiaReceitaId,
      numeroGuia: fonteParaAdicionar.numeroGuia,
      fonteId: fonteParaAdicionar.fonteId,
      codigo: fonteParaAdicionar.codigo,
      titulo: fonteParaAdicionar.titulo,
      porcentagem: fonteParaAdicionar.porcentagem,
      valor: fonteParaAdicionar.valor,
    };

    if (fonteRecursosExists(fonteGuia)) {
      toast.error("Esta Fonte de Recursos já foi adicionada com essa Guia.");
      return;
    }
    console.log(fonteGuia);
    

    setInfosAnulacaoReceita((prevState) => ({
      ...prevState,
      fontesGuiaReceita: [fonteGuia, ...prevState.fontesGuiaReceita],
    }));

    resetValuesFonte();
  };

  const fonteRecursosExists = (fonte) => {
    return infosAnulacaoReceita.fontesGuiaReceita.some(
      (item) =>
        item.guiaReceitaId === fonte.guiaReceitaId && item.fonteId === fonte.fonteId
    );
  };

  const resetValuesFonte = () => {
    setFonteParaAdicionar({
      guiaReceitaId: "",
      numeroGuia:'',
      fonteId: "",
      codigo: "",
      titulo: "",
      porcentagem: "0,00",
      valor: "0,00",
    });

    setInputFonteValue("");
  };

  const handleEditFonteDeRecurso = (indexFonteRecurso) => {
    const { porcentagem, valor } =
      infosAnulacaoReceita.fontesGuiaReceita[indexFonteRecurso];
    setValuesFonteAntesDaEdicao({ porcentagem, valor });
    setIndexEditFonteDeRecurso(indexFonteRecurso);
  };

  const handleSaveEditFonte = (option) => {
    const saldoMenorValorAnulacao =
      parseFloat(valuesFonteAntesDaEdicao.valor) < parseFloat(option.valor);

    if (saldoMenorValorAnulacao) {
      toast.error("O valor não pode ser maior que o saldo da Guia.");
      return;
    }

    setIndexEditFonteDeRecurso(null);
  };

  const handleCancelEditFonte = ( option) => {
    console.log(valuesFonteAntesDaEdicao);

    const fontesGuiaReceita = infosAnulacaoReceita.fontesGuiaReceita.map(
      (item, index) => {
        if (
          item.guiaReceitaId === option.guiaReceitaId &&
          item.fonteId === option.fonteId
        ) {
          return {
            ...item,
            porcentagem: valuesFonteAntesDaEdicao.porcentagem,
            valor: valuesFonteAntesDaEdicao.valor,
          };
        }
        return item;
      }
    );
    console.log(fontesGuiaReceita);

    setInfosAnulacaoReceita((prevState) => ({
      ...prevState,
      fontesGuiaReceita,
    }));
    setIndexEditFonteDeRecurso(null);
  };

  const handleDeleteFonteRecurso = (option) => {
    console.log(option);
    const fontesGuiaReceita = infosAnulacaoReceita.fontesGuiaReceita.filter(
      (item) =>
        item.guiaReceitaId !== option.guiaReceitaId && item.fonteId !== option.fonteId
    );
    setInfosAnulacaoReceita((prevState) => ({
      ...prevState,
      fontesGuiaReceita,
    }));
    setIndexEditFonteDeRecurso(null);
  };

  const onChangeFonteEditPercentage = (porcentagem, option) => {
    const percentageFormatted = maskInputPercentage(porcentagem);
    const fontesGuiaReceita = infosAnulacaoReceita.fontesGuiaReceita.map(
      (item) => {
        if (
          item.guiaReceitaId === option.guiaReceitaId &&
          item.fonteId === option.fonteId
        ) {
          return {
            ...item,
            porcentagem: percentageFormatted,
          };
        }
        return item;
      }
    );

    setInfosAnulacaoReceita((prevState) => ({
      ...prevState,
      fontesGuiaReceita,
    }));
  };

  const onChangeFonteEditValor = (valor, option) => {
    const fontesGuiaReceita = infosAnulacaoReceita.fontesGuiaReceita.map(
      (item) => {
        if (
          item.guiaReceitaId === option.guiaReceitaId &&
          item.fonteId === option.fonteId
        ) {
          return {
            ...item,
            valor,
          };
        }
        return item;
      }
    );

    setInfosAnulacaoReceita((prevState) => ({
      ...prevState,
      fontesGuiaReceita,
    }));
  };

  const maskInputPercentage = (value) => {
    const rawValue = value.replace(/[^\d]/g, "");
    const numericValue = Number(rawValue) / 100;
    const formattedValue = numericValue.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formattedValue;
  };

  // const formattedOptionFonteRecurso= useMemo(()=>{
  //   const fontes = infosAnulacaoReceita.fontesGuiaReceita.map(fonte => {
  //     return{
  //       label: `${fonte.codigo} - ${fonte.titulo}`,
  //       value: fonte.fonteId,
  //       ...fonte
  //     }
  //   })
  //   return fontes
  // },[infosAnulacaoReceita.fontesGuiaReceita])
  console.log(infosAnulacaoReceita);

  return (
    <>
      <RowTituloAba>
        <div>
          <span>Fonte de Recursos / CO</span>
        </div>
      </RowTituloAba>

      <RowForm className="mt-1" $borderRadiusType={2}>
        <div style={{width:'125px'}}>
          <PTitulosInputs>Guia</PTitulosInputs>
          <FormControlListaDeInputs
            disabled
            value={fonteParaAdicionar.numeroGuia}
          />
        </div>
        <CCol>
          <PTitulosInputs>Fonte de Recursos + CO</PTitulosInputs>
          <MyAutoComplete
            options={optionsFonteRecurso}
            labelFormat={(item) => `${item.codigo} - ${item.titulo}`}
            inputValue={inputFonteValue}
            setInputValue={setInputFonteValue}
            onOptionSelect={(option) => {
              handleSelectFonte(option);
            }}
            iconClearFunction={resetValuesFonte}
          />
        </CCol>
        <CCol md={1}>
          <PTitulosInputs>%</PTitulosInputs>
          <FormControlListaDeInputs
            style={{ textAlign: "end" }}
            value={fonteParaAdicionar.porcentagem}
            onChange={(e) => {
              setFonteParaAdicionar((prev) => ({
                ...prev,
                porcentagem: maskInputPercentage(e.target.value),
              }));
            }}
          />
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>Valor</PTitulosInputs>
          <InputFormatRealBrasileiro
            listaDeInputs={true}
            externalValue={fonteParaAdicionar.valor}
            onChange={(_, valor) =>
              setFonteParaAdicionar((prev) => ({
                ...prev,
                valor,
              }))
            }
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
          <IconAdicionar onClick={handleAddFonteRecurso} />
        </CColButtonsAcoes2Icons>

        {infosAnulacaoReceita.fontesGuiaReceita.map((item, index, array) => {
          if (!item.guiaReceitaId) return null;
          return (
            <RowAninhada key={index}>
              <div style={{width:'125px'}}>
                <FormControlListaDeInputs
                  disabled
                  value={item.numeroGuia}
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                />
              </div>
              <CCol>
                <FormControlListaDeInputs
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                  value={`${item.codigo} - ${item.titulo}`}
                  disabled
                />
              </CCol>
              <CCol md={1}>
                <FormControlListaDeInputs
                  style={{ textAlign: "end" }}
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                  value={item.porcentagem}
                  disabled={
                    !optionTipoAnulacaoIsRestituicao ||
                    index !== indexEditFonteDeRecurso
                  }
                  onChange={(e) =>
                    onChangeFonteEditPercentage(e.target.value, item)
                  }
                />
              </CCol>
              <CCol md={2}>
                <InputFormatRealBrasileiro
                  listaDeInputs
                  externalValue={item.valor}
                  radiusborder={
                    array.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                  disabled={
                    !optionTipoAnulacaoIsRestituicao ||
                    index !== indexEditFonteDeRecurso
                  }
                  onChange={(_, valor) => onChangeFonteEditValor(valor, item)}
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                {indexEditFonteDeRecurso !== index && (
                  <>
                    <Icon
                      onClick={() => handleEditFonteDeRecurso(index)}
                      icon="bx:edit"
                      color="#5971C8"
                      height="26"
                      style={{
                        cursor: "pointer",

                        marginTop: "3px",
                      }}
                    />
                    <Icon
                      onClick={() => handleDeleteFonteRecurso(item)}
                      icon="ic:baseline-delete"
                      color="#E79900"
                      height="26"
                      style={{
                        cursor: "pointer",
                        marginLeft: "5px",
                        marginTop: "1px",
                      }}
                    />
                  </>
                )}
                {indexEditFonteDeRecurso !== null &&
                  indexEditFonteDeRecurso === index && (
                    <>
                      <Icon
                        onClick={() => handleSaveEditFonte(item)}
                        icon="fluent:save-24-filled"
                        color="#008CFF"
                        height="26"
                        style={{
                          cursor: "pointer",
                          marginTop: "3px",
                        }}
                      />
                      <Icon
                        icon="ic:baseline-cancel"
                        onClick={() => handleCancelEditFonte( item)}
                        height="26"
                        color="#F24E1E"
                        style={{
                          display:
                            indexEditFonteDeRecurso !== index
                              ? "none"
                              : "inline-block",
                          pointerEvents:
                            indexEditFonteDeRecurso !== index ? "none" : "auto",
                          cursor:
                            indexEditFonteDeRecurso !== index
                              ? "not-allowed"
                              : "pointer",
                          marginLeft: "2px",
                          marginRight: "-5px",
                          marginTop: "3px",
                        }}
                      />
                    </>
                  )}
              </CColButtonsAcoes2Icons>
            </RowAninhada>
          );
        })}
      </RowForm>
    </>
  );
};
