import styled, { keyframes } from "styled-components";
import { colors } from "../../styles/colors";

const bounceHorizontal = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-30px);
  }
  60% {
    transform: translateX(-15px);
  }
`;

export const ToastContainer = styled.div`
  position: fixed;
  top: 20px;
  width: 310px;
  z-index: 10;
  background-color: white;
  border-radius: 6px;
  padding: 10px;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  right: ${({ isOpen }) => (isOpen ? "20px" : "-310px")};
  animation: ${({ isOpen }) => (isOpen ? bounceHorizontal : 'none')} 1s ease-in-out;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 6px;
`;

export const ButtonClose = styled.button`
  position: absolute;
  right: 5px;
  top: 2px;
  color: ${colors["gray-light"]};
  outline: none;
  box-shadow: none;
  border: none;
  background-color: transparent;
  &:hover {
    color: black;
  }
`;

export const ButtonAction = styled.button`
  outline: none;
  box-shadow: none;
  border: none;
  padding: 5px;
  width: 70px;
  border-radius: 6px;
  color: white;
`;

export const ToastMessage = styled.p`
  font-size: 1rem;
  text-align: center;
  color: #515c70;
`;

